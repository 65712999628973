import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { Stack } from '@mui/material';

import clsx from 'clsx';
import { animated, useSpring } from '@react-spring/web';
import { styled, alpha } from '@mui/material/styles';
import FileCard from '../../shared/FileCard';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import ArticleIcon from '@mui/icons-material/Article';
import FolderRounded from '@mui/icons-material/FolderRounded';
import HomeIcon from '@mui/icons-material/Home';
import HubIcon from '@mui/icons-material/Hub';
import { FolderCopy } from '@mui/icons-material';

import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import {
  TreeItem2Checkbox,
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { TreeItem2DragAndDropOverlay } from '@mui/x-tree-view/TreeItem2DragAndDropOverlay';

import { useTreeViewApiRef } from '@mui/x-tree-view/hooks';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const primaryColor = '#5c5dd8'

function getItemDescendantsIds(item) {
  const ids = [];
  item.children?.forEach((child) => {
    ids.push(child.id);
    ids.push(...getItemDescendantsIds(child));
  });

  return ids;
}

function DotIcon() {
  return (
    <Box
      sx={{
        width: 6,
        height: 6,
        borderRadius: '70%',
        bgcolor: 'warning.main',
        display: 'inline-block',
        verticalAlign: 'middle',
        zIndex: 1,
        mx: 1,
      }}
    />
  );
}

const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color: theme.palette.grey[400],
  position: 'relative',
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(3.5),
  },
  ...theme.applyStyles('light', {
    color: theme.palette.grey[800],
  }),
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  flexDirection: 'row-reverse',
  borderRadius: theme.spacing(0.7),
  marginBottom: theme.spacing(0.5),
  marginTop: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  fontWeight: 500,
  [`&.Mui-expanded `]: {
    '&:not(.Mui-focused, .Mui-selected, .Mui-selected.Mui-focused) .labelIcon': {
      color: theme.palette.primary.dark,
      ...theme.applyStyles('light', {
        color: primaryColor,
      }),
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '16px',
      top: '44px',
      height: 'calc(100% - 48px)',
      width: '1.5px',
      backgroundColor: theme.palette.grey[700],
      ...theme.applyStyles('light', {
        backgroundColor: theme.palette.grey[300],
      }),
    },
  },
  '&:hover': {
    backgroundColor: alpha(primaryColor, 0.1),
    color: 'white',
    ...theme.applyStyles('light', {
      color: primaryColor,
    }),
  },
  [`&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused`]: {
    backgroundColor: primaryColor,
    color: theme.palette.primary.contrastText,
    ...theme.applyStyles('light', {
      backgroundColor: primaryColor,
    }),
  },
  '& .MuiCheckbox-root.Mui-checked': {
    color: '#ffffff',
  },
}));

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
}

const StyledTreeItemLabelText = styled(Typography)({
  color: 'inherit',
  fontWeight: 500,
});

function CustomLabel({ icon: Icon, expandable, children, ...other }) {
  return (
    <TreeItem2Label
      {...other}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {Icon && (
        <Box
          component={Icon}
          className="labelIcon"
          color="inherit"
          sx={{ mr: 1, fontSize: '1.2rem' }}
        />
      )}

      <StyledTreeItemLabelText variant="body2">{children}</StyledTreeItemLabelText>
      {expandable && <DotIcon />}
    </TreeItem2Label>
  );
}

const isExpandable = (reactChildren) => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isExpandable);
  }
  return Boolean(reactChildren);
};

const getIconFromFileType = (type) => {
  switch (type) {
    case 'hub':
      return HubIcon;
    case 'project':
      return HomeIcon;
    case 'folders':
      return FolderRounded;
    case 'folder':
      return FolderRounded;
    case 'site':
      return HomeIcon;
    case 'drive':
      return FolderCopy;
    case 'item':
      return ArticleIcon;
    default:
      return ArticleIcon;
  }
};

const CustomTreeItem = forwardRef(function CustomTreeItem(props, ref) {
  const { id, itemId, label, disabled, children, ...other } = props;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    getDragAndDropOverlayProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const item = publicAPI.getItem(itemId);
  const expandable = isExpandable(children);
  let icon;
  icon = getIconFromFileType(item.type);

  return (
    <TreeItem2Provider itemId={itemId}>
      <StyledTreeItemRoot {...getRootProps(other)}>
        <CustomTreeItemContent
          {...getContentProps({
            className: clsx('content', {
              'Mui-expanded': status.expanded,
              'Mui-selected': status.selected,
              'Mui-focused': status.focused,
              'Mui-disabled': status.disabled,
            }),
          })}
        >
          <TreeItem2IconContainer {...getIconContainerProps()}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
          <TreeItem2Checkbox {...getCheckboxProps()} />
          <CustomLabel
            {...getLabelProps({ icon, expandable: expandable && status.expanded })}
          />
          <TreeItem2DragAndDropOverlay {...getDragAndDropOverlayProps()} />
        </CustomTreeItemContent>
        {children && <TransitionComponent {...getGroupTransitionProps()} />}
      </StyledTreeItemRoot>
    </TreeItem2Provider>
  );
});

const CustomSourceTree = ({ sourceTree, handleSetFileCount, handleSetSourceFiles, source }) => {
  const [isLoadingTree, setIsLoadingTree] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const toggledItemRef = useRef({});
  const apiRef = useTreeViewApiRef();

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    toggledItemRef.current[itemId] = isSelected;
  };

  const handleSelectedItemsChange = (event, newSelectedItems) => {
    setSelectedItems(newSelectedItems);

    // Select / unselect the children of the toggled item
    const itemsToSelect = [];
    const itemsToUnSelect = {};
    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRef.current.getItem(itemId);
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item));
      } else {
        getItemDescendantsIds(item).forEach((descendantId) => {
          itemsToUnSelect[descendantId] = true;
        });
      }
    });

    const newSelectedItemsWithChildren = Array.from(
      new Set(
        [...newSelectedItems, ...itemsToSelect].filter(
          (itemId) => !itemsToUnSelect[itemId],
        ),
      ),
    );

    const files = newSelectedItemsWithChildren
      .map((itemId) => {
        const item = apiRef.current.getItem(itemId);  // Fetch the full item object
        if (item.type === 'items' || item.type === 'item' || item.type === 'File') {
          return {
            ...item,
          };
        }
        return null; // Or `undefined`
      })
      .filter(Boolean); // Remove null or undefined values

    setSelectedFiles(files)

    console.log(files)

    handleSetFileCount(files.length)
    handleSetSourceFiles(files, source)
    setSelectedItems(newSelectedItemsWithChildren);

    toggledItemRef.current = {};
  };

  const handleTreeRender = () => {

    // Check if sourceTree is not null/undefined and has elements
    if (Array.isArray(sourceTree) && sourceTree.length > 0) {
      return (
        <RichTreeView
          items={sourceTree}
          multiSelect
          checkboxSelection
          apiRef={apiRef}
          sx={{ height: 'fit-content', flexGrow: 1, height: 'calc(100vh - 260px)', overflowY: 'auto', scrollbarGutter: 'stable' }}
          slots={{ item: CustomTreeItem }}
          selectedItems={selectedItems}
          onSelectedItemsChange={handleSelectedItemsChange}
          onItemSelectionToggle={handleItemSelectionToggle}
        />
      );
    } else {
      return <div>No data available</div>;  // Optional: Handle the case where sourceTree is empty
    }
  };


  useEffect(() => {
    handleSetFileCount(0)
  }, [])

  return (
    <div style={{ display: 'flex', flexGrow: 1}}>
      <Stack direction="row" spacing={2} sx={{width: '100%', maxHeight: 'calc(100vh - 260px)', maxWidth: '77vw', justifyContent: 'space-between' }}>
        <div style={{width: '50%'}}>
          {handleTreeRender()}
        </div>
        <div style={{ maxHeight: 'calc(100vh - 260px)', width: '50%', overflowY: 'auto', scrollbarGutter: 'stable' }}>
          {/* Display uploaded files using FileCard component */}
          {selectedFiles.map((selectedFile, index) => (
            <FileCard
              key={index}
              fileName={selectedFile.label}
              fileType={selectedFile.label.split('.').pop()}
            />
          ))}
        </div>
      </Stack>
    </div>
  )
}

export default CustomSourceTree;

