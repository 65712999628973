import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal"; // Add this for the popup
import Button from "react-bootstrap/Button"; // Add this for buttons

const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();  // Get the URL query params
  const [showPopup, setShowPopup] = useState(false);

  // Check for email verification error in URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const error = queryParams.get("error");
    const errorDescription = queryParams.get("error_description");

    // If there's an access_denied error related to email verification
    if (error === "access_denied" && errorDescription.includes("verify your email")) {
      setShowPopup(true);  // Trigger the popup
    }
  }, [location]);

  // Handle redirection after login
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/welcome");
    }
  }, [isAuthenticated, navigate]);

  // Loading state while authentication is in progress
  if (isLoading) return <div className="white-font">Loading...</div>;

  return (
    <>
      {/* Login button */}
      <button className='btn btn-tertiary me-3' onClick={() => loginWithRedirect()}>
        Get started&nbsp;&nbsp;&nbsp;&nbsp;⮕
      </button>

      {/* "Check Your Email" Popup Modal */}
      <Modal show={showPopup} onHide={() => setShowPopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Verify Your Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Please verify your email before logging in. Check your inbox for a verification email.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPopup(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LoginButton;
