import { generateRandomString } from '../scripts/generateRandomString';
import { generateCodeChallenge } from '../scripts/generateCodeChallenge';
import { generateState } from '../scripts/generateState';

export async function handleAuth(connector, organisation, workspace, sessionId, userId) {
    // Step 1: Generate a code_verifier and code_challenge
    const codeVerifier = generateRandomString(128);
    const codeChallenge = await generateCodeChallenge(codeVerifier);

    // Step 2: Store the code_verifier (e.g., in localStorage) to use later during the token exchange
    localStorage.setItem('code_verifier', codeVerifier);

    const state = generateState(organisation, workspace, sessionId, userId); // Generate the state string

    let params, auth_url;

    if (connector === 'sharepoint') {
        params = {
            client_id: process.env.REACT_APP_ENTRA_CLIENT_ID,
            scope: 'Sites.Read.All Files.Read.All offline_access',
            response_type: 'code',
            response_mode: 'query',
            redirect_uri: window.location.origin + '/callback/get/sharepoint',
            state: state,
            code_challenge: codeChallenge,       // PKCE code challenge
            code_challenge_method: 'S256'        // Always use 'S256' for the challenge method
        };

        auth_url = 'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?'
        }
    else if (connector === 'autodesk') {

        params = {
            client_id: process.env.REACT_APP_AUTODESK_CLIENT_ID,
            scope: 'data:read',
            response_type: 'code',
            redirect_uri: window.location.origin + '/callback/get/autodesk',
            state: state,
            code_challenge: codeChallenge,       // PKCE code challenge
            code_challenge_method: 'S256'        // Always use 'S256' for the challenge method
        };

        auth_url = 'https://developer.api.autodesk.com/authentication/v2/authorize?'

    }

    const queryString = new URLSearchParams(params).toString();

    // Redirect the user's browser to the authorization URL
    window.location.href = `${auth_url}${queryString}`

    console.log(`${auth_url}${queryString}`)
}