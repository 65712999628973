import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, CardActionArea, Chip } from '@mui/material';

const ConnectorCard = ({ title, disabled, comingSoon, imageSrc, connected, isSelected, onClick }) => {

    return (
        <Card
            sx={{
                width: 250,
                boxShadow: 3,
                borderRadius: '8px',
                position: 'relative', // Position badge inside card
                border: isSelected ? '2px solid #544cfd' : 'none',  // Change border when selected
                opacity: disabled || comingSoon ? 0.5 : 1, // Reduce opacity if disabled
                pointerEvents: disabled || comingSoon ? 'none' : 'auto', // Disable click if disabled
                cursor: disabled || comingSoon ? 'not-allowed' : 'auto'
            }}
            onClick={!disabled || !comingSoon ? onClick : null} // Only call onClick if not disabled
            title = {disabled ? "You do not have permission to select this item" : comingSoon ? 'Coming soon' :
                ""} // Tooltip when disabled
        >
            <CardActionArea onClick={!disabled || !comingSoon ? onClick : null} disabled={disabled || comingSoon}>
                {/* Card Image */}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: 150,
                    backgroundColor: 'ghostwhite',
                    margin: '8px',
                    borderRadius: '6px',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    {disabled && (
                        <Chip
                            label="PRO"
                            color="warning"
                            sx={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                            }}
                        />
                    )}
                    {comingSoon && (
                        <Chip
                            label="Coming soon"
                            color="info"
                            sx={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                            }}
                        />
                    )}
                    <img
                        src={`${process.env.PUBLIC_URL}${imageSrc}`}
                        style={{ width: 75 }}
                    />

                </Box>
                {/* Card Content */}
                <CardContent>
                    {/* Connected Badge */}
                    {connected && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 10,
                                left: 10,
                                backgroundColor: 'green',
                                padding: '4px 8px',
                                color: 'white',
                                borderRadius: '12px',
                                fontSize: '12px',
                            }}
                        >
                            Connected
                        </Box>
                    )}
                    <Typography gutterBottom variant="h7" component="div">
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

export default ConnectorCard