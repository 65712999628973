import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Container, Row, Col, Badge, Card, Form, Alert, Modal, Button } from 'react-bootstrap';
import Chip from '@mui/material/Chip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TypingEffect from './subcomponents/TypingEffect';
import Pricing from './subcomponents/Pricing';
import RoiCalculator from './subcomponents/RoiCalculator';
import ThreePartProcess from './subcomponents/ThreePartProcess';
import Subscribe from './subcomponents/Subscribe';
import RequestADemo from './subcomponents/RequestADemo';
import NavBar from './shared/NavBar'
import FooterBar from './shared/FooterBar';
import Popup from './shared/Popup';
import Overlay from './shared/Overlay';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import LoginButton from './shared/LoginButton';
import LogoutButton from './shared/LogoutButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as ScrollLink } from "react-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faPuzzlePiece, faFileImport, faWandSparkles, faArrowUpRightFromSquare

  } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import { useSession } from '../contexts/SessionContexts';
import '../App.scss';
import ReactGA from 'react-ga';
import { useAuth0 } from "@auth0/auth0-react";


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const Home = (props) => {
  const navigate = useNavigate(); // for handling page routing within a function. 
  const location = useLocation();
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const { setSession } = useSession();
  const { sessionGuid } = useSession();
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [showStartupModal, setShowStartupModal] = useState(false);
  const [resultGuid, setResultGuid] = useState('');
  const inputRef = useRef(null);
  const [showSpinner, setShowSpinner] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [userId, setuserId] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [APIError, setAPIError] = useState(null);
  const [formError, setFormError] = useState(null);
  const [showSubscribe, setShowSubscribe] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  // Subscription modal popup on page scroll
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const [subscribeModalShown, setSubscribeModalShown] = useState(false);




  // Google analytics ping on component mount
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // For conditionally styling components based on window width. 
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Auto-ping login button when user returning from email validation on sign-up
  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    return {
      message: queryParams.get('message'),
      success: queryParams.get('success'),
      // Add more parameters as needed
    };
  };

  const { message, success } = getQueryParams();

  useEffect(() => {
    if (success === 'true' && !isAuthenticated) {
      // Auto-trigger login with redirect if success parameter is true and user is not authenticated
      loginWithRedirect();
    }
  }, [success, isAuthenticated, loginWithRedirect]);

  const handleShowSessionModal = () => setShowSessionModal(true);
  const handleHideSessionModal = () => {
    setShowSessionModal(false);
    setResultGuid('');
    handleFormReset('EnterSessionKey')
  }

  const handleShowStartupModal = () => setShowStartupModal(true);
  const handleHideStartupModal = () => {
    setShowStartupModal(false);
    handleFormReset('EnterSessionKey')
  }


  const handleGetResultsButtonClick = (event) => {

    const fetchUserStandard = async () => {
      try {

        const config = {headers: {
          'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
        }}

        const response = await axios.get(`${API_BASE_URL}/api/ConfigureResultsTableTrigger?sessionGuid=${resultGuid}&folderName=${userId}`, config);
        setShowSpinner(false);
        setSession(resultGuid, userId);
        navigate(`${userId}/${resultGuid}/results`)
        // if (props.onGetResultsButtonClick) {
        //   props.onGetResultsButtonClick();
        // }
      } catch (error) {
        setShowSpinner(false)
        console.error("Error fetching userStandard:", error);
        setAPIError('Session key not found');
      }
    };

    event.preventDefault();
    event.stopPropagation();

    // Trigger form validation. 
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setFormError('Cannot be blank')
      setValidated(true);
      return;
    }

    setShowSpinner(true);

    fetchUserStandard();

  };

  const handleAuthenticate = (event) => {

    event.preventDefault();
    event.stopPropagation();

    // Trigger form validation. 
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setFormError('Cannot be blank')
      setValidated(true);
      return;
    }

    const passwordSetting = process.env[`REACT_APP_USER_${userId}`];

    if (passwordSetting && password === passwordSetting) {

      setAPIError(null)
      navigate(`${userId}/${sessionGuid}/initiate`)
      
    }
      else {
        setAPIError('Invalid userId or password')
      }
  }

  const handleAlertClose = () => {
    setAPIError(null);
  };

  const handleRegisterInterest = () => {
    if (showSubscribe) {
      setShowSubscribe(false)
    } else {
      setShowSubscribe(true); // Show the Subscribe component
    }
    
  };

  const handleFormReset = (formId) => {
    const form = document.getElementById(formId);
    if (form) {
      form.reset();
    } else {
      console.error(`Form with ID '${formId}' not found.`);
    }
    setAPIError(null);
    setFormError(null);
    setValidated(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (showSessionModal) {
      inputRef.current.focus();
    }
  }, [showSessionModal]);

  
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleImageClick = (event, src) => {
    event.preventDefault(); // Prevent the default behavior (scrolling to the top)
    console.log("Image source:", src);
    setImageSrc(src);
    setShowModal(true);
    event.stopPropagation(); // Prevent the event from bubbling up to the <a> tag
  };
  
  useEffect(() => {
    // Check if subscribeModalShown is stored in local storage
    const storedSubscribeModalShown = localStorage.getItem('subscribeModalShown');
  
    // Initialize subscribeModalShown state based on local storage value
    if (storedSubscribeModalShown) {
      setSubscribeModalShown(true);
    }
  }, []);
  

  // Subscribe modal effects and event listener
  useEffect(() => {
    const handleScroll = () => {
      console.log('Scroll event detected');
      const featuresSection = document.getElementById('features');
      if (featuresSection) {
        const rect = featuresSection.getBoundingClientRect();
        console.log('Features section rect:', rect);
        console.log('Subscribe modal shown:', subscribeModalShown);
  
        // If the modal has not been shown yet
        if (!subscribeModalShown) {
          // Open the modal when scrolling to the features section
          if (rect.top <= window.innerHeight) {
            console.log('Opening subscribe modal');
            setShowSubscribeModal(true);
            setSubscribeModalShown(true);
            // Store subscribeModalShown in local storage
            localStorage.setItem('subscribeModalShown', 'true');
          }
        }
      }
    };
  
    // Debounce scroll events to avoid rapid state updates
    let timeoutId;
    const debouncedHandleScroll = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleScroll, 100);
    };
  
    window.addEventListener('scroll', debouncedHandleScroll);
    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [subscribeModalShown]);
  

  const handleCloseSubscribeModal = () => {
    setShowSubscribeModal(false);
  };  

  const handleManualSubscribeModalRecall = () => {
    setShowSubscribeModal(true);
    setSubscribeModalShown(true);
    localStorage.setItem('subscribeModalShown', 'true');
  };

  return (
    <div className='page-wrapper'>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showSpinner}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <NavBar handleShowSessionModal={handleShowSessionModal} handleShowStartModal={handleShowStartupModal} />
      <main className="main-wrapper">
        <div className="home-hero_section">
          <div className="background-placeholder">
            <video className="background-video" autoPlay loop width='100%' height='100%' muted playsInline>
              <source src={`${process.env.PUBLIC_URL}/home_background.mp4`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <Container className="home-strapline">
            <Row className='mt-5'>
              <Col xs={10} sm={6}>
                <div className="hidden-on-mobile">
                  <TypingEffect />
                </div>
                <h1 className="highlight-text">
                Information management for the AI era.
                </h1>
                <div className='mt-4'>
                <div className='floating-panel-2'>
                <div className="panel-m home-valueprop-strapline">
                  <p className='m-0 fs-5'>Introducing hoppa - the smarter way to turn built environment data into organised & actionable knowledge.</p><br></br>
                  {/*<p>Transform unstructured data into insights, enhance productivity, and unlock the hidden value of your industry data.</p>*/}
                </div>
                
                </div>
              </div>
                <div className="mt-2 d-flex">
                  <button className="btn btn-primary ms-0">
                    <ScrollLink spy to="features">
                      Learn more
                    </ScrollLink>
                  </button>
                  <button className="btn btn-primary">
                    <ScrollLink spy to="contact">
                      Request a demo
                    </ScrollLink></button>
                    {/* <button
                      className="btn btn-primary"
                      onClick={() => {
                        window.location.href = "mailto:hello@hoppa.ai?subject=Request%20a%20demo%20-%20hoppa&body=Hey%20there%20hoppa,%20I%20would%20like%20to%20learn%20more%20about%20your%20solution,%20let's%20arrange%20a%20demo!";
                      }}
                    >
                      Request a demo
                    </button> */}
                    </div>
                    
              </Col>
            </Row>
            {/* <Row className='justify-content-end'>
              <div className='floating-panel-2'>
                <div className="panel-m transparent">
                  <p>Knowledge workers in construction lose 5 to 9 hours each week searching for information.</p>
                  <p className='m-0'>That's why we developed Hoppa - the smarter way to classify, tag and rename files in minutes.👉</p>
                </div>
              </div>
            </Row> */}
          </Container>
            
        </div>
        <Container id='about'>
          <div className='padding-xhuge'>
            {/* <Chip label="Why hoppa" variant="outlined" className='ps-2 pe-2 mb-2 tag-selected' />*/}
            <Row>
              <Col xs={12} lg={6}>
                <p className='blue-font xlarge-font bold-statement'>Cut through the noise to <b>find answers</b> and <b>flag risk</b></p>
                {/* <p className='mt-2'>Supply chain fragmentation, disparate technologies, and a long tail of historic, poorly managed data means 90% of built environment <b><i className="blue-font">data is unstructured and hard to work with.</i></b></p> */}
                <p className='mt-4'>Your cheat code to better Information Management. Our AI-powered solution inhales unruly banks of data and returns order. Perfect when information changes hands, if things get out of hand, and as requirements change over time.</p>
                <p className='mb-4'> Say goodbye to inefficient, labour-intensive information handling practices, and hello to a new era of productivity and control.</p>
                <Row className='mb-4'>

                <Col xs={2} md={1} className='ms-3 d-flex flex-column align-items-center'>
                  <div className='circle-static'>
                    <div className='circle-icon-static' >
                      <FontAwesomeIcon size='lg' icon={faPuzzlePiece} />
                    </div>
                  </div>
                  <div className='v1'>
                  </div>
                  <div className='circle-static'>
                    <div className='circle-icon-static' >
                      <FontAwesomeIcon size='lg' icon={faFileImport} />
                    </div>
                  </div>
                  <div className='v1'>
                  </div>
                  <div className='circle-static'>
                    <div className='circle-icon-static' >
                      <FontAwesomeIcon size='lg' icon={faWandSparkles} />
                    </div>
                  </div>
              </Col>
              <Col xs={9} md={7} className='d-flex flex-column justify-content-between'>
              <p className='mb-3'>Specify how your information needs to be organised and the questions that need answering.</p>
              <p className='mb-4'>Point hoppa at your files and sit back while our AI gets to work.</p>
              <p className='mb-1'>Review results, take action, and organise your files for storage.</p>
              </Col>
              </Row>
              <button className='btn btn-tertiary mt-4 mb-5 ms-0'>
                  <ScrollLink spy to="benefits">
                    What could this mean for you?&nbsp;&nbsp;&nbsp;&nbsp;⮕
                  </ScrollLink>

                </button>
              <Col>
              </Col>
              </Col>
              <Col xs={12} lg={6} className='d-flex justify-content-center'>
              <video
                style={{
                  width: window.innerWidth < 768 ? '100%' : '75%'
                }}
                src={`${process.env.PUBLIC_URL}/funnel_logo_animation.mp4`}
                alt="GIF"
                playsInline
                loop
                autoPlay
                muted
              />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6} className='d-flex justify-content-center'>

              </Col>
              <Col xs={12} lg={6} className='d-flex align-items-center'>

              </Col>
            </Row>
          </div>
        </Container>

        <div  id='solutions' className='background-video-banner' style={{ marginTop: '2rem' }}>
        <h1 className="blue-font xlarge-font mb-5 text-center"><b>Taking charge</b> of data has never been easier</h1>
        {/* <video autoPlay loop width='100%' height='100%' muted playsInline> */}
          <video loop width='100%' height='100%' muted playsInline>
            <source src={`${process.env.PUBLIC_URL}/purple_ripple.mp4`} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Container id='solutions' className='benefits-card_grid'>
            <div className='padding-huge'>
            {/*<Chip label="Solutions" variant="outlined" className='ps-2 pe-2 mb-3 tag-selected' />*/}
              

              <Row className='gx-2 gy-3'>
              <Col md={12} lg={4}>
              <Card className='case-study'>
              <Card.Img style={{aspectRatio: '16 / 9'}} variant='top' src={`${process.env.PUBLIC_URL}/compass_design.png`} />
              <Card.Title ><Chip variant='outlined' label='DATA DISCOVERY' size="small" className='mb-2'/>
              <h5 className='blue-font'>Uncover buried insights</h5>
                </Card.Title>      
                          {[
                        'Quickly sort through incoming data',
                        'Pinpoint information gaps',
                        'Take control of legacy & historic records'
                      ].map((text, index) => (
                        <Row key={index}>
                          <Col xs={1}><DoneIcon color='primary' /></Col>
                          <Col className='ms-1 mb-2'>{text}</Col>
                        </Row>
                      ))}
                      </Card>
                      </Col>
                      <Col md={12} lg={4}>
              <Card className='case-study'>  
              <Card.Img style={{aspectRatio: '16 / 9'}} variant='top' src={`${process.env.PUBLIC_URL}/construction_helmet.png`} />
              <Card.Title><Chip variant='outlined' label='INFORMATION EXCHANGE' size="small" className='mb-2'/>
              <h5 className='blue-font'>Optimise data sharing</h5>
                </Card.Title>                  
                          {[
                        'Point & shoot compliance with any IM standard',
                        'Prepare for handover without the pain',
                        'Supercharge inter-org knowledge transfer'
                      ].map((text, index) => (
                        <Row key={index}>
                          <Col xs={1}><DoneIcon color='primary' /></Col>
                          <Col className='ms-1 mb-2'>{text}</Col>
                        </Row>
                      ))}
                      </Card>
                      </Col>
                      <Col md={12} lg={4}>
              <Card className='case-study'>  
              <Card.Img style={{aspectRatio: '16 / 9'}} variant='top' src={`${process.env.PUBLIC_URL}/building_defence.png`} />
              <Card.Title><Chip variant='outlined' label='GOLDEN THREAD' size="small" className='mb-2'/>
              <h5 className='blue-font'>Keep the facts to hand</h5>
              </Card.Title>      
                {[
                  'Kick-start your information golden thread',
                  'Boost resilience and emergency readiness',
                  'Find facts in digital records instead of physical surveys',
                ].map((text, index) => (
                  <Row key={index}>
                    <Col xs={1}><DoneIcon color='primary' /></Col>
                    <Col className='ms-1 mb-2'>
                      {text} {index === 0 && <a href="https://buildingsafety.campaign.gov.uk/making-buildings-safer/building-safety-regulator-news/understanding-the-golden-thread/" target="_blank" rel="noopener noreferrer"><OpenInNewIcon style={{ fontSize: 16, verticalAlign: 'middle', marginLeft:"3px", marginBottom:"3px" }} /></a>}
                    </Col>
                  </Row>
                ))}

                      </Card>
                      </Col>
              </Row>
            </div>
          </Container>
        </div>

        {/*<ThreePartProcess />*/}

        <Container id="features" className='home-detail_section'>
        <div className='padding-xhuge'>
            {/*<Chip label="How it works" variant="outlined" className='ps-2 pe-2 mb-4 tag-selected' />*/}
            <Container>
            <Row className='d-flex justify-content-center' style={{ display: 'flex', alignItems: 'center' }}>
              <Col xs={12} lg={10} className='p-0'>
              <p className='xlarge-font blue-font text-center m-0 p-0' >Translate unstructured file stores into</p>
                <p className='xlarge-font blue-font text-center'><b>categorised knowledge banks</b>
                </p>
              </Col>
              <Row className='d-flex align-items-center justify-content-center mt-4'>
      <Col xs={12} md={8} className='d-flex flex-column align-items-center'>
        {/*<p>Want to find out more?</p>
            <button className="btn btn-tertiary me-0" onClick={handleShowStartupModal}>
              Download Use Case Pack <FontAwesomeIcon size='sm' icon={faArrowUpRightFromSquare} className='ms-2' />
            </button>*/}
            <button
              className="btn btn-primary ms-0"
            >
              <ScrollLink spy to="contact">
                      Request a demo
                    </ScrollLink>
            </button>
          </Col>
        </Row>
            </Row>
            </Container>
            </div>
            <div className='padding-medium'>
            <Row className='product-text-img_grid'>
              <Col xs={12} lg={6}>
                <p className='medium-font blue-font'>Get to the detail faster</p>
                <p className='mt-2'>Unlike other solutions, our AI is ready to go out-the-box. All you have to do is pick from a list of popular classification standards, or define your own.</p>
              </Col>
              <Col xs={{ order: windowWidth < 991 ? 'first' : 'last' }}>
              <div className="gif-container">
                <a className="gif-link" href="#" onClick={handleImageClick}>
                <img
                className="product-slider-image-3d"
                src={`${process.env.PUBLIC_URL}/hoppa_img_config.png`}
                alt="GIF"
                onClick={(event) => handleImageClick(event, `${process.env.PUBLIC_URL}/hoppa_img_config.png`)}
              />  
                </a>
              </div>
              </Col>
            </Row>
            
            
          </div>
          <div className='padding-xhuge'>
          <Row className='product-text-img_grid'>
              <Col xs={12} lg={6}>
                <p className='medium-font blue-font'>Find those needles in the haystack</p>
                <p className='mt-2'>Tell hoppa the key questions you want to ask of your data and our powerful Natural Language algorithms will find the answers nested deep in your files.</p>
              </Col>
              <Col xs={{ order: windowWidth < 991 ? 'first' : 'last' }}>
              <div className="gif-container">
                <a className="gif-link" href="#" onClick={handleImageClick}>
                  <img
                  className="product-slider-image-3d"
                  src={`${process.env.PUBLIC_URL}/hoppa_gif_search.gif`}
                  alt="GIF"
                  onClick={(event) => handleImageClick(event, `${process.env.PUBLIC_URL}/hoppa_gif_search.gif`)}
                />  
                  </a>
                </div>
              </Col>
            </Row> 
          </div>
          <div className='padding-xhuge'>
          <Row className='product-text-img_grid'>
              <Col xs={12} lg={6}>
                <p className='medium-font blue-font'>Trustworthy AI that puts you in the driving seat</p>
                <p className='mt-2'>Harness the power of GPT-4.5 to process textual and image based files. Our AI is traceable, transparent and modest - it explains its thinking and flags when it needs human assistance.</p>
              </Col>
              <Col xs={{ order: windowWidth < 991 ? 'first' : 'last' }}>

              <div className="gif-container">
                <a className="gif-link" href="#" onClick={handleImageClick}>
                <img
                className="product-slider-image-3d"
                src={`${process.env.PUBLIC_URL}/hoppa_gif_results.gif`}
                alt="GIF"
                onClick={(event) => handleImageClick(event, `${process.env.PUBLIC_URL}/hoppa_gif_results.gif`)}
              />  
                </a>
              </div>
            </Col>
            </Row>
          </div>

          <div className='padding-xhuge'>
          <Row className='product-text-img_grid'>
              <Col xs={12} lg={6}>
                <p className='medium-font blue-font'>Unlock the power of structured information</p>
                <p className='mt-2'>Upload your files and receive results in minutes - hoppa will sort your files into a digestible, editable and exportable information pack.</p>
              </Col>
              <Col xs={{ order: windowWidth < 991 ? 'first' : 'last' }}>

              <div className="gif-container">
                <a className="gif-link" href="#" onClick={handleImageClick}>
                <img
                className="product-slider-image-3d"
                src={`${process.env.PUBLIC_URL}/hoppa_gif_exports.gif`}
                alt="GIF"
                onClick={(event) => handleImageClick(event, `${process.env.PUBLIC_URL}/hoppa_gif_exports.gif`)}
              />  
                </a>
              </div>
            </Col>
            </Row>
          </div>
          

          {/*<div className='padding-xhuge'>
            <Row className='product-text-img_grid'>
              <Col xs={12} lg={5}>
                <b className='medium-font blue-font'>No strings attached</b>
                <p className='mt-2'>When you're done simply export your results. Your data will be accessible for 30 days but if you want to delete it sooner you can.</p>
              </Col>
              <Col xs={{ order: windowWidth < 991 ? 'first' : 'last' }}>
              <div className="gif-container">
                <a className="gif-link" href="#" onClick={handleImageClick}>
                <img
                className="product-slider-image-3d"
                src={`${process.env.PUBLIC_URL}/hoppa_img_upload.png`}
                alt="GIF"
                onClick={(event) => handleImageClick(event, `${process.env.PUBLIC_URL}/hoppa_img_upload.png`)}
              />  
                </a>
              </div>
              </Col>
                    </Row>
          </div>*/}          
          {/* Modal for rendering all gifs */}
          <Modal show={showModal} onHide={toggleModal} centered className="fullscreen-modal">
                <Modal.Body>
                  <img src={imageSrc} alt="Image" className="fullscreen-gif" onClick={toggleModal} />
                </Modal.Body>
              </Modal>
        </Container>
        
        <Container id="benefits" className='home-detail_section'>

          <div className='padding-xhuge'>
            {/*<Chip label="What could this mean for you?" variant="outlined" className='ps-2 pe-2 mb-2 tag-selected' />*/}
            <Row className='d-flex justify-content-center'>
            <Col xs={12} md={8}>
            <p className='mt-3 xlarge-font blue-font text-center mb-0'><b>Supercharge productivity</b></p>
            <p className='xlarge-font blue-font text-center'>for a fraction of the effort</p>
            <p className="mt-3 text-center">
            Teams who find answers quickly have the upper hand. Using hoppa means you no longer have to open, read, interpret and categorise files manually, enabling you to benefit from exemplar information control in a few clicks. Use our savings calculator to see how much effort and cost our AI could help you avoid.
            </p>
            </Col>
            </Row>
            {/*<Row className='mt-4'>
                <Pricing />
        </Row>*/}
            <div className='mt-5'>
              <RoiCalculator />
            </div>
          </div>
        </Container >

        {/* News articles / industry view */}
        <Container className='mt-3 mb-5'>
        {/*<Chip label="Why our mission is important" variant="outlined" className='ps-2 pe-2 mb-2 tag-selected' />*/}
        <p className='mt-5 xlarge-font blue-font text-center mb-0'>Our world is built on shared data,</p>
        <p className='xlarge-font blue-font text-center'>and hoppa is on a mission to <b>bridge the divide</b></p>
        <div className='inset-card'>
                  <Row className='case-study-container gx-2 gy-2'>
                  <Col md={12} lg={4}>
                    <Card className='case-study'>
                      <a target="_blank" href="https://www.autodesk.com/blogs/construction/construction-disconnected-fmi-report/">
                        <Card.Img style={{aspectRatio: '940 / 640'}} variant='top' src={`${process.env.PUBLIC_URL}/shell-699945_640.png`}/>
                        <Card.Title><Chip variant='outlined' label='Autodesk' size="small" className='mb-2'/>
                        <h5 className='blue-font'>Construction Disconnected</h5>
                        </Card.Title>
                        <Card.Text>
                          Your workers could be losing two days each week solving avoidable issues and searching for information. 
                        </Card.Text>
                        <Card.Footer>
                          <div className='case-study-card-button'>
                        <div className='circle'>
                            <div className='circle-icon' >
                            <FontAwesomeIcon size='lg' icon={faArrowRight}/>
                            </div>
                            <div className='circle-icon'>
                            <FontAwesomeIcon size='lg' icon={faArrowRight}/>
                            </div>
                          </div>
                          </div>
                        </Card.Footer>
                        </a>
                      </Card>
                    </Col>
                    <Col md={12} lg={4}>
                    <Card className='case-study'>
                      <a target="_blank" href="https://www.cdbb.cam.ac.uk/news/press-release-information-management-construction-can-deliver-productivity-boost">
                        <Card.Img style={{aspectRatio: '940 / 640'}} variant='top' src={`${process.env.PUBLIC_URL}/building-279769_640.png`}/>
                        <Card.Title>
                          <Chip variant='outlined' label='Centre for Digital Built Britain' size="small" className='mb-2'/>
                          <h5 className='blue-font'>The value of information management</h5>
                          </Card.Title>
                        <Card.Text>
                          Find out how improving your information management could deliver a six-fold return on investment.
                        </Card.Text>
                        <Card.Footer>
                          <div className='case-study-card-button'>
                        <div className='circle'>
                            <div className='circle-icon' >
                            <FontAwesomeIcon size='lg' icon={faArrowRight}/>
                            </div>
                            <div className='circle-icon'>
                            <FontAwesomeIcon size='lg' icon={faArrowRight}/>
                            </div>
                          </div>
                          </div>
                        </Card.Footer>
                        </a>
                      </Card>
                    </Col>
                    <Col md={12} lg={4}>
                      <Card className='case-study'>
                      <a target="_blank" href="https://www.gov.uk/government/publications/national-underground-asset-register-unlocking-value-for-industry-and-the-wider-economy/national-underground-asset-register-nuar-economic-case-summary">
                        <Card.Img style={{aspectRatio: '940 / 640'}} variant='top' src={`${process.env.PUBLIC_URL}/construction-site-2630484_640.png`}/>
                        <Card.Title><Chip variant='outlined' label='Geospatial Commission' size="small" className='mb-2'/>
                        <h5 className='blue-font'>Underground assets: the case for change</h5>
                        </Card.Title>
                        <Card.Text>
                          The UK government is prioritising investment to reduce the cost of accidental damage to buried assets. 
                        </Card.Text>
                        <Card.Footer>
                          <div className='case-study-card-button'>
                        <div className='circle'>
                            <div className='circle-icon' >
                            <FontAwesomeIcon size='lg' icon={faArrowRight}/>
                            </div>
                            <div className='circle-icon'>
                            <FontAwesomeIcon size='lg' icon={faArrowRight}/>
                            </div>
                          </div>
                          </div>
                        </Card.Footer>
                        </a>
                      </Card>
                    </Col>
                  </Row>
                  </div>
        </Container>
       
        <div className='triangle-background'>
          <Container id="contact" className='home-detail_section'>
            <div className='padding-xhuge mb-1'>
              <Row>
                <Col xs={12} md={5} className='mt-5 mb-4'>
                <div>
                  {/*<Chip label="Get in touch" variant="outlined" className='ps-2 pe-2 mb-2 tag-selected' />*/}
                  <p className='large-font black-font'>Got a question?</p>
                  <p>To learn about our additional services or share some feedback, you can reach us for a chat at: <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a></p>
                  <p>Or if you'd like to be first in line for exciting updates from hoppa HQ:</p>
                  <button className='btn btn-primary mt-2' onClick={handleManualSubscribeModalRecall}>
                    Subscribe to our mailing list
                  </button>
                  </div>
                </Col>
                <Col xs={0} md={1}></Col>
                <Col>
                  <div className='request-demo-card'>
                    <p className='large-font black-font'>Request a demo</p>
                    <p>Find out more about our cutting-edge AI and discover how hoppa can help you cut costs, de-risk delivery and eliminate waste. </p>
                    <RequestADemo/>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          
        </div>
        
      </main>
      <footer>
        <FooterBar/>
      </footer>

      <Popup
        show={showSessionModal}
        onHide={handleHideSessionModal}
        title="Enter your details"
        image='architecture-2004491_640.png'
        imageAlt='Enter your session key'
        content={
          <div>
            <h5 className='custom-modal-mobile-header blue-font'>Enter your details</h5>
            <Alert variant="info">Can't find your session key? <a href="mailto:hello@hoppa.ai">Get in touch</a>.</Alert>
            <Form
              id='EnterSessionKey'
              noValidate
              validated={validated}
              onSubmit={handleGetResultsButtonClick} >
              {APIError && <Alert variant="danger" dismissible onClose={handleAlertClose}>{APIError}</Alert>}
              <input
                className={'mb-2 custom-input ' + (validated ? 'custom-input-validated' : '')}
                ref={inputRef}
                type="text"
                required
                placeholder='UserId'
                onChange={(e) => setuserId(e.target.value)}
              />
              <input
                className={'custom-input ' + (validated ? 'custom-input-validated' : '')}
                type="text"
                required
                placeholder='Session key'
                onChange={(e) => setResultGuid(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {formError}
              </Form.Control.Feedback>
              <button className='btn btn-secondary m-0 mt-3'>Confirm</button>
            </Form>
          </div>
        }
      />
      <Popup
        show={showStartupModal}
        onHide={handleHideStartupModal}
        title="Get started"
        image='labor-7576514_640.png'
        imageAlt='Get started'
        content={
          <div className='overflow-container'>
            <div className={`form-container ${showSubscribe ? 'slide-out-left' : 'slide-in-left'}`}>
              <Alert variant="info">Our early-release service is only available to members. Register your interest to join our community.</Alert>
              <h5 className='blue-font'>Member Sign In</h5>
              <LoginButton/>
              <LogoutButton/>
              {APIError && <Alert className='m-0 pt-2 pb-2' variant="danger">{APIError}</Alert>}
              <Form
                id='EnterSessionKey'
                noValidate
                validated={validated}
                onSubmit={handleAuthenticate} >
                <input
                  className='custom-input mb-2'
                  aria-label="userId"
                  name="fields[first_name]"
                  placeholder="userId"
                  type="text"
                  required
                  onChange={(e) => setuserId(e.target.value)}
                  value={userId}
                />
                <Form.Control.Feedback type="invalid">
                  {formError}
                </Form.Control.Feedback>
                <input
                  className='custom-input mb-2'
                  aria-label="Password"
                  name="email_address"
                  placeholder="Password"
                  required
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <input type="checkbox" className='me-1' onClick={togglePasswordVisibility}/>Show Password
                <br></br>
                <Form.Control.Feedback type="invalid">
                  {formError}
                </Form.Control.Feedback>
                <button className='btn btn-secondary m-0 mt-3'>Sign in</button>
              </Form>
              <button className='btn btn-link ms-0 ps-0 blue-font' onClick={handleRegisterInterest}>Register your interest &gt;</button>
            </div>
            <div className={`form-container ${!showSubscribe ? 'slide-out-right' : 'slide-in-right'}`}>
          <button className='btn btn-link ms-0 ps-0 blue-font' onClick={handleRegisterInterest}>&lt; Back</button>
          <h5 className='blue-font'>Register your interest</h5>
          <p>One of our experts will get in touch.</p>
          <RequestADemo/>
          </div>
        </div>
        }
      />
      <Overlay 
        subscribeModalShown={subscribeModalShown}
        content={
        <>
          <h2>Subscribe to our mailing list</h2>
          <p>Receive regular updates from hoppa HQ.</p>
          <Subscribe />
        </>
      } />
      <Popup
        show={showSubscribeModal}
        onHide={handleCloseSubscribeModal}
        image='labor-7576514_640.png'
        title='Join our mailing list'
        aria-labelledby="mailing-list-modal-title"
        aria-describedby="mailing-list-modal-description"
        content={
        <div >
          <p id="mailing-list-modal-description">Receive regular updates from hoppa HQ.</p>
          <Subscribe/>
        </div>
        }
        showCloseButton = {false}
        >
      </Popup>
    </div >
  );
};
export default Home;
