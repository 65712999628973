import React, { useState, useEffect, useCallback } from "react";
import BackButton from '../../shared/BackButton';
import { Drawer, List, ListItem, ListItemText, ListItemButton, ListItemIcon, IconButton, Typography, Box, Divider, Button, Stack, Chip, Avatar } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import { useCheckPermissions, useUserManagement } from "../../../contexts/UserManagementContexts";

const Sidebar = ({ organisation, workspace, selectedTab, handleSetSelectedTab, handleSetSelectedPage, handleSetSelectedConnector, dataSources, handleAddDataSource, handleDeleteDataSource }) => {
    const checkPermissions = useCheckPermissions()
    const { user, isLoading, isLoadingOrgs } = useUserManagement();
    const [isAdmin, setIsAdmin] = useState(false)
    const drawerWidth = 240;

    useEffect(() => {
        if (!isLoading && !isLoadingOrgs) {
            const isAuthorised = checkPermissions(['settings:sessions'])
            setIsAdmin(isAuthorised)
        }
    }, [user, isLoading, isLoadingOrgs])

    const addDataSource = () => {
        const sourceName = 'New data source'

        handleSetSelectedTab(sourceName)
        handleSetSelectedPage('AddDataSource')

        if (dataSources.some((dataSource) => dataSource.name === sourceName)) {
            return
        }

        handleAddDataSource({ name: sourceName })
        
    }

    const tabSelection = (tab, page) => {
        if (tab !== 'New data source') {
            handleDeleteDataSource('New data source')
        }
        handleSetSelectedTab(tab)
        handleSetSelectedPage(page)
        handleSetSelectedConnector(tab)

        // const matchingConnector = dataSources.find(dataSource => dataSource.displayName === tab);
        // // Set the selectedConnector state to the found data source
        // handleSetSelectedConnector(matchingConnector || null); // Set to null if no match is found
        // console.log(matchingConnector)

        
    }

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    position: 'relative', // Relative to parent, not the viewport
                },
            }}
            variant="permanent"
            anchor="left"
        >
            <Box sx={{ overflow: 'auto', backgroundColor: 'white', margin: '0.5rem', display: 'flex', flexDirection: 'column', height: '100%' }}>
                <BackButton destinationText='workspace' destination={`/${organisation}/${workspace}/workspace`} />
                <List style={{ flexGrow: 1 }}>
                    <ListItem key='Overview' disablePadding>
                        <ListItemButton selected={selectedTab === 'Overview'} onClick={() => tabSelection('Overview', 'Overview')}>
                            <ListItemText primary='Overview' />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key='Refinery' disablePadding>
                        <ListItemButton selected={selectedTab === 'Refinery'} onClick={() => tabSelection('Refinery', 'Refinery')}>
                            <ListItemText primary='Data Refinery' />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="Data sources" />
                        <IconButton onClick={() => addDataSource()}>
                            <AddIcon />
                        </IconButton>
                    </ListItem>
                    <Divider />
                    {dataSources.length === 0 ?
                        (
                            <Typography variant="body2" style={{ paddingLeft: '16px', color: '#999999' }}>
                                No data sources added
                            </Typography>
                        )
                        :
                        (dataSources.map((dataSource, index) => (
                            <ListItem key={dataSource.name} disablePadding>
                                <ListItemButton selected={selectedTab === dataSource.name} onClick={() => tabSelection(dataSource.name, 'ConfigureDataSource')}>
                                    <ListItemText>
                                        <Typography variant="body2" style={{ color: '#999999' }}>
                                            {dataSource.name}
                                        </Typography>
                                    </ListItemText>
                                </ListItemButton>
                            </ListItem>
                        ))
                        )
                    }
                </List>
                {isAdmin ? 
                <List style={{ position: 'relative' }}>
                    <ListItem key={'Settings'} disablePadding>
                        <ListItemButton selected={selectedTab === 'Settings'} disabled={!isAdmin} onClick={isAdmin ? () => tabSelection('Settings', 'Settings') : null}>
                            <ListItemIcon style={{minWidth:'30px'}}>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary='Settings' />
                        </ListItemButton>
                    </ListItem>
                </List>
                : <></>}
            </Box>
        </Drawer>

    )
}

export default Sidebar;