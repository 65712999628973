// SessionContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { humanId, poolSize, minLength, maxLength } from 'human-id';
import { useAuth0 } from "@auth0/auth0-react";

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const { user, isAuthenticated } = useAuth0();
  const [sessionGuid, setSessionGuid] = useState('');
  const [userId, setUserId] = useState('');

  const sanitizeUserId = (userId) => {
    return userId.split('|')[1];  // Split by '|' and return the second part
  };

  useEffect(() => {
    if (isAuthenticated) {
      let userId = user?.sub
      userId = sanitizeUserId(userId);
      // Set user ID from Auth0 when the user is authenticated
      setUserId(userId); 

    // Generate session GUID when SessionProvider is rendered.
    const newSessionGuid = generateSessionGuid();
    setSessionGuid(newSessionGuid);
    }
  }, [isAuthenticated, user]);

  const generateSessionGuid = () => {
    // Your logic to generate a new session GUID, for example:
    return humanId({ separator: '-', capitalize: false });
  };

  const setSession = (guid) => {
    setSessionGuid(guid);
  };

  return (
    <SessionContext.Provider value={{ sessionGuid, setSession, userId }}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};
