import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, Button, Tab, Nav, Table } from 'react-bootstrap';
import Chip from '@mui/material/Chip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Portal } from '@mui/base/Portal';
import tagSuggestions from '../../baseStandards/tagSuggestions.json';
import { useUserManagement, useCheckPermissions } from '../../contexts/UserManagementContexts';

const DefineWorkspaceEnrichers = ({ workspaceStandard, setWorkspaceStandard }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const [validated, setValidated] = useState(false);
  const [searchTermName, setSearchTermName] = useState('');
  const [searchTermBody, setSearchTermBody] = useState('');
  const [editIndex, setEditIndex] = useState(null);

  const container = useRef(null);
  const [tagInput, setTagInput] = useState('');

  const { user, hasOrgs, organizations, currentOrg, setCurrentOrg, isLoadingOrgs, error } = useUserManagement();
  const checkPermissions = useCheckPermissions()

  const [hasUpdatePermission, setHasUpdatePermission] = useState(false); // If user is able to create new workspaces


  useEffect(() => {
    setAvailableTags(tagSuggestions);
  }, []);

  // Permission check
  useEffect(() => {
    if (!isLoadingOrgs) {
        const updatePermission = checkPermissions(['update:workspaces'], currentOrg.name)
        setHasUpdatePermission(updatePermission)
    }
  }, [isLoadingOrgs, currentOrg]);

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const handleTagSelect = (tag) => {
    const updatedTags = workspaceStandard.enrichers.tags.includes(tag)
      ? workspaceStandard.enrichers.tags.filter((t) => t !== tag) // Remove tag
      : [...workspaceStandard.enrichers.tags, tag]; // Add tag

    setWorkspaceStandard((prevWorkspaceStandard) => ({
      ...prevWorkspaceStandard,
      enrichers: {
        ...prevWorkspaceStandard.enrichers,
        tags: updatedTags,
      },
    }));
  };

  const handleTagInputChange = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
    setTagInput(event.target.value);
  };

  const handleAddTag = (tag) => {
    if (tag.trim() !== '') {
      const updatedTags = [...workspaceStandard.enrichers.tags, tag.trim()];

      setWorkspaceStandard((prevWorkspaceStandard) => ({
        ...prevWorkspaceStandard,
        enrichers: {
          ...prevWorkspaceStandard.enrichers,
          tags: updatedTags,
        },
      }));

      setTagInput('');
    }
  };

  const isTagSelected = (tag) => {
    return workspaceStandard.enrichers.tags.includes(tag);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddTag(tagInput);
    }
  };

  const handleAddSearchTerm = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!searchTermName || !searchTermBody) {
      setValidated(true);
      return;
    }
    setValidated(false);

    const newTerm = {
      searchTerm: `${searchTermName}; ${searchTermBody}`,
      name: searchTermName,
      body: searchTermBody,
      active: true,
    };

    const updatedSearchTerms = editIndex !== null
      ? workspaceStandard.enrichers.searchTerms.map((term, idx) => (idx === editIndex ? newTerm : term))
      : [...workspaceStandard.enrichers.searchTerms, newTerm];

    setWorkspaceStandard((prevWorkspaceStandard) => ({
      ...prevWorkspaceStandard,
      enrichers: {
        ...prevWorkspaceStandard.enrichers,
        searchTerms: updatedSearchTerms,
      },
    }));

    setSearchTermName('');
    setSearchTermBody('');
    setEditIndex(null);
  };

  const handleRemoveSearchTerm = (index) => {
    const updatedSearchTerms = workspaceStandard.enrichers.searchTerms.filter((_, i) => i !== index);

    setWorkspaceStandard((prevWorkspaceStandard) => ({
      ...prevWorkspaceStandard,
      enrichers: {
        ...prevWorkspaceStandard.enrichers,
        searchTerms: updatedSearchTerms,
      },
    }));
  };

  const handleEditSearchTerm = (index) => {
    setEditIndex(index);
    const term = workspaceStandard.enrichers.searchTerms[index];
    setSearchTermName(term.name);
    setSearchTermBody(term.body);
  };

  const handleCheckboxChange = (index) => {
    const newSearchTerms = [...workspaceStandard.enrichers.searchTerms];
    newSearchTerms[index].active = !newSearchTerms[index].active;

    setWorkspaceStandard((prevWorkspaceStandard) => ({
      ...prevWorkspaceStandard,
      enrichers: {
        ...prevWorkspaceStandard.enrichers,
        searchTerms: newSearchTerms,
      },
    }));
  };

  const enrichersData = {
    1: {
      title: 'Search Terms',
      content: (
        <div className='text-start'>
          <div ref={container}></div>
          <Portal container={container.current}>
            <Form noValidate validated={validated} onSubmit={handleAddSearchTerm}>
              <div className="d-flex align-items-center">
                <Form.Control
                  required
                  disabled={!hasUpdatePermission}
                  type="text"
                  placeholder="Search Term Name (e.g., Published Date)"
                  value={searchTermName}
                  onChange={(e) => setSearchTermName(e.target.value)}
                />
                <Button variant="secondary" type='submit' disabled={!hasUpdatePermission}>
                  {editIndex !== null ? "Update" : "Add"}
                </Button>
              </div>
              <Form.Control
                required
                disabled={!hasUpdatePermission}
                as="textarea"
                rows={3}
                placeholder="Search Term Body (e.g., 'find the date when the document was last published.')"
                value={searchTermBody}
                onChange={(e) => setSearchTermBody(e.target.value)}
                className='mb-2'
              />
              <Form.Control.Feedback type="invalid">
                Name and body cannot be blank.
              </Form.Control.Feedback>
            </Form>
          </Portal>

          <div style={{ marginTop: '20px' }}>
            {workspaceStandard.enrichers.searchTerms.length > 0 && (
              <Table hover responsive>
                <thead>
                  <tr style={{ backgroundColor:"transparent !important"}}>
                    <th className="text-center" style={{ width: '5%',  }}>Active</th>
                    <th className="text-center" style={{ width: '5%' }}>Edit</th>
                    <th style={{ paddingLeft: "20px", width: '22%' }}>Name</th>
                    <th style={{ wordWrap: 'break-word' }}>Body</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {workspaceStandard.enrichers.searchTerms.map((term, index) => (
                    <tr key={index}>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          checked={term.active}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>
                      <td className="text-center">
                        <FontAwesomeIcon
                          icon={faPencilAlt}
                          onClick={!hasUpdatePermission ? null: () => handleEditSearchTerm(index) }
                          style={{
                            cursor: hasUpdatePermission ? 'pointer' : 'auto'
                        }}
                        />
                      </td>
                      <td style={{ wordWrap: 'break-word', maxWidth: '150px', paddingLeft: "20px" }}>{term.name}</td>
                      <td style={{ wordWrap: 'break-word', maxWidth: '300px' }}>{term.body}</td>
                      <td className="text-center">
                        <FontAwesomeIcon
                          icon={faTrash}
                          onClick={!hasUpdatePermission ? null: () => handleRemoveSearchTerm(index)}
                          style={{
                            cursor: hasUpdatePermission ? 'pointer' : 'auto',
                            color: hasUpdatePermission ? 'red' : 'grey'
                        }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      )
    },
    2: {
      title: 'Tags',
      content: (
        <div className='text-start' style={{ marginTop: "5px" }}>
          <Form.Group className='mb-3'>
            <Form.Control
              type="text"
              disabled={!hasUpdatePermission}
              placeholder="Type new tag and press enter to create"
              value={tagInput}
              onChange={handleTagInputChange}
              onKeyDown={handleKeyPress}
            />
          </Form.Group>
          <Row>
            <Col md={6}>
              <h5>Suggested tags</h5>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {availableTags
                  .filter(tag => !workspaceStandard.enrichers.tags.includes(tag)) // Exclude selected tags
                  .map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      className={isTagSelected(tag) ? "tag-selected mb-1 me-1 p-1" : "filledChip mb-1 me-1 p-1"}
                      onClick={hasUpdatePermission ? () => handleTagSelect(tag) : null}
                    />
                  ))}
              </div>
            </Col>
            <Col md={6}>
              <h5>Selected tags</h5>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {workspaceStandard.enrichers.tags.map((tag) => (
                  <Chip key={tag} label={tag} className="tag-selected mb-3 me-1 p-1" onDelete={hasUpdatePermission ? () => handleTagSelect(tag) : null} />
                ))}
              </div>
            </Col>
          </Row>
        </div>
      )
    }
    
    
    
  };

  return (
    <Container>
      <Row>
        <Col>
          <Tab.Container activeKey={selectedTab} onSelect={handleTabSelect}>
            <Nav className="nav nav-pills">
              <Nav.Item>
                <Nav.Link eventKey={1}>Search Terms</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={2}>Tags</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey={1}>
                {enrichersData[1].content}
              </Tab.Pane>
              <Tab.Pane eventKey={2}>
                {enrichersData[2].content}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
    </Container>
  );
};

export default DefineWorkspaceEnrichers;
