import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { Helmet } from 'react-helmet';

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID


const root = createRoot(document.getElementById('root'));
root.render(
  <>
  {/* Conditionally add the noindex meta tag for staging */}
  {process.env.REACT_APP_ENV === 'staging' && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
  <Auth0Provider
    domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin
      }}
    >
  {/* <React.StrictMode>
    <App />
  </React.StrictMode> */}
  <App />
  </Auth0Provider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
