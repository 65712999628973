import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Typography, Box, Button, IconButton, ButtonGroup, Chip, Switch, FormControlLabel } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import SaveIcon from '@mui/icons-material/Save';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ClearIcon from '@mui/icons-material/Clear';
import ReplayIcon from '@mui/icons-material/Replay';
import { Tooltip, MenuItem } from '@mui/material';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { useUserManagement } from '../../contexts/UserManagementContexts';
import { useParams } from "react-router-dom";
import RenameFiles from './RenameFiles';
import { blue } from '@mui/material/colors';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const csvConfig = mkConfig({
  fieldSeparator: ',',
  decimalSeparator: '.',
  useKeysAsHeaders: true,
});

let config = {
  headers: {
    'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
  }
}

const ResultsTable = ({ sessionStandard, classifierHeaders, searchHeaders, baseResults, savedEdits, setSavedEdits, tags, handleSetLoadingState }) => {

  // Page load
  const { user } = useUserManagement();
  const { currentOrg, workspace, sessionId, userId } = useParams();

  // Table content  
  const [mergedResults, setMergedResults] = useState([])
  const [modifiedFileNames, setModifiedFileNames] = useState({});

  // Table interactions
  const [showTags, setShowTags] = useState(false)
  const [showSearchTerms, setShowSearchTerms] = useState(false)
  const [showRenameSettings, setShowRenameSettings] = useState(false)
  const [selectedValue, setSelectedValue] = useState(''); // State to manage the selected value
  const [newEdits, setNewEdits] = useState();
  const [allEdits, setAllEdits] = useState();
  const [isUnsavedEdits, setIsUnsavedEdits] = useState(false);
  const [isMerged, setIsMerged] = useState(false)

  const inputRef = useRef(null); // Reference to the input element
  const [rowId, setRowId] = useState('')
  const [searchTermName, setSearchTermName] = useState('')

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isUnsavedEdits) {
        event.preventDefault();
        event.returnValue = ''; // Standard way to show the confirmation dialog
      }
    };

    // Add the event listener when there are unsaved changes
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isUnsavedEdits]); // Re-run the effect if `unsavedEdits` changes


  // Effect to manage loading edits from storage.
  useEffect(() => {
    //console.log('Merged results at start of run are: ', mergedResults)

    // If base results but no merged results then populate the merged results array from storage. 
    if (baseResults && mergedResults.length == 0) {
      //console.log('Adding base results')
      setMergedResults(baseResults)
    }

    // If there are merged results and edits from storage then merge them, but only execute once. 
    if (mergedResults && !isMerged && savedEdits && Object.keys(savedEdits).length > 0) {
      updateTable(savedEdits)
      setIsMerged(true)
    }

  }, [savedEdits, baseResults, mergedResults, isMerged])

  // Effect to manage merging new user edits
  useEffect(() => {
    const handleUpdateTable = async (newEdits) => {
      await updateTable(newEdits)
      setNewEdits(null)
      setIsUnsavedEdits(true)
    }

    if (newEdits) {
      handleUpdateTable(newEdits);
    }

  }, [newEdits])

  const updateTable = async (newEdits) => {

    // Call the onSaveRow prop with the edited row
    await handleTableDataChange(Object.values(newEdits));
    //console.log('Updating table with', newEdits);

    // Persisting edits in state for write back to database on user trigger. 
    setAllEdits(prevAllEdits => {
      const updatedAllEdits = {
        ...prevAllEdits, // Keep the existing rows
        ...newEdits      // Add or update rows from editedRow
      };

      //console.log('Previous user edits:', prevAllEdits);
      //console.log('Updated user edits:', updatedAllEdits);

      return updatedAllEdits;
    });
  };

  const handleTableDataChange = (updatedData) => {
    setMergedResults((prevMergedResults) => {
      // Map over the existing tableData
      const mergedData = prevMergedResults.map((row) => {
        // Find the edited row in the updatedData using the name property
        const editedRow = updatedData.find((updatedRow) => updatedRow.name === row.name);
        // If the edited row exists, merge it with the existing row, otherwise, return the existing row unchanged
        return editedRow ? { ...row, ...editedRow } : row;
      });

      return mergedData;
    });
  };

  const handleToggleTags = () => {
    setShowTags(!showTags);
  };

  const handleToggleSearchTerms = () => {
    setShowSearchTerms(!showSearchTerms);
  };

  // Open the modal
  const handleOpenRenameSettingsModal = () => {
    setShowRenameSettings(true);
  }

  // Close the modal
  const handleCloseRenameSettingsModal = () => {
    setShowRenameSettings(false);
  }

  // Close the modal
  const handleSaveEdits = async () => {

    handleSetLoadingState('Saving edits...', true)

    const allEditsJSON = JSON.stringify(allEdits);

    const allEditsJSONforBlob = new Blob([allEditsJSON], { type: 'application/json' });

    const formData = new FormData();

    formData.append('file', allEditsJSONforBlob, 'userEdits.json');
    formData.append('organisation', currentOrg ? currentOrg : userId);
    formData.append('workspace', workspace ? workspace : '');
    formData.append('sessionId', sessionId);

    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;

      //console.log("FormData content:", formDataObject);

    })

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/FileUploadTrigger`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
          },
        }
      );

      //console.log("File upload successful. Server response:", response.data);

    }
    catch (error) {
      console.error("Error saving edits:", error.message);
    } finally {
      handleSetLoadingState('', false)
      setSavedEdits(allEdits)
      setIsUnsavedEdits(false)
    }
  }

  const handleResetEdits = async () => {
    setAllEdits([])
    setMergedResults(baseResults)
    setIsMerged(false)
    setIsUnsavedEdits(false)
  }

  const handleDeleteAllEdits = async () => {

    handleSetLoadingState('Deleting all saved edits...', true)

    const allEditsJSON = JSON.stringify({});

    const allEditsJSONforBlob = new Blob([allEditsJSON], { type: 'application/json' });

    const formData = new FormData();

    formData.append('file', allEditsJSONforBlob, 'userEdits.json');
    formData.append('organisation', currentOrg ? currentOrg : userId);
    formData.append('workspace', workspace ? workspace : '');
    formData.append('sessionId', sessionId);

    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;

      //console.log("FormData content:", formDataObject);

    })

    try {
      const response = await axios.post(
        `${API_BASE_URL}/api/FileUploadTrigger`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
          },
        }
      );

      //console.log("File upload successful. Server response:", response.data);

    }
    catch (error) {
      console.error("Error saving edits:", error.message);
    } finally {
      handleSetLoadingState('', false)
      setAllEdits([])
      setMergedResults(baseResults)
      setIsMerged(false)
      setIsUnsavedEdits(false)
      setSavedEdits([])
    }
  }

  const handleRenameFiles = (codeSettings) => {
    //console.log("Rename taking place with following settings: ", codeSettings);
    //console.log("Current table data used for rename: ", mergedResults);
    //console.log("List of file names: ", mergedResults.map(file => file.name));
    //console.log("List of classifier names: ", classifierHeaders);

    // Initialize an object to store the new file names
    const newFileNames = {};

    // Initialize an object to store the sequential numbers for each file name pattern
    const sequentialNumbers = {};

    // Iterate over each file in tableData
    const updatedMergedResults = mergedResults.map((file) => {
      let newFileName; // Declare it here for wider scope
      let numberedFileName; // To hold the final file name with numbers

      if (codeSettings.advancedMode) {
        // Start with the advancedMode pattern
        newFileName = codeSettings.advancedMode;

        // Find all placeholders (e.g., {Originator}, {Volume}, {Role}) using regex
        const regex = /\{(.*?)\}/g; // Matches everything inside curly braces

        // Replace placeholders with actual classifier values
        newFileName = newFileName.replace(regex, (match, p1) => {
          const classifierName = p1.trim(); // Extract the classifier name inside the curly braces

          let classifierValue;

          //console.log('Name is', classifierName)

          // First check the string contains code parts,otherwise just return the original string
          if (classifierName === "File Name") {
            // Remove the file extension from file.name
            classifierValue = file.name.split('.').slice(0, -1).join('.'); // Gets the name without extension
          } else if (classifierName === "Number") {
            classifierValue = '{Number}'; // You can keep this empty for now
          } else {
            classifierValue = file[classifierName]?.code || classifierName; // Get the classifier value from the file data
          }
          return classifierValue; // Replace with classifier value or an empty string if not found
        });

      } else {
        // Basic mode logic
        // Extract classifier codes for the current file
        const classifierCodes = classifierHeaders.map(classifierName => {
          return file[classifierName].code || ''; // If classifier is found, extract code part, otherwise return an empty string
        });

        // Join classifier codes into a single string using a separator
        const separator = codeSettings.documentSeparator;
        newFileName = classifierCodes.join(separator); // Assign the joined string to newFileName
      }

      // Check if the new file name pattern already exists in the sequentialNumbers object
      if (!sequentialNumbers[newFileName]) {
        // Initialize the sequential number for this pattern to 0
        sequentialNumbers[newFileName] = 0;
      }

      // Increment the sequential number for this pattern
      sequentialNumbers[newFileName]++;

      // Generate the sequential number string with leading zeros based on the specified number of digits
      const sequentialNumberString = sequentialNumbers[newFileName].toString().padStart(codeSettings.numberingSystem.digits, '0');

      // Finalize the numbered file name
      if (codeSettings.advancedMode) {
        // Replace the {Number} placeholder in the new file name with the sequential number
        numberedFileName = newFileName.replace(/\{Number\}/g, sequentialNumberString);
      } else {
        // Combine the new file name pattern and the sequential number
        numberedFileName = `${newFileName}${codeSettings.documentSeparator}${sequentialNumberString}`;
      }

      // Add the original file name and the corresponding new file name to the newFileNames object
      newFileNames[file.name] = numberedFileName;

      // Add the new file name to the file object
      return { ...file, newFileName: numberedFileName }; // Return the updated file object
    });

    // Log the updated tableData
    //console.log("Updated table data with new file names: ", updatedMergedResults);

    // Update the tableData state with the new file names
    setMergedResults(updatedMergedResults);

    // Update the modifiedFileNames state with the new file names
    setModifiedFileNames(newFileNames);
    //console.log(modifiedFileNames);
  };

  const handleDownloadFiles = async () => {
    try {
      handleSetLoadingState('Zipping files for download...', true)

      let request_url

      if (userId) {
        request_url = `${API_BASE_URL}/api/DownloadDocumentsTrigger?sessionId=${sessionId}&containerName=${userId}`
      } else {
        request_url = `${API_BASE_URL}/api/DownloadDocumentsTrigger?sessionId=${sessionId}&workspace=${workspace}&containerName=${currentOrg}`
      }

      const response = await axios.post(
        request_url,
        {
          modifiedFileNames: modifiedFileNames,
        },
        {
          responseType: 'arraybuffer',
          headers: {
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
          }
        }
      );


      // Check if the response is successful
      if (response.status === 200) {
        // Create a blob containing the files
        const blob = new Blob([response.data], { type: 'application/zip' });

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'downloadedFiles.zip';

        // Trigger the download
        link.click();

        // Remove the link element only if it's a child of the document body
        if (document.body.contains(link)) {
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        }

      } else {
        console.error('Failed to fetch files from the blob.');
      }
    } catch (error) {
      console.error('Error downloading files:', error);
    } finally {
      handleSetLoadingState('', false)
    }
  };


  // Function to flatten a single object
  const flattenData = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, item]) =>
        typeof item === 'object' && item !== null && 'code' in item
          ? [key, item.code]  // Flatten if 'code' exists
          : [key, item]        // Keep item as is
      )
    );

  ////console.log('Columns:', columns);
  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);

    // Flatten the data array
    const flattenedDataArray = rowData.map(flattenData);

    // Generate and download CSV
    const csv = generateCsv(csvConfig)(flattenedDataArray);
    download(csvConfig)(csv);
  };

  const handleExportData = () => {
    // Flatten the data array
    const flattenedDataArray = mergedResults.map(flattenData);

    // Generate and download CSV
    const csv = generateCsv(csvConfig)(flattenedDataArray);
    download(csvConfig)(csv);
  };

  // Define column names to be passed to material-react-table component
  // Note, I tried putting this into the table component for simplicity, with classifiers passed as props but it created and infinite loop and I couldn't work out why
  const columns = useMemo(() => {

    // Columns are generated dynamically from the fields in the 'data' prop passed to the table component (data=tableData)
    // the material react table component takes the data prop and uses the 'accessorKey' to map data to columns
    const dynamicColumns = [
      // This first column is simple, it looks for 'name' in the data prop, which corresponds to file name
      {
        accessorKey: "name",
        header: "File Name",
        enableEditing: false,
        //muiTableHeadCellProps: { sx: { color: "green" } }
        Cell: ({ renderedCellValue }) => <strong>{renderedCellValue}</strong> //optional custom cell render
      },
    ];

    if (!sessionStandard || !classifierHeaders || !searchHeaders) {
      return dynamicColumns;
    }

    // Conditionally add the newFileName column if it exists in the tableData
    if (mergedResults && mergedResults.length > 0 && mergedResults[0].hasOwnProperty('newFileName')) {
      dynamicColumns.push({
        accessorKey: "newFileName",
        header: "Coded File Name",
        Cell: ({ renderedCellValue }) => <span>{renderedCellValue}</span>
      });
    }

    // The rest of the columns are more complicated; the classifier names are extracted from the 'User Standard' and iterated over
    // The classifier name is then passed as the accessorKey to get the values for that code
    classifierHeaders.forEach((classifierName, index) => {
      // Get the results data associated with each code part which needs to be unpacked positionally (code, certainty, description, emoji)
      const classifierData = sessionStandard.classifiers.find(classifier => classifier.name === classifierName);
      dynamicColumns.push({
        accessorKey: `${classifierName}.code`,
        header: classifierName,
        filterSelectOptions: (classifierData && classifierData.data) ?
          classifierData.data.map(item => ({ value: item.code, label: `${item.code} - ${item.description}` })) : [],
        filterVariant: 'select',
        editVariant: 'select',
        editSelectOptions: ({ cell, row }) => {

          let editSelectOptions

          if (classifierData.isHierarchy) {

            const parentColumnValue = index > 0 ? row.original[classifierHeaders[index - 1]].code : null; // Use the previous column based on the index

            // Filter the options based on the value of the other column
            editSelectOptions = (classifierData && classifierData.data)
              ? classifierData.data.filter(item => item.parent === parentColumnValue).map(item => ({
                value: item.code,
                label: `${item.code} - ${item.description}`
              }))
              : [];

          } else {
            editSelectOptions = (classifierData && classifierData.data)
              ? classifierData.data.map(item => ({
                value: item.code,
                label: `${item.code} - ${item.description}`
              }))
              : []
          }
          return editSelectOptions
        },
        muiEditTextFieldProps: ({ row, cell }) => ({
          select: true,
          onKeyDown: (e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.shiftKey = true;
            }
          },
          onChange: (e) => {
            setSelectedValue(e.target.value || ""); // Update state

            // Update the row's cached value so that it reflects in the table
            row._valuesCache[cell.column.id] = e.target.value;

            const updatedRow = newEdits && newEdits[row.id]
              ? {
                ...newEdits[row.id],
                [classifierName]: {
                  ...newEdits[row.id][classifierName],  // Spread the existing classifierName object
                  code: e.target.value,                  // Update the 'code' field within classifierName
                }
              }
              : {
                ...row.original,
                [classifierName]: {
                  ...row.original[classifierName],       // Spread the existing classifierName object
                  code: e.target.value,                  // Add or update the 'code' field within classifierName
                }
              };

            //console.log('Updated row is', updatedRow)

            setIsUnsavedEdits(true)

            setNewEdits((prevEditedRow) => ({
              ...prevEditedRow,
              [row.id]: updatedRow,
            }))
          }
        }),

        Cell: ({ cell, row }) => {
          if (!classifierData) {
            return null; // Return null if classifierData is not found, i.e. if there are no classifier names
          }

          const fileName = row.original.name;
          const cellValue = cell.getValue();

          // Find the corresponding selectedCodeResults for the current filename
          const selectedFileResults = mergedResults.find(item => item.name === fileName);

          // Check if selectedCodeResults is found
          if (!selectedFileResults || !selectedFileResults[classifierName]) {
            return null; // Return null if selectedCodeResults or classifier data is not found for the current filename
          }

          // In order to display a tooltip of the description (which is drawn from the user standard), we create a variable that uses the code part from the result as the key to access its properties from the user standard
          const selectedCodeInformation = classifierData.data.find(item => item.code === cell.getValue());

          // In order to display info from the results file (certainty and explanation) they need to be mapped to the code part
          const selectedCodeResults = baseResults.find(item => item.name === fileName)[classifierName];
          const selectedCodeEmoji = selectedCodeResults.emoji;
          const selectedCodeExplanation = selectedCodeResults.explanation;

          const isEdited = cellValue !== selectedCodeResults.code;

          return (
            <div>
              <Tooltip title={selectedCodeInformation ? selectedCodeInformation.description : ''} arrow>
                <span>
                  <span>{cell.getValue()}</span> {/* Display code */}
                </span>
              </Tooltip>
              {/* Check if the cell value matches the classifier code */}
              {isEdited ? (
                // Render "User edited" message and apply styles
                <span style={{ fontStyle: 'italic', color: 'blue' }}>edited</span>
              ) : (
                // Render emoji with tooltip
                <Tooltip title={selectedCodeExplanation} arrow>
                  <span>{selectedCodeEmoji}</span>
                </Tooltip>
              )}
            </div>
          );
        },
      });
    });

    const TagsCell = ({ tags }) => {
      // Split the tags string into an array using comma as the separator
      const tagArray = tags.split(',');

      return (
        <div>
          {tagArray.map((tag, index) => (
            <Chip key={index} label={tag.trim()} style={{ marginRight: '5px', marginBottom: '5px' }} />
          ))}
        </div>
      );
    };

    // Add column for tags
    if (showTags) {
      dynamicColumns.push({
        accessorKey: "tags",
        header: "Tags",
        Cell: ({ cell, row }) => {
          const fileName = row.original.name;
          const selectedFileResults = mergedResults.find(item => item.name === fileName);
          const tags = selectedFileResults ? selectedFileResults.tags : [];

          return tags.length > 0 ? <TagsCell tags={tags} /> : null;
        }
      });
    }

    // Add columns for search terms
    if (showSearchTerms) {
      searchHeaders.forEach(searchTermName => {
        dynamicColumns.push({
          accessorKey: searchTermName,
          header: searchTermName,
          muiTableHeadCellProps: {
            sx : {
              color: '#544cfd'
            },
          },
          size: 400,
          muiEditTextFieldProps: ({ cell, row }) => ({
            multiline: 'true',
            placeholder: 'Enter text',
            onBlur: (e) => {
              const updatedValue = e.target.value;

              const updatedRow = newEdits && newEdits[row.id]
                ? {
                  ...newEdits[row.id],
                  [searchTermName]: updatedValue  // Set the value for the specific searchTermName key
                }
                : {
                  ...row.original,
                  [searchTermName]: updatedValue  // Set the value for the specific searchTermName key
                };

              //console.log('Updated row is', updatedRow);

              // Set the state with the new edited row data
              setIsUnsavedEdits(true);
              setNewEdits((prevEditedRow) => ({
                ...prevEditedRow,
                [row.id]: updatedRow,
              }));
            }
          }),

          Cell: ({ cell, row }) => {
            const fileName = row.original.name;
            const selectedFileResults = mergedResults.find(item => item.name === fileName);
            const searchResult = selectedFileResults ? selectedFileResults[searchTermName] : [];
            const selectedCodeResults = baseResults.find(item => item.name === fileName)[searchTermName];
            const isEdited = cell?.getValue() !== selectedCodeResults;

            return (
              <div>
                <div>
                  {searchResult &&
                    <div>
                      <span>
                        {cell.getValue()}
                      </span>
                    </div>
                  }
                  <div>
                    {isEdited ? (
                      // Render "User edited" message and apply styles
                      <span style={{ fontStyle: 'italic', color: 'blue' }}>
                        edited
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

              </div >
            );
          },
        });
      });
    }

    return dynamicColumns;

  }, [sessionStandard, classifierHeaders, searchHeaders, mergedResults, showTags, showSearchTerms]);

  const table = useMaterialReactTable({
    columns,
    data: mergedResults,
    enableRowSelection: true,
    enableColumnPinning: true,
    enableColumnResizing: true,
    initialState: {
      columnPinning: { left: ['newFileName'] },
    },
    columnFilterDisplayMode: 'popover',
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    renderTopToolbarCustomActions: ({ table }) => (
      <div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between', // Align components in one row with space between
            alignItems: 'center', // Align items vertically
            padding: '8px',
            flexWrap: 'wrap',
          }}
        >
          {/* Buttons */}
          <div style={{ display: 'flex', gap: '16px' }}>
            <Tooltip title="Save edits">
              <span>
                <IconButton
                  onClick={handleSaveEdits}
                  disabled={!isUnsavedEdits}
                  className='btn btn-secondary'
                  sx={{ minWidth: 'auto' }}
                >
                  <SaveIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Clear unsaved edits">
              <span>
                <IconButton
                  onClick={handleResetEdits}
                  disabled={!isUnsavedEdits}
                  className='btn btn-secondary'
                  sx={{ minWidth: 'auto' }}
                >
                  <ReplayIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Delete all edits">
              <span>
                <IconButton
                  onClick={handleDeleteAllEdits}
                  disabled={Object.keys(savedEdits).length === 0}
                  className='btn btn-secondary'
                  sx={{ minWidth: 'auto' }}
                >
                  <ClearIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Generate File Codes">
              <span>
                <Button
                  onClick={handleOpenRenameSettingsModal}
                  className='btn btn-secondary'
                  sx={{ minWidth: 'auto' }}
                >
                  <DriveFileRenameOutlineIcon />
                </Button>
              </span>
            </Tooltip>
            <Tooltip title="Export Coded Files">
              <span>
                <Button
                  onClick={handleDownloadFiles}
                  className='btn btn-secondary'
                  sx={{ minWidth: 'auto' }}
                  // Disable if cell edits have not been saved and new file names have not been generated
                  disabled={(isUnsavedEdits || Object.keys(modifiedFileNames).length === 0) ? true : false}
                >
                  <FolderZipIcon />
                </Button>
              </span>
            </Tooltip>
            <Tooltip title={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected() ? 'Export all' : 'Export selected'}>
              <span>
                <Button
                  onClick={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected() ? handleExportData : () => handleExportRows(table.getSelectedRowModel().rows)}
                  className='btn btn-secondary'
                  sx={{ minWidth: 'auto' }}
                >
                  <FileDownloadIcon />
                </Button>
              </span>
            </Tooltip>
          </div>

          {/* Tags and Search Terms */}
          <div style={{ display: 'flex', gap: '16px', marginLeft: '32px' }}>
            {/* Toggle switch for Tags */}
            {tags && tags.length > 0 && (
              <FormControlLabel
                control={<Switch checked={showTags} onChange={handleToggleTags} color="secondary" />}
                label="Tags"
                labelPlacement="end"
              />
            )}
            {/* Toggle switch for Search Terms */}
            {searchHeaders && searchHeaders.length > 0 && (
              <FormControlLabel
                control={<Switch checked={showSearchTerms} onChange={handleToggleSearchTerms} color="secondary" />}
                label="Search Terms"
                labelPlacement="end"
              />
            )}
          </div>
        </Box>
      </div>
    ),

    // Custom rendering for expand button
    enableExpandAll: true, // I don't think this is particularly better functionality but it saves space and I can't edit the column header
    muiDetailPanelProps: () => ({
      sx: (theme) => ({
        backgroundColor:
          theme.palette.mode === 'light'
            ? 'rgba(255,210,244,0.1)'
            : 'rgba(0,0,0,0.1)',
      }),
    }),
    muiExpandButtonProps: ({ row, table }) => ({
      onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
      sx: {
        transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
        transition: 'transform 0.2s',
      },
    }),

    //conditionally render detail panel
    renderDetailPanel: ({ row }) => (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr', // Single column layout
          width: '60%',
          maxWidth: '100%', // Maximum width set to 100% of the container width
          overflowX: 'hidden', // Enable horizontal scrolling if necessary
          justifyContent: 'start', // Justify content to the start (left)
        }}
      >
        {row.original.description && row.getIsExpanded() && (
          <>
            {/* Display description in the first column */}
            <Typography sx={{ mb: 2, pl: 2, overflowX: 'hidden' }}>
              <b>Document Summary: </b>
              {row.original.description}
            </Typography>
          </>
        )}
      </Box>
    ),

    // Additional options for editing
    editDisplayMode: 'cell',
    enableCellActions: true,
    enableClickToCopy: 'context-menu',
    editVariant: 'select',
    enableEditing: true,
    muiTableBodyCellProps: ({ cell, column, table }) => ({
      onClick: () => {
        table.setEditingCell(cell); //set editing cell
        //optionally, focus the text field
        queueMicrotask(() => {
          const textField = table.refs.editInputRefs.current[column.id];
          if (textField) {
            textField.focus();
            textField.select?.();
          }
        });
      },
    }),
    getRowId: (row) => row.id,
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {showRenameSettings && (
        <RenameFiles onClose={handleCloseRenameSettingsModal} onDownload={handleRenameFiles} />
      )}
    </>

  );
};

export default ResultsTable;
