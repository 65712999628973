import React, { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
import HeaderBar from './shared/HeaderBar';
import BackButton from './shared/BackButton';
import { Box, Backdrop, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import { useUserManagement, useCheckPermissions } from '../contexts/UserManagementContexts';
import ResultsTable from './subcomponents/ResultsTable';
import ResultsStatusBar from './subcomponents/ResultsStatusBar';
import NotFoundContent from './shared/NotFoundContent'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const Results = () => {
    const { currentOrg, workspace, sessionId, userId } = useParams();
    const navigate = useNavigate();
    const { user, isLoading, isLoadingOrgs } = useUserManagement();

    const checkPermissions = useCheckPermissions();
    const [hasEditPermission, setHasEditPermission] = useState(false); // If user is able to submit sessions for analysis
    const [notFound, setNotFound] = useState(false)

    const [rawResultsData, setRawResultsData] = useState();
    const [sessionStandardData, setSessionStandardData] = useState();
    const [userEditsData, setUserEditsData] = useState();
    const [showSpinner, setShowSpinner] = useState(true)
    const [loadingState, setLoadingState] = useState('Checking permissions...')
    const [loadError, setLoadError] = useState(null)
    const [errorCode, setErrorCode] = useState(null)
    const [fileCount, setFileCount] = useState({});
    const [modifiedFileNames, setModifiedFileNames] = useState({});

    const [classifierHeaders, setClassifierHeaders] = useState();
    const [searchHeaders, setSearchHeaders] = useState();
    const [tags, setTags] = useState([]);

    const [tableData, setTableData] = useState([]);

    const [savedEdits, setSavedEdits] = useState([]);

    let config = {
        headers: {
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
        }
    }

    // Effect to load the session standard and raw results into state
    useEffect(() => {

        const fetchResults = async () => {
            handleSetLoadingState('Fetching session details...', true);

            let sessionStandard, rawResults;

            try {
                // Determine call method based on URL route. 
                if (userId) {
                    sessionStandard = await axios.get(
                        `${API_BASE_URL}/api/ConfigureResultsTableTrigger?sessionGuid=${sessionId}&container=${userId}`,
                        config
                    );
                    rawResults = await axios.get(
                        `${API_BASE_URL}/api/GetResultsTrigger?sessionGuid=${sessionId}&container=${userId}`,
                        config
                    );
                } else {
                    // Include workspace in calls, and replace container with organisation
                    sessionStandard = await axios.get(
                        `${API_BASE_URL}/api/ConfigureResultsTableTrigger?sessionGuid=${sessionId}&container=${currentOrg}&workspace=${workspace}`,
                        config
                    );
                    rawResults = await axios.get(
                        `${API_BASE_URL}/api/GetResultsTrigger?sessionGuid=${sessionId}&container=${currentOrg}&workspace=${workspace}`,
                        config
                    );
                }

                // Only proceed if the calls succeed
                handleSetLoadingState('Loading results...', true);
                setSessionStandardData(sessionStandard.data);
                setRawResultsData(rawResults.data);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setNotFound(true); // Set notFound to true if a 404 error occurs
                } else {
                    console.error("An error occurred while fetching results:", error);
                }
            } finally {
                handleSetLoadingState('', false); // Stop loading state after attempt
            }
        };

        // Trigger fetchResults based on permissions
        if (!isLoading && !isLoadingOrgs) {
            const isAuthorised = checkPermissions(['read:sessions'], currentOrg);
            console.log('Authorisation check is:', isAuthorised);
            const editPermission = checkPermissions(['update:sessions'], currentOrg);
            setHasEditPermission(editPermission);
            if (isAuthorised) {
                fetchResults(); // Call the function to load data
            } else {
                navigate('/access-denied');
            }
        }


    }, [user, isLoading, isLoadingOrgs])


    // Effect to initialise the table headers.
    useEffect(() => {

        if (sessionStandardData) {

            // Take session standard json and extract classifiers and search terms
            const classifiers = sessionStandardData?.classifiers || [];
            const searchTerms = sessionStandardData?.enrichers?.searchTerms || [];
            const tags = sessionStandardData?.enrichers?.tags || [];

            // Get classifier names and search term titles for column headers
            const classifierNames = classifiers.map((classifier) => classifier.name);
            const searchTermTitles = searchTerms.map((searchTerm) => searchTerm.searchTerm.split(';')[0])

            //console.log(searchTermTitles)

            setClassifierHeaders(classifierNames)
            setSearchHeaders(searchTermTitles)
            setTags(tags)
        }

    }, [sessionStandardData])


    // Effect to process results into required format for binding to table.
    useEffect(() => {

        // Only execute if session standard and raw results have loaded.
        if (sessionStandardData && rawResultsData) {

            //console.log(sessionStandardData, rawResultsData)

            // Take session standard json and extract classifiers and search terms
            const classifiers = sessionStandardData?.classifiers || [];

            // Get classifier names for table headers - these are values that are not needed to be rendered in row / in specific cells
            const classifierIds = classifiers.map((classifier) => classifier.id);
            const classifierNames = classifiers.map((classifier) => classifier.name);

            let editsData;
            if (rawResultsData.edits && typeof rawResultsData.edits === 'object' && Object.keys(rawResultsData.edits).length > 0) {
                // If edits is a non-empty object, retain the original structure
                editsData = { ...rawResultsData.edits };
                //console.log('Extracted edits data', editsData);
            } else {
                editsData = {}; // Handle case where edits is empty or not an object by setting it to an empty object
            }

            const counts = {
                pass: 0,
                warning: 0,
                error: 0,
                fail: 0,
                total: rawResultsData.status.session.file_count
            };

            // Loop through the files and count statuses
            for (const status of Object.values(rawResultsData.status.files)) {
                if (status === 'Pass') {
                    counts.pass++;
                } else if (status === 'Warning') {
                    counts.warning++;
                } else if (status === 'Error') {
                    counts.error++;
                } else if (status === 'Fail') {
                    counts.fail++;
                }
            }

            // Parse results data into necessary structure for table. 
            const fileResults = rawResultsData.results
            const resultKeys = Object.keys(fileResults);
            const processedResultsData = resultKeys.map((resultKey) => {
                const result = fileResults[resultKey];
                // Extract result content for processing
                const fileName = modifiedFileNames[resultKey] || resultKey.split('/').pop().split('_results.json')[0].split('.json')[0];
                const description = result.description;
                const classifiers = result.classifiers || [];
                const extractions = result.extractions || {};
                const tags = extractions.tags || [];
                // Check if extractions.searchTerms exists before accessing its properties
                const searchTerms = extractions && extractions.searchTerms ? extractions.searchTerms : [];

                // Initialize searchTermObject outside of the loop
                const searchTermObject = {};

                // Helper function to flatten nested structures recursively
                const flattenNestedValue = (value) => {
                    if (Array.isArray(value)) {
                        // Flatten arrays
                        return value.map((item) => flattenNestedValue(item)).join('; ');
                    } else if (typeof value === 'object' && value !== null) {
                        // Flatten nested objects
                        const flattenedObject = [];
                        for (const [key, val] of Object.entries(value)) {
                            flattenedObject.push(`${key}: ${flattenNestedValue(val)}`);
                        }
                        return flattenedObject.join('; ');
                    } else {
                        // Direct values
                        return value;
                    }
                };

                // Iterate over each searchTerm object
                searchTerms.forEach((searchTermObj) => {
                    let title, searchTerm, value;

                    // Check if the searchTermObj has the "searchTerm" property directly
                    if ("searchTerm" in searchTermObj && "value" in searchTermObj) {
                        title = searchTermObj.searchTerm.split(';')[0]
                        searchTerm = searchTermObj.searchTerm.split(';').pop().trim();
                        value = searchTermObj.value;
                    } else {
                        // If the searchTermObj does not have "searchTerm" property directly, assume it's the searchTerm and value directly
                        const entries = Object.entries(searchTermObj);
                        if (entries.length === 1) {
                            [searchTerm, value] = entries[0]; // Extract searchTerm and value
                        } else {
                            console.error("Invalid search term object:", searchTermObj);
                            return; // Skip invalid search term object
                        }
                    }

                    // Process and store searchTerm and value
                    const flattenedValue = flattenNestedValue(value);

                    // Check if the documentName already exists as a key in searchTermObject
                    if (!searchTermObject[fileName]) {
                        // If not, initialize it as an empty object
                        searchTermObject[fileName] = {};
                    }

                    // Assign the flattened value to the corresponding documentName and searchTerm
                    searchTermObject[fileName][title] = flattenedValue;

                    //console.log('searchTermObject:', searchTermObject); // Log searchTermObject here
                });

                // Map over each classifiers using the code part ID (e.g. IS01) to extract nested results 'data' for each code ('data' is a specific object in the results.json)
                // IMPORTANT the extracted data (code, certainty etc.) are returned as an array, which is nested within the processedResultsData object
                // this is because the table component cannot handle objects, only arrays
                // in order to display multiple data points in a single cell it is necessary to pass the cell an array, and then use positional indexing to render the relevant data

                const classifierResults = classifierIds.map((id) => {
                    const relevantClassifier = classifiers.find(classifier => classifier.id === id);
                    const code = relevantClassifier?.data[0]?.code || '';
                    const certainty = relevantClassifier?.data[0]?.certainty || '';
                    const explanation = relevantClassifier?.data[0]?.explanation || '';

                    // Determine the emoji based on certainty
                    // The emoji is passed along with the certainty string, to provide a more visual way of the user identifying erroneous results
                    let emoji = '';
                    switch (certainty) {
                        case 'low':
                            emoji = ' ⚠️';
                            break;
                        case 'medium':
                            emoji = <span style={{ fontSize: "18px", color: '#FFAA33' }}><b>&nbsp; ✓ </b></span>
                            break;
                        case 'high':
                            emoji = <span style={{ fontSize: "18px", color: '#03C03C' }}><b>&nbsp; ✓</b></span>; // High certainty, tick emoji
                            break;
                        case 'edited':
                            emoji = ' user edited'
                        default:
                            emoji = ''; // Default case, no emoji
                            break;
                    }
                    // classifier data returned as an array
                    return {
                        code: code,
                        certainty: certainty,
                        explanation: explanation,
                        emoji: emoji
                    };
                });

                // Find corresponding searchTermObject for the current document
                const correspondingSearchTermData = searchTermObject[fileName];

                // Merge searchTermObject with other data
                const mergedData = {
                    name: fileName,
                    description: description,
                    ...Object.fromEntries(
                        classifierNames.map((id, index) => [
                            id,
                            classifierResults[index] || ''
                        ])
                    ),
                    tags: tags.join(', '),
                    ...correspondingSearchTermData // Merge searchTermObject data unconditionally
                };

                return mergedData;
            });

            //console.log(processedResultsData)

            setTableData(processedResultsData)

            // Update state with counts
            setFileCount(counts)

            if (editsData) {
                setSavedEdits(editsData)
            }

            handleSetLoadingState('', false)
        }

    }, [sessionStandardData, rawResultsData])

    const handleSetLoadingState = (message, state) => {
        setShowSpinner(state)
        setLoadingState(message)
    }


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'ghostwhite', position: 'relative' }}>
            <HeaderBar
                className="secondary-background"
                homeLink="/"
                homeText="hoppa"
                userInfo={user}
                switchingAllowed={false}
                wide={true}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, margin: '0 2rem' }}>
                <div style={{ height: '35px' }}>
                    <BackButton destinationText='workspace' destination={`/${currentOrg}/${workspace}/workspace`} />
                </div>
                {notFound ?
                    <>
                    <NotFoundContent/>
                    </>
                    :
                    <>
                        {fileCount.total > 0 ? <ResultsStatusBar fileCount={fileCount} /> : <></>}
                        <div style={{ marginTop: '-30px' }}>
                            <ResultsTable
                                sessionStandard={sessionStandardData}
                                classifierHeaders={classifierHeaders}
                                searchHeaders={searchHeaders}
                                tags={tags}
                                baseResults={tableData}
                                savedEdits={savedEdits}
                                setSavedEdits={setSavedEdits}
                                handleSetLoadingState={handleSetLoadingState}
                            />
                        </div>
                    </>
                }

            </Box>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    display: 'flex',
                    flexDirection: 'column', // Align items vertically
                    justifyContent: 'center', // Center vertically
                    alignItems: 'center', // Center horizontally
                }}
                open={showSpinner}
            >
                <CircularProgress color="inherit" />
                <p className='p-2'>{loadingState}</p>
            </Backdrop>


        </Box>
    )

}

export default Results;
