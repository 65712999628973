import React, { useState, useEffect } from 'react';
import { Modal, Button, Card, Col, Row } from 'react-bootstrap';

const TopUpModal = ({ show, handleClose, userPermissions }) => {
  const [selectedOption, setSelectedOption] = useState(null); 

  // Define card options
  const cardOptions = [
    { id: 1, label: 'Pay-As-You', permission: 'PAG' },
    { id: 2, label: 'Subscription', permission: 'subscription' },
    { id: 3, label: 'Enterprise', permission: 'admin' },
  ];

  useEffect(() => {
    // Check permissions and set the default selected option based on user permissions
    if (!userPermissions || !userPermissions.permissions) {
      // No permissions found, set to null (all cards disabled)
      setSelectedOption(null);
      return;
    }

    const { permissions } = userPermissions;
    if (permissions.subscription === 'admin') {
      setSelectedOption(3); // Select Enterprise for admin
    } else if (permissions.subscription === 'PAG') {
      setSelectedOption(1); // Select Pay-As-You for PAG
    } else if (permissions.subscription) {
      setSelectedOption(2); // Select Subscription if it's available
    } else {
      setSelectedOption(null); // No valid permission, all cards disabled
    }
  }, [userPermissions]);

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Select Payment Plan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          {cardOptions.map((option) => (
            <Col key={option.id} sm={4} className="d-flex">
              <Card
                className={`card-template ${selectedOption === option.id ? 'card-template-custom-selected' : ''}`}
                style={{ 
                  cursor: 'not-allowed', // Indicate non-selectable cards
                  flex: 1, 
                  opacity: userPermissions && userPermissions.permissions ? 1 : 0.5 // Grey out if no permissions
                }} 
              >
                <Card.Body className="d-flex flex-column">
                  <Card.Title>{option.label}</Card.Title>
                  {userPermissions && userPermissions.permissions ? (
                    <Card.Text style={{ color: 'gray' }}>Not selectable</Card.Text>
                  ) : (
                    <Card.Text style={{ color: 'gray' }}>Disabled due to permissions</Card.Text>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <div className="mt-3 text-center">
          <p>Your account needs to be verified before you can be added to a consumption plan.</p>
          <p>Please contact <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a> to get started.</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TopUpModal;
