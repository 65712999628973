import React, { useState, useEffect, useCallback } from "react";
import { Typography, Box, Button, Stack, Chip, Avatar, Divider, Tooltip } from '@mui/material';
import EditableTitle from "../../shared/EditableTitle";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCopy, faTrashCan, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons'
import DynamicTable from "../../shared/DynamicTable";
import DynamicFileTable from "../../shared/DynamicFileTable";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

const Overview = ({ sessionId, sessionName, sessionStatus, handleSetSessionName, createdAt, createdBy, saveSession, userName, triggerAlert, allFiles, isLoadingSession, handleRunSession, hasQueuePermission }) => {

    const handleSaveSession = () => {
        saveSession()
        triggerAlert('Save successful', 'success')
    }

    const tableHeaders = [
        { label: 'Source', key: 'source', width: '60px' },
        { label: 'File Name', key: 'name', width: '45%' },
        { label: 'Type', key: 'type', width: '60px' },
        { label: 'Last Modified', key: 'lastModifiedDate' },
        { label: 'Size', key: 'size' },
    ];

    return (
        <Box sx={{ margin: '1.5rem', flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {/* Title and Buttons Area */}
            <div style={{ width: '100%' }}>
                <Box
                    sx={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        height: '50px'
                    }}
                >
                    {/* Title */}
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#5C5DD8', flexGrow: 1 }}>
                        Overview
                    </Typography>
                    {/* Buttons Group */}
                    <Stack direction="row" spacing={1} sx={{
                        alignItems: "center",
                    }}>
                        <Button variant="text" sx={{ color: '#999999', textTransform: 'none' }} disabled>
                            <FontAwesomeIcon size='sm' icon={faBan} className='me-1' />
                            Cancel
                        </Button>
                        <Button variant="text" sx={{ color: '#999999', textTransform: 'none' }} disabled>
                            <FontAwesomeIcon size='sm' icon={faCopy} className='me-1' />
                            Duplicate
                        </Button>
                        <Button variant="text" sx={{ color: '#999999', textTransform: 'none' }} onClick={handleSaveSession}>
                            <FontAwesomeIcon size='sm' icon={faTrashCan} className='me-1' />
                            Save draft
                        </Button>
                    </Stack>
                </Box>
            </div>
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={{ xs: 3, lg: 1 }}
                style={{ maxWidth: '100%', width: '100%' }}
            >
                <div style={{ minWidth: '40%' }}>
                    <Stack direction="column" spacing={2}>
                        {/* Title Field */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', color: '#4A4A4A', width: '150px' }}>
                                Title
                            </Typography>
                            <EditableTitle defaultTitle={sessionName} handleSetTitle={handleSetSessionName} />
                        </Box>
                        {/* Id Field */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', color: '#4A4A4A', width: '150px' }}>
                                ID
                            </Typography>
                            <Typography sx={{ color: '#4A4A4A' }}>
                                {sessionId}
                            </Typography>
                        </Box>
                        {/* Status Field */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', color: '#4A4A4A', width: '150px' }}>
                                Status
                            </Typography>
                            <Chip
                                label={sessionStatus}
                                variant="outlined"
                                sx={{ borderColor: '#5C5DD8', color: '#5C5DD8', fontWeight: 'bold' }}
                            />
                        </Box>
                        {/* Created by Field */}
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', color: '#4A4A4A', width: '150px' }}>
                                Created by
                            </Typography>
                            <Stack direction="row" spacing={1} alignItems="center">
                                    <Avatar
                                        alt={createdBy?.toUpperCase()}
                                        src="/static/images/avatar/1.jpg"
                                        sx={{ bgcolor: 'hotpink', width: 30, height: 30 }}
                                    />
                                <Typography sx={{ color: '#4A4A4A' }}>
                                    {createdBy}
                                </Typography>
                            </Stack>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Typography sx={{ fontWeight: 'bold', color: '#4A4A4A', width: '150px' }}>
                                Created at
                            </Typography>
                            <Typography sx={{ color: '#4A4A4A' }}>
                                {createdAt}
                            </Typography>
                        </Box>
                    </Stack>
                </div>
                <Divider flexItem style={{ color: 'gray', borderWidth: '1px' }} />
                <div style={{ maxHeight: 'calc(100vh - 260px)', overflowY: 'auto', scrollbarGutter: 'stable' }}>

                    <Typography sx={{ fontWeight: 'bold', color: '#4A4A4A', width: '150px', verticalAlign: 'middle', margin: '0 0.5rem' }}>
                        <ShoppingBasketIcon style={{ marginRight: '0.5rem' }} />
                        Your basket
                    </Typography>
                    <DynamicFileTable
                        tableData={allFiles}
                        tableHeaders={tableHeaders}
                        isLoading={isLoadingSession}
                    />
                </div>
            </Stack>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 'calc(-1.5rem)',
                    left: 'calc(-1.5rem)',
                    // right: 'calc(+1.5rem)',
                    height: '64px', // Adjust the height as needed,
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)', // Adds a subtle shadow to the top of the bar
                    padding: '0 1.5rem',
                    boxSizing: 'border-box',
                    width: 'calc(100% + 3rem)'
                }}
            >
                {/* Files Added Message */}
                <div>
                    <ShoppingBasketIcon style={{ marginRight: '0.5rem' }} />
                    <b>{allFiles.length} files in basket</b>
                </div>
                <button className='btn btn-primary mt-2' disabled={!hasQueuePermission || allFiles?.length === 0} onClick={hasQueuePermission ? () => handleRunSession() : null}>
                    <FontAwesomeIcon size='sm' icon={faWandMagicSparkles} className='me-1' />
                    Run
                </button>
            </Box>

        </Box>
    )
}

export default Overview;