import React, { useState, useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap'
import HeaderBar from './shared/HeaderBar';
import FooterBar from './shared/FooterBar';
import ReactGA from 'react-ga';

const TermsAndConditions = () => {

  // Google analytics ping on component mount
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const htmlContent = `
  <article>
    <style>
        [data-custom-class='body'], [data-custom-class='body'] * {
            background: transparent !important;
        }
        [data-custom-class='title'], [data-custom-class='title'] * {
            font - family: Arial !important;
        font-size: 26px !important;
        color: #000000 !important;
        }
        [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
            font - family: Arial !important;
        color: #595959 !important;
        font-size: 14px !important;
        }
        [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
            font - family: Arial !important;
        font-size: 19px !important;
        color: #000000 !important;
        }
        [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
            font - family: Arial !important;
        font-size: 17px !important;
        color: #000000 !important;
        }
        [data-custom-class='body_text'], [data-custom-class='body_text'] * {
            color: #595959 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        }
        [data-custom-class='link'], [data-custom-class='link'] * {
            color: #3030F1 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        word-break: break-word !important;
        }
    </style>
    <div data-custom-class="body">
        <div>
            <strong><span style="font-size: 26px;">
                <span data-custom-class="title"><bdt class="block-component"></bdt>
                    <bdt class="question">TERMS AND CONDITIONS</bdt>
                </span>
            </span>
            </strong>
            <div><br></div>
            <strong>
                <span style="font-size: 15px;">
                    <span data-custom-class="subtitle">Last updated <bdt class="question">October 02, 2024</bdt>
                    </span>
                </span>
            </strong>
        </div>
        <div><br></div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the <a href="https://hoppa.ai">hoppa.ai</a> website (“Website” or “Service”) and any of its related products and services (collectively, “Services”). This Agreement is legally binding between you (“User”, “you” or “your”) and Hoppa Technologies Ltd (“hoppa”, “we”, “us” or “our”). If you are entering into this Agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Agreement, you must not accept this Agreement and may not access and use the Website and Services. By accessing and using the Website and Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement. You acknowledge that this Agreement is a contract between you and Hoppa Technologies Ltd, even though it is electronic and is not physically signed by you, and it governs your use of the Website and Services.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0);">
                    <span style="color: rgb(0, 0, 0); font-size: 15px;">
                        <span style="font-size: 15px; color: rgb(0, 0, 0);">
                            <span style="font-size: 15px; color: rgb(0, 0, 0)">
                                <span id="control" style="color: rgb(0, 0, 0);">
                                    <strong>
                                        <span data-custom-class="heading_1">
                                            ACCOUNTS AND MEMBERSHIP
                                        </span>
                                    </strong>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        You must be at least 18 years of age to use the Website and Services. By using the Website and Services and by agreeing to this Agreement you warrant and represent that you are at least 18 years of age. If you create an account on the Website, you are responsible for maintaining the security of your account and you are fully responsible for all activities that occur under the account and any other actions taken in connection with it. We may, but have no obligation to, monitor and review new accounts before you may sign in and start using the Services. Providing false contact information of any kind may result in the termination of your account. You must immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions by you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your email address and Internet protocol address to prevent further registration.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0);">
                    <span style="color: rgb(0, 0, 0); font-size: 15px;">
                        <span style="font-size: 15px; color: rgb(0, 0, 0);">
                            <span style="font-size: 15px; color: rgb(0, 0, 0)">
                                <span id="control" style="color: rgb(0, 0, 0);">
                                    <strong>
                                        <span data-custom-class="heading_1">
                                            BILLING AND PAYMENTS
                                        </span>
                                    </strong>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        You shall pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. Where Services are offered on a free trial basis, payment may be required after the free trial period ends, and not when you enter your billing details (which may be required prior to the commencement of the free trial period). If auto-renewal is enabled for the Services you have subscribed for, you will be charged automatically in accordance with the term you selected. Sensitive and private data exchange happens over a SSL secured communication channel and is encrypted and protected with digital signatures, and the Website and Services are also in compliance with PCI vulnerability standards in order to create as secure of an environment as possible for Users. Scans for malware are performed on a regular basis for additional security and protection. If, in our judgment, your purchase constitutes a high-risk transaction, we will require you to provide us with a copy of your valid government-issued photo identification, and possibly a copy of a recent bank statement for the credit or debit card used for the purchase. We reserve the right to change products and product pricing at any time. We also reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0);">
                    <span style="color: rgb(0, 0, 0); font-size: 15px;">
                        <span style="font-size: 15px; color: rgb(0, 0, 0);">
                            <span style="font-size: 15px; color: rgb(0, 0, 0)">
                                <span id="control" style="color: rgb(0, 0, 0);">
                                    <strong>
                                        <span data-custom-class="heading_1">
                                            ACCURACY OF INFORMATION
                                        </span>
                                    </strong>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        Occasionally there may be information on the Website that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, availability, promotions and offers. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information on the Website or Services is inaccurate at any time without prior notice (including after you have submitted your order). We undertake no obligation to update, amend or clarify information on the Website including, without limitation, pricing information, except as required by law. No specified update or refresh date applied on the Website should be taken to indicate that all information on the Website or Services has been modified or updated.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        The Services are provided on an "as is" basis and no representation is made that the Services will be uninterrupted or error-free, or will meet your requirements. You assume sole responsibility for results obtained from the use of the Services, and for conclusions drawn from such use. Hoppa shall have no liability for any damage caused by errors or omissions in any information, instructions or scripts provided in connection with the Services.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0);">
                    <span style="color: rgb(0, 0, 0); font-size: 15px;">
                        <span style="font-size: 15px; color: rgb(0, 0, 0);">
                            <span style="font-size: 15px; color: rgb(0, 0, 0)">
                                <span id="control" style="color: rgb(0, 0, 0);">
                                    <strong>
                                        <span data-custom-class="heading_1">
                                            LINKS TO OTHER RESOURCES
                                        </span>
                                    </strong>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        Although the Website and Services may link to other resources (such as websites, mobile applications, etc.), we are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or affiliation with any linked resource, unless specifically stated herein. We are not responsible for examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their resources. We do not assume any responsibility or liability for the actions, products, services, and content of any other third parties. You should carefully review the legal statements and other conditions of use of any resource which you access through a link on the Website. Your linking to any other off-site resources is at your own risk.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0);">
                    <span style="color: rgb(0, 0, 0); font-size: 15px;">
                        <span style="font-size: 15px; color: rgb(0, 0, 0);">
                            <span style="font-size: 15px; color: rgb(0, 0, 0)">
                                <span id="control" style="color: rgb(0, 0, 0);">
                                    <strong>
                                        <span data-custom-class="heading_1">
                                            CHANGES AND AMENDMENTS
                                        </span>
                                    </strong>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        We reserve the right to modify this Agreement or its terms related to the Website and Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        An updated version of this Agreement will be effective immediately upon the posting of the revised Agreement unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Agreement (or such other act specified at that time) will constitute your consent to those changes.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0);">
                    <span style="color: rgb(0, 0, 0); font-size: 15px;">
                        <span style="font-size: 15px; color: rgb(0, 0, 0);">
                            <span style="font-size: 15px; color: rgb(0, 0, 0)">
                                <span id="control" style="color: rgb(0, 0, 0);">
                                    <strong>
                                        <span data-custom-class="heading_1">
                                            ACCEPTANCE OF THESE TERMS
                                        </span>
                                    </strong>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        You acknowledge that you have read this Agreement and agree to all its terms and conditions. By accessing and using the Website and Services you agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to access or use the Website and Services.
                    </span>
                </span>
            </span>
        <div><br></div>
        <div id="scope" style="line-height: 3;">
            <div>
                <span style="color: rgb(0, 0, 0);">
                    <span style="color: rgb(0, 0, 0); font-size: 15px;">
                        <span style="font-size: 15px; color: rgb(0, 0, 0);">
                            <span style="font-size: 15px; color: rgb(0, 0, 0)">
                                <span id="control" style="color: rgb(0, 0, 0);">
                                    <strong>
                                        <span data-custom-class="heading_1">
                                            CONTACTING US
                                        </span>
                                    </strong>
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>
        </div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        If you have any questions, concerns, or complaints regarding this Agreement, we encourage you to contact us using the details below:
                    </span>
                </span>
            </span>
        <div><br></div>
        <div style="line-height: 1.5;">
            <span style="color: rgb(127, 127, 127);">
                <span style="color: rgb(89, 89, 89); font-size: 15px;">
                    <span data-custom-class="body_text">
                        <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a>
                    </span>
                </span>
            </span>
        <div><br></div>





                        `




  return (
    <div >
      <HeaderBar homeLink="/" homeText='hoppa' isBasic />
      <div className="full-width-primary-section">
        <Container>
          <Container className='mt-3 p-5 rounded-box'>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </Container>
        </Container>
        <footer>
          <FooterBar />
        </footer>
      </div>
    </div>
  )
}

export default TermsAndConditions;
