    // Function to create a state parameter with user data and session ID
    export function generateState(organisation, workspace, sessionId, userId) {
        const stateObject = {
            organisation: organisation,
            workspace: workspace,
            sessionId: sessionId,
            userId: userId,
            timestamp: new Date().getTime() // Optional: Include a timestamp for validation later
        };

        // Convert the state object to a string and base64-encode it (or use a secure method)
        const stateString = btoa(JSON.stringify(stateObject));

        return stateString;
    }