import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Card, Nav, Tab } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCirclePlus, faCircleDown, faPassport, faEllipsis } from '@fortawesome/free-solid-svg-icons';

const StandardCard = ({ id, title, description, badge, handleSetCheck, check, variant, disabled }) => {
    const [icon, setIcon] = useState(faPassport);

    useEffect(() => {
        if (variant === 'custom') {
            setIcon(faCirclePlus)
        }
      },[])
    
    return (
        <Card 
        onClick={!disabled ? () => handleSetCheck(id) : null}
        className={`card-template ${check === id ? "card-template-custom-selected" : "card-template-default"} ${disabled ? 'card-template-disabled' : ''}`}>
            <Card.Body className="text-start ">
                <span className="fa-stack mb-2">
                    <FontAwesomeIcon icon={faCircle} className='icon-background-style fa-stack-2x' transform="scale-2" />
                    <FontAwesomeIcon icon={icon} className='icon-foreground-style fa-stack-1x' />
                </span>
                <Card.Title>
                    {title}&nbsp;
                    <p className='mt-2'><Badge bg="information" className=''>{badge}</Badge></p>
                </Card.Title>
                <Card.Text>
                    {description} 
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default StandardCard;