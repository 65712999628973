import React from 'react';
import { generateRandomString } from '../scripts/generateRandomString';
import { generateCodeChallenge } from '../scripts/generateCodeChallenge';
import { generateState } from '../scripts/generateState';

const Authorize = () => {

    const handleSharePoint = async () => {
        // Step 1: Generate a code_verifier and code_challenge
        const codeVerifier = generateRandomString(128);
        const codeChallenge = await generateCodeChallenge(codeVerifier);

        // Step 2: Store the code_verifier (e.g., in localStorage) to use later during the token exchange
        localStorage.setItem('code_verifier', codeVerifier);

        // Generate state with session ID and user ID (for example, from your app's session)
        const containerName = 'dev'; // Example session ID
        const sessionGuid = 'toms-test-sharepoint-session'; // Example user ID
        const state = generateState(containerName, sessionGuid); // Generate the state string

        const params = {
            client_id: process.env.REACT_APP_ENTRA_CLIENT_ID,
            scope: 'Sites.Read.All Files.Read.All offline_access',
            response_type: 'code',
            response_mode: 'query',
            redirect_uri: window.location.origin + '/callback/get/sharepoint',
            state: state,
            code_challenge: codeChallenge,       // PKCE code challenge
            code_challenge_method: 'S256'        // Always use 'S256' for the challenge method
        };

        const queryString = new URLSearchParams(params).toString();

        // Redirect the user's browser to the Microsoft authorization URL
        window.location.href = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?${queryString}`;
    };

    const handleAutodesk = async () => {

        // Step 1: Generate a code_verifier and code_challenge
        const codeVerifier = generateRandomString(128);
        const codeChallenge = await generateCodeChallenge(codeVerifier);

        // Step 2: Store the code_verifier (e.g., in localStorage) to use later during the token exchange
        localStorage.setItem('code_verifier', codeVerifier);
        
        // Generate state with session ID and user ID (for example, from your app's session)
        const containerName = 'dev'; // Example session ID
        const sessionGuid = 'toms-test-autodesk-session'; // Example user ID
        const state = generateState(containerName, sessionGuid); // Generate the state string

        const params = {
            client_id: process.env.REACT_APP_AUTODESK_CLIENT_ID,
            scope: 'data:read',
            response_type: 'code',
            redirect_uri: window.location.origin + '/callback/get/autodesk',
            state: state,
            code_challenge: codeChallenge,       // PKCE code challenge
            code_challenge_method: 'S256'        // Always use 'S256' for the challenge method
        };

        const queryString = new URLSearchParams(params).toString();

        // Redirect the user's browser to the Microsoft authorization URL
        window.location.href = `https://developer.api.autodesk.com/authentication/v2/authorize?${queryString}`;
    }


    return (
        <div>
            <button className='btn btn-primary mt-2' onClick={handleSharePoint}>
                Connnect to SharePoint
            </button>
            <button className='btn btn-primary mt-2' onClick={handleAutodesk}>
                Connnect to ACC
            </button>
        </div>
    )
}


export default Authorize;