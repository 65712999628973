import React, { useState, useEffect, useMemo } from 'react';
import { Spinner, Table, Alert, Row, Col, Button, Dropdown, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Chip } from '@mui/material';

const DynamicTable = ({ tableData, tableHeaders, searchPlaceholder = "Search", onRowClick, actionButton, rowActions, isLoading }) => {
  const [loadingTimeout, setLoadingTimeout] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsLoading(false);
  //     if (tableData.length === 0) {
  //       setLoadingTimeout(true);
  //     }
  //   }, 2000);

  //   return () => clearTimeout(timeout);
  // }, [tableData]);



  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortDirection = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '↑' : '↓';
    }
    return null;
  };

  const filteredAndSortedData = useMemo(() => {
    const filteredData = tableData.filter((row) =>
      Object.values(row).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    );

    if (sortConfig.key) {
      filteredData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredData;
  }, [tableData, sortConfig, searchQuery, isLoading]);

  const renderCellContent = (header, value) => {
    if (header.key === 'status') {
      return value === 'Active' ? (
        <Badge bg="success rounded-pill">Active</Badge>
      ) : value === 'Config' ? (
        <Badge bg="warning rounded-pill">Config</Badge>
      ) : value === 'Draft' ? (
        <Chip label={value} variant='outlined' sx={{ color: '#544cfd', borderColor: '#544cfd' }} />
      ) : value === 'Processing' ? (
        <Chip label={value} variant='outlined' sx={{ color: '#544cfd', borderColor: '#544cfd' }} />
      ) : value === 'Completed' ? (
        <Chip label={value} color='success' />
      ) : value === 'Error' ? (
        <Chip label={value} color='error' />
      ) : (
        value
      );
    }

    if (header.key === 'createdBy') {
      return (
        <Badge bg="secondary rounded-pill" style={{ fontWeight: 'normal', fontSize: '0.9rem' }}>
          {value}
        </Badge>
      );
    }

    if (header.key === 'name') {
      return (
        <span style={{
          fontSize: '1.1rem',
          display: 'block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap'
        }}>
          {value}
        </span>
      );
    }

    return value;
  };

  return (
    <div className="dynamic-table-section">

      <Row className="mt-2">
        <div className="d-flex w-100">
          <input
            type="text"
            className="form-control dynamic-table-search"
            placeholder={searchPlaceholder}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ height: "40px", flex: 1 }}  // Expands to fill available space
          />
          <Button
            className="btn btn-secondary ms-2"  // Spacing between input and button
            style={{ borderRadius: "0 !important", height: "40px" }}  // Ensures square corners
            onClick={actionButton.onClick}
            disabled={actionButton.disabled}
          >
            {actionButton.label}
          </Button>
        </div>
      </Row>
      {isLoading ? (
        <div className="dynamic-table-loading">
          <Spinner animation="border" />
        </div>
      ) : filteredAndSortedData.length > 0 ? (
        <div>
          <Table hover responsive className="dynamic-table">
            <thead>
              <tr>
                {tableHeaders.map((header) => (
                  <th
                    key={header.key}
                    onClick={() => requestSort(header.key)}
                    style={{ cursor: 'pointer' }}
                    className="dynamic-table-header"
                  >
                    {header.label} {getSortDirection(header.key)}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredAndSortedData.map((row, index) => (
                <tr
                  key={index}
                  onClick={() => onRowClick(row)}
                  style={{ cursor: 'pointer' }}
                  className="dynamic-table-row"
                >
                  {tableHeaders.map((header) => (
                    <td key={header.key}>
                      {renderCellContent(header, row[header.key] !== null && row[header.key] !== undefined ? row[header.key] : 'N/A')}
                    </td>
                  ))}
                  {rowActions && (
                    <td style={{ padding: 0 }}>
                      <Dropdown onClick={(e) => e.stopPropagation()}>
                        <Dropdown.Toggle as="div" className="btn-ellipsis">
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {rowActions(row).map((action, actionIndex) => (
                            <Dropdown.Item
                              key={actionIndex}
                              disabled={action.disabled}
                              onClick={(e) => {
                                e.stopPropagation();
                                action.onClick(row);
                                e.target.closest('.dropdown').querySelector('.dropdown-toggle').click();
                              }}
                            >
                              {action.label}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <Alert variant="warning">No records found.</Alert>
      )}
    </div>
  );
};

export default DynamicTable;
