export const validateState = (state) => {
    const decodedState = JSON.parse(atob(state));
    const currentTimestamp = new Date().getTime();

    // Check that the timestamp is within a valid range (e.g., 5 minutes)
    const FIVE_MINUTES = 5 * 60 * 1000;
    if (currentTimestamp - decodedState.timestamp > FIVE_MINUTES) {
        throw new Error('State parameter has expired');
    }

    return decodedState;
};