import React, { useState, useEffect } from 'react';
import { Container, Button, Row, Col, Card, Nav, Tab } from 'react-bootstrap';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCirclePlus, faCircleDown, faPassport, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import StandardCard from './StandardCard';

const DefineStandard = ({handleSetTemplate, setHasCodeParts, setUploadFromExcel }) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [check, setCheck] = useState('BS EN ISO 19650-2:2018 & Revised NA'); // Variable used to control default template. 

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  // Set the new template on component render.
  useEffect(() => {
    handleSetTemplate(check)
  },[check])

  const handleSetCheck = (template) => {
    setCheck(template); // sets the selected state for the selected card. 
  
    if (template === 'custom' || template === 'Excel Upload') {
      setHasCodeParts(false); // Set hasCodeParts to false
    } else {
      setHasCodeParts(true); // Set hasCodeParts to true for other templates
    }

    if (template === 'Excel Upload') {
      setUploadFromExcel(true)
    } else {
      setUploadFromExcel(false);
    }
  };
  

  const standardData = {
    1: {
      title: 'Start with an industry standard',
      content: (
        <div>
          <p className="text-start">Choose from a list of widely used industry standards to fast track your route to information assurance, and don't worry - in the next step you can tweak and modify the template to suit your needs.</p>
          <Row xs={1} md={3} className="g-2">
            <Col>
            <StandardCard
              id='BS EN ISO 19650-2:2018 & Revised NA'
              title='BS EN ISO 19650-2:2018 & Revised NA'
              description='Organization and digitization of information about buildings and civil engineering works - delivery phase of the assets'
              handleSetCheck={handleSetCheck}
              check={check}
              variant='standard'
            />
            </Col>
            <Col>
            <StandardCard
              id='DfE SRP1025'
              title='DfE SRP1025'
              description='UK Department for Education (DfE) standard.
              Rev C01'
              handleSetCheck={handleSetCheck}
              check={check}
              variant='standard'
            />
            </Col>
            <Col>
            <StandardCard
              id='Anglian Water File Naming Convention'
              title='Anglian Water File Naming Convention'
              description='Anglian Water Standard. Rev. C01'
              handleSetCheck={handleSetCheck}
              check={check}
              variant='standard'
            />
            </Col>
            <Col>
            <StandardCard
              id='DMRB GG 184'
              title='National Highways GG184'
              description='Specification for the use of Computer Aided
Design.'
              badge='Coming soon'
              handleSetCheck={handleSetCheck}
              check={check}
              variant='standard'
              disabled
            />
            </Col>
            <Col>
            <StandardCard
              id='PAS 1192:2013'
              title='PAS 1192: 2013'
              description='Specification for information management for the capital/delivery phase of construction projects.'
              badge='Coming soon'
              handleSetCheck={handleSetCheck}
              check={check}
              variant='standard'
              disabled
            />
            </Col>
          </Row>
    </div>
      ),
    },
    2: {
      title: "Build or upload your own",
      content: (
        <div>
          <p className="text-start">Build your standard from scratch using our web builder, or upload from Excel by downloading, populating and reuploading our Excel template in the next step.</p>
          <Row xs={1} md={3} className="g-2">
            <Col>
              <StandardCard
                id='custom'
                title='Custom standard'
                description="Start from a blank canvas. Perfect for when you want to match to a client's standard or build your own."
                handleSetCheck={handleSetCheck}
                check={check}
                variant='custom'
              />
            </Col>
            <Col>
              <StandardCard
                id='Excel Upload'
                title='Upload from Excel'
                description="If you've used Hoppa before and exported your standard to Excel, you can upload it here to use it again. "
                handleSetCheck={handleSetCheck}
                check={check}
                variant='custom'
              />
            </Col>
          </Row>
        </div>
      )
      },
    3: {
      title: "Use AI to build one for you",
      content: (
        <div >
          <p className="text-start">Coming soon...</p>
          <Row xs={1} md={3} className="g-2">
            <StandardCard
              id='AI Builder'
              title='AI Standard Builder'
              description="Tell us some info about your project and our AI will generate a standard for you."
              handleSetCheck={handleSetCheck}
              badge='Coming soon'
              check={check}
              disabled
              variant='custom'
            />
          </Row>
        </div>
        )
        },
  };
  return (
    <div>
      <Container className="rounded-box">
      <Tab.Container activeKey={selectedTab} onSelect={handleTabSelect}>
        <Row>
        <Col md={3}>
          <Nav variant="pills" className="flex-column">
            {Object.keys(standardData).map((tabKey) => (
              <Nav.Item key={tabKey} >
                <Nav.Link
                  eventKey={parseInt(tabKey)}
                  className={`custom-nav-link ${selectedTab === parseInt(tabKey) ? 'active' : ''}`}
                >
                  {standardData[tabKey].title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
          <Col md={9}>
            <Tab.Content >
              {Object.keys(standardData).map((tabKey) => (
                <Tab.Pane key={tabKey} eventKey={parseInt(tabKey)} >
                     <div className="text-center smaller-font">{standardData[tabKey].content}</div>

                </Tab.Pane>
              ))}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      </Container>
    </div>
  );
};

export default DefineStandard;
