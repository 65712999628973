import React, { useState, useEffect, useMemo } from 'react';
import { Spinner, Table, Alert, Row, Col, Button, Dropdown, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { localeOption } from 'primereact/api';
import { Chip } from '@mui/material';
import connectors from "../../connectorConfig/connectorConfig.json"


const DynamicFileTable = ({ tableData, tableHeaders, searchPlaceholder = "Search", isLoading, rowActions }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    
    const formatSize = (bytes) => {
        const k = 1024;
        let dm = 1;
        const sizes = localeOption('fileSizeTypes');

        if (bytes === 0) {
            return `0 ${sizes[0]}`;
        }

        // Display zero decimal places if file size is less than 1MB. 
        if (bytes < k * k) {
            dm = 0
        }

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

        return `${formattedSize} ${sizes[i]}`;
    };
    
    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortDirection = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'ascending' ? '↑' : '↓';
        }
        return null;
    };

    const filteredAndSortedData = useMemo(() => {
        const filteredData = tableData.filter((row) =>
            Object.values(row).some(
                (value) =>
                    value &&
                    value.toString().toLowerCase().includes(searchQuery.toLowerCase())
            )
        );

        if (sortConfig.key !== null) {
            filteredData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return filteredData;
    }, [tableData, sortConfig, searchQuery]);

    const renderCellContent = (header, row) => {

        const value = row[header.key] || 'N/A'

        if (header.key === 'name') {
            const fileName = value.split('.')[0]
            return (
                <span style={{
                    overflow: 'hidden', // Handle overflow
                    textOverflow: 'ellipsis', // Add ellipsis for overflowed text
                    whiteSpace: 'nowrap', // Prevent line breaks
                }}>
                    {fileName}
                </span>
            );
        }

        if (header.key === 'size' && value !== 'N/A') {
            // Style name with responsive font size
            return (
                <div>
                                {value ? (
                                    <Chip
                                        label={formatSize(value)}
                                        sx={{ 
                                            color: "#544cfd", 
                                            borderColor: "#544cfd",
                                            fontSize: '12px',
                                            height: '24px',
                                        }}
                                        variant="outlined"
                                    />
                                ) : null}

                            </div>
            );
        }

        if (header.key === 'lastModifiedDate') {
            const date = new Date(value);
            const formattedDate = date.toLocaleString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric'
            }).replace(/ /g, '-'); // Replace spaces with dashes
            return (
                <div style={{
                    overflow: 'hidden', // Handle overflow
                    textOverflow: 'ellipsis', // Add ellipsis for overflowed text
                    whiteSpace: 'nowrap', // Prevent line breaks
                }}>{formattedDate}</div>
            );
        }

        if (header.key === 'source') {
            const connector = connectors.find(connector => connector.key === value) || null;
        
            if (connector && connector.displayImage) { // Check if connector and displayImage exist
                return (
                    <img
                        src={`${process.env.PUBLIC_URL}${connector.displayImage}`} // Ensure correct path formatting
                        alt={connector.key} // Add an alt attribute for accessibility
                        style={{ width: 25, marginLeft: '15px' }}
                    />
                );
            }
            // Optionally, return a placeholder or null if connector or displayImage is missing
            return null;
        }
        if (header.key === 'type') {
            const fileName = row['name']
            const fileType = fileName.split('.')[1] || ''
        
            if (fileType) { // Check if fileType
                return (
                    <div style={{ width: '30px', minWidth: '25px' }}>
                            <FileIcon
                                extension={fileType}
                                {...defaultStyles[fileType]}
                                labelUppercase
                            />
                        </div>
                );
            }
            // Optionally, return a placeholder or null if connector or displayImage is missing
            return null;
        }
        

        // Default case: return the value as-is
        return value;
    };


    return (
        <div style={{maxWidth:'95%', margin: '0 0.5rem',}}>
            <Row className="mt-2 mb-2">
                <div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder={searchPlaceholder}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        style={{maxWidth: '250px'}}
                    />
                </div>
            </Row>
            {isLoading ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px'
                }}>
                    <Spinner animation="border" />
                </div>
            ) : filteredAndSortedData.length === 0 ? (
                <Alert variant="warning">No records found.</Alert>
            ) : filteredAndSortedData.length > 0 ? (
                    <Table responsive
                        style={{
                            borderCollapse: 'separate', // Use separate borders for spacing
                            borderSpacing: '0 0.2rem',
                            tableLayout: 'fixed',
                            
                        }}
                    >
                        <thead>
                            <tr >
                                {tableHeaders.map((header) => (
                                    <th
                                        key={header.key}
                                        onClick={() => requestSort(header.key)}
                                        style={{
                                            cursor: 'pointer',
                                            backgroundColor: 'transparent',
                                            border: 'none',
                                            color: '#6c757d',
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                            width: header.width,
                                            padding: '0.5rem 0'

                                        }}
                                    >
                                        {header.label} {getSortDirection(header.key)}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {filteredAndSortedData.map((row, index) => (
                                <tr
                                    key={index}
                                    style={{
                                        verticalAlign: 'middle',
                                        
                                        

                                    }}
                                >
                                    {tableHeaders.map((header) => (
                                        <td key={header.key}
                                        title={(typeof row[header.key] === 'string' ? row[header.key].split('.')[0] : 'N/A')}
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            padding: '0.5rem 0',
                                            fontSize: '14px',
                                            paddingRight: '10px'
                                        }}
                                        >
                                            {renderCellContent(header, row)}
                                        </td>
                                    ))}
                                    {rowActions && rowActions.length > 0 && (
                                        <td>
                                            <Dropdown
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <Dropdown.Toggle as="div" className="btn-ellipsis">
                                                    <FontAwesomeIcon icon={faEllipsisV} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {rowActions.map((action, actionIndex) => (
                                                        <Dropdown.Item
                                                            key={actionIndex}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                action.onClick(row);
                                                            }}
                                                        >
                                                            {action.label}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
            ) : (
                <Alert variant="warning">No records found.</Alert>
            )}
        </div>
    );
};

export default DynamicFileTable;
