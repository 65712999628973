import React, { useState, useRef, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar } from '@mui/material';
import { pink } from '@mui/material/colors';

const HeaderBarUserDropdown = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imgError, setImgError] = useState(false);  // Tracks if there's an error loading the image
  const [loading, setLoading] = useState(true);  // Tracks the loading state of the image
  const { logout } = useAuth0();
  
  const dropdownRef = useRef(null);
  
  const gravatarUrl = user ? user.picture : null; // Get Gravatar URL from user object
  
  // Toggles the dropdown visibility
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="custom-dropdown" style={{ position: 'relative' }} ref={dropdownRef}>
      {/* User image or icon with tooltip */}
      

      <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
        <Tooltip title={user?.name || "User"} placement="right">
        <Avatar 
        alt={user?.name.toUpperCase()} 
        src="/static/images/avatar/1.jpg"
        onClick={toggleDropdown}
        sx={{bgcolor: 'hotpink'}}
        />
          {/* Render the image only when it's not in error and loading is false */}
          {/* {gravatarUrl && !imgError && !loading ? (
            <img
              src={gravatarUrl}
              onLoad={() => setLoading(false)}  // Image successfully loaded
              onError={() => {
                setImgError(true);  // Error loading image
                setLoading(false);  // Stop loading
                console.error("Error loading user image.");
              }}
              alt="User"
              style={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                marginRight: '10px',
              }}
            />
          ) : (
            // If loading failed, show an icon or placeholder
            <AccountCircle 
              style={{
                fontSize: '45px',
                marginRight: '10px',
              }} 
            />
          )} */}
        </Tooltip>
      </div>

      {/* Dropdown list */}
      {isOpen && (
        <div
          className="dropdown-list"
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
            width: '200px',
            borderRadius: '5px',
            marginTop: '5px',
            right: '0'
          }}
        >
          <div
            className="dropdown-item"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            style={{
              cursor: 'pointer',
              padding: '10px',
              color: 'black',
            }}
          >
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderBarUserDropdown;
