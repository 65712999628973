import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook

const NotFoundContent = () => {

    const navigate = useNavigate(); // Initialize the useNavigate hook
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const goHome = () => {
        navigate('/'); // Navigate to the homepage
    };

    // For conditionally styling components based on window width. 
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <div className='full-width-error-section'>
                <Container className="h-80">
                    <Row className="align-items-center h-100">
                        <Col className='error-page-text '>
                            <h1>Oops! You're lost.</h1>
                            <p>The content you are looking for doesn't exist. How you got here is a mystery. Click the button below to go back to the homepage.</p>
                            <button className='btn btn-primary mb-05' onClick={goHome}>
                                Return home
                            </button>
                        </Col>
                        <Col xs={{ order: windowWidth < 991 ? 'first' : 'last' }} s={12} md={6}>
                            <img
                                src={`${process.env.PUBLIC_URL}/404_error.png`}
                                alt="404"
                                className='img-fluid mt-4 mb-4'
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default NotFoundContent;
