import React, { useState } from 'react';
import { IconButton, Snackbar } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Chip from '@mui/material/Chip';


function HeaderWithClipboard({ fieldTitle, fieldValue }) {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    setCopied(true);
    navigator.clipboard.writeText(fieldValue);
  };

  return (
    <div>
      <Chip variant='outlined' title='Copy session key' icon={<ContentCopyIcon fontSize='small' style={{color:'inherit'}}/>} onClick={handleClick} style={{color:'inherit', borderColor:'inherit'}} label={fieldValue}/>
      <Snackbar
        message="Copied to clipboard"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={1000}
        onClose={() => setCopied(false)}
        open={copied}
      />     
    </div>
  );
};

export default HeaderWithClipboard;
