import React, {useState, useEffect, useRef} from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


const Popup = ({ show, onHide, title, content, image, imageAlt}) => {

  return (
    <Modal size="lg" show={show} onHide={onHide} dialogClassName="custom-modal" centered>
      <div className='custom-modal-content'>
        <p className='custom-modal-title'>{title}</p>
        <img
          className='custom-modal-image'
          src={`${process.env.PUBLIC_URL}/${image}`}
          alt={imageAlt}
        />
        <div className='custom-modal-content-container'>
          <IconButton className="custom-modal-close-btn" aria-label="close" onClick={onHide}>
            <CloseIcon />
          </IconButton>
          {content}
        </div>
      </div>
    </Modal>
  );
};

export default Popup;
