import React, { useEffect, useState } from 'react';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Paper, Box } from '@mui/material';

const TreeView = ({ nestedData, dataKey = 'classifiers', labelKey = 'name', childrenKey = 'data', nodeKey = 'id' }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (Array.isArray(nestedData)) {
      setData(nestedData);
    } else if (nestedData && nestedData[dataKey]) {
      setData(nestedData[dataKey]);
    }
  }, [nestedData, dataKey]);

  const renderTree = (nodes) =>
    nodes.map((node, nodeIndex) => (
      <TreeItem
        key={`root-${node[nodeKey]}-${nodeIndex}`} // Ensure unique root keys
        itemId={`root-${node[nodeKey]}-${nodeIndex}`} // Ensure unique root ids
        label={
          <Box display="flex" alignItems="center" style={{ userSelect: 'none', padding: '4px', fontWeight: 'bold' }}>
            {node[labelKey] || 'Unnamed Category'}
          </Box>
        }
      >
        {node[childrenKey]?.map((child, childIndex) => (
          <TreeItem
            key={`child-${node[nodeKey]}-${child.code}-${childIndex}`} // Ensure unique child keys
            itemId={`child-${node[nodeKey]}-${child.code}-${childIndex}`} // Ensure unique child ids
            label={
              <Box display="flex" alignItems="center" style={{ userSelect: 'none', padding: '4px' }}>
                <div style={{ flexGrow: 0, marginRight: '8px', fontWeight: 'bold' }}>{child.code || 'N/A'}</div>
                <div style={{ flexGrow: 1 }}>{child.description || 'No description available'}</div>
              </Box>
            }
          />
        ))}
      </TreeItem>
    ));

  return (
    <Paper elevation={3} style={{ padding: '16px', height: 'auto', overflow: 'auto' }}>
      <SimpleTreeView getItemId={(node) => node.itemId || node.key}> {/* getItemId specifies a unique id for each item */}
        {renderTree(data)}
      </SimpleTreeView>
    </Paper>
  );
};

export default TreeView;
