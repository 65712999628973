import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import BlueprintPartPicker from './BlueprintPartPicker';
import StandardCard from './StandardCard';
import CategoryIcon from '@mui/icons-material/Category';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { minLength } from 'human-id';

const DefineWorkspaceStandard = ({ blueprints, handleSetTemplate, setHasCodeParts, setUploadFromExcel, setContinueEnabled }) => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedBlueprint, setSelectedBlueprint] = useState(null);
  const [check, setCheck] = useState("");

  // Pass-back to initiate to enable click of continue button
  useEffect(() => {
    // If 'check' is set to 'custom' or 'excel upload', enable continue
    if (check === "custom" || check === "excel upload") {
      setContinueEnabled(true);
    }
    // If 'check' is set to a blueprint, ensure that 'selectedBlueprint' is not null
    else if (check && check !== "" && selectedBlueprint !== null) {
      setContinueEnabled(true); // Enable Continue button
    } else {
      setContinueEnabled(false); // Disable Continue button
    }
  }, [check, selectedBlueprint, setContinueEnabled]);
  

  const handleCardSelect = (card, templateType) => {
    if (selectedCard === card) {
      setSelectedCard(0);
      setCheck(null);
      setSelectedBlueprint(null);
    } else {
      setSelectedCard(card);
      setCheck(templateType);
      setSelectedBlueprint(null);
    }
  };

  const handleBlueprintSelect = (blueprint) => {
    setSelectedBlueprint(blueprint);
    setCheck(blueprint.name);
  };

  useEffect(() => {
    if (selectedCard > 0) {
      if (selectedBlueprint) {
        handleSetTemplate(selectedBlueprint.name);
      } else {
        handleSetTemplate(check);
      }
    }
  }, [selectedCard, check, selectedBlueprint, handleSetTemplate]);

  useEffect(() => {
    if (check === 'Excel Upload') {
      setUploadFromExcel(true);
      setHasCodeParts(false);
    } else if (check === 'custom') {
      setUploadFromExcel(false);
      setHasCodeParts(false);
    } else if (selectedBlueprint) {
      setUploadFromExcel(false);
      setHasCodeParts(true);
    }
  }, [check, selectedBlueprint, setUploadFromExcel, setHasCodeParts]);

  const standardData = {
    1: {
      title: 'Start with an industry standard',
      content: (
        <BlueprintPartPicker 
          blueprints={blueprints} 
          onBlueprintSelect={handleBlueprintSelect} 
        />
      ),
    },
    2: {
      title: 'Build or upload your own',
      content: (
        <Row xs={1} md={2} className="g-2">
          <Col>
            <StandardCard
              id='custom'
              title='Custom standard'
              description="Start from a blank canvas. Perfect for when you want to match to a client's standard or build your own."
              handleSetCheck={() => handleCardSelect(2, 'custom')}
              check={check}
            />
          </Col>
          <Col>
            <StandardCard
              id='Excel Upload'
              title='Upload from Excel'
              description="If you've used Hoppa before and exported your standard to Excel, you can upload it here to use it again."
              handleSetCheck={() => handleCardSelect(2, 'Excel Upload')}
              check={check}
            />
          </Col>
        </Row>
      ),
    },
    3: {
      title: 'Use AI to build one for you',
      content: (
        <p className="text-start">Coming soon...</p>
      ),
    },
  };

  const cardStyles = {
    card1: { backgroundColor: 'rgb(239, 245, 255)', border: '#2200ff58 1px solid', borderRadius: '3px' },
    card2: { backgroundColor: 'rgb(254, 246, 251)', border: '#ff36af6d 1px solid', borderRadius: '3px' },
    card3: { backgroundColor: 'rgb(254, 247, 236)', border: '#ffa702a6 1px solid', borderRadius: '3px'},
  };

  return (
    <Container>
    <Row className="mb-3">
  {/** Card 1 **/}
  <Col xs={12} md={4} className="d-flex mb-2">
    <Card
      style={{
        ...cardStyles.card1,
        color: 'black',
        flex: '1',
        margin: '10px',
        cursor: 'pointer',
        ...(selectedCard === 1 ? { boxShadow: '2px 2px 22px rgba(14, 1, 255, 0.552)' } : {}),
      }}
      onClick={() => handleCardSelect(1, 'standard')}
      className={`text-center card-hover ${selectedCard === 1 ? 'active-card' : ''}`}
    >
      <Card.Body className="d-flex flex-column flex-sm-row align-items-center text-center text-sm-start" style={{ padding: '20px' }}>
        <div
          className="d-flex align-items-center justify-content-center mx-auto mx-sm-0"
          style={{
            backgroundColor: '#0051B6',
            borderRadius: '10%',
            width: '70px',
            height: '70px',
            marginBottom: '10px',
          }}
        >
          <CategoryIcon style={{ fontSize: '3rem', color: 'white' }} />
        </div>
        <Card.Title className="ms-sm-3" style={{ margin: '0', fontSize: '1.2rem', flex: '1', textAlign: 'right', marginRight:"40px", color: "#003270" }}>
          Select a blueprint
        </Card.Title>
      </Card.Body>
    </Card>
  </Col>

  {/** Card 2 **/}
  <Col xs={12} md={4} className="d-flex mb-2">
    <Card
      style={{
        ...cardStyles.card2,
        color: 'black',
        flex: '1',
        margin: '10px',
        cursor: 'pointer',
        ...(selectedCard === 2 ? { boxShadow: '2px 2px 22px rgba(255, 2, 255, 0.45)' } : {}),
      }}
      onClick={() => handleCardSelect(2, 'custom')}
      className={`text-center card-hover ${selectedCard === 2 ? 'active-card' : ''}`}
    >
      <Card.Body className="d-flex flex-column flex-sm-row align-items-center text-center text-sm-start" style={{ padding: '20px' }}>
        <div
          className="d-flex align-items-center justify-content-center mx-auto mx-sm-0"
          style={{
            backgroundColor: 'rgb(248, 7, 153)',
            borderRadius: '10%',
            width: '70px',
            height: '70px',
            marginBottom: '10px',
          }}
        >
          <AddCircleOutlineIcon style={{ fontSize: '3rem', color: 'white' }} />
        </div>
        <Card.Title className="ms-sm-3" style={{ margin: '0', fontSize: '1.2rem', flex: '1', textAlign: 'right', marginRight:"40px", color: 'rgb(210, 1, 127)' }}>
          Start from scratch
        </Card.Title>
      </Card.Body>
    </Card>
  </Col>

  {/** Card 3 - Disabled **/}
  <Col xs={12} md={4} className="d-flex mb-2">
    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-disabled">Coming soon!</Tooltip>}>
        <Card
          style={{
            ...cardStyles.card3,
            color: 'black',
            flex: '1',
            margin: '10px',
            cursor: 'not-allowed',
          }}
          className={`text-center`}
        >
          <Card.Body className="d-flex flex-column flex-sm-row align-items-center text-center text-sm-start" style={{ padding: '20px' }}>
            <div
              className="d-flex align-items-center justify-content-center mx-auto mx-sm-0"
              style={{
                backgroundColor: 'rgb(251, 199, 85)',
                borderRadius: '10%',
                width: '70px',
                height: '70px',
                marginBottom: '10px',
              }}
            >
              <AutoAwesomeIcon style={{ fontSize: '3rem', color: 'white' }} />
            </div>
            <Card.Title className="ms-sm-3" style={{ margin: '0', fontSize: '1.2rem', flex: '1', textAlign: 'right', marginRight:"40px", color: "rgb(170, 130, 42)" }}>
              Generate with AI
            </Card.Title>
          </Card.Body>
        </Card>
    </OverlayTrigger>
  </Col>
</Row>


      {selectedCard > 0 && (
        <div className="text-center smaller-font">
          {standardData[selectedCard].content}
        </div>
      )}
    </Container>
  );
};

export default DefineWorkspaceStandard;
