import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col, Button, Form, OverlayTrigger, Tooltip, Badge, Modal } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import HeaderBar from './shared/HeaderBar';
import DefineStandard from './subcomponents/DefineStandard';
import CustomiseStandard from './subcomponents/CustomiseStandard';
import DefineEnrichers from './subcomponents/DefineEnrichers';
import FileUpload from './subcomponents/FileUpload';
import Checkout from './subcomponents/Checkout';
import Popup from './shared/Popup';
import { useUserStandard } from '../contexts/InitiateContexts';
import { useSession } from '../contexts/SessionContexts';
import axios from 'axios';
import '../App.scss';
import HorizontalLinearStepper from './shared/LinearStepper';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import HeaderWithClipboard from './shared/HeaderWithClipboard';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';

import {
  useNavigate,
  useParams,
} from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL



const Initiate = (props) => {
  const { userStandard } = useUserStandard();
  const { user } = useParams(); // Get user name from URL
  const { sessionId } = useParams(); // get session ID from URL
  const { setSession } = useSession(); 
  setSession(sessionId, user); // Update context with URL params
  const { sessionGuid, userId } = useSession();
  const navigate = useNavigate(); // for handling page routing within a function. 

  const cancelTokenSource = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [fileOperationInProgress, setFileOperationInProgress] = useState(false); // New state variable
  const [showCheckout, setShowCheckout] = useState(false); // Don't mount checkout until that step is reached to prevent premature API call to stripe
  const [showOverlay, setShowOverlay] = useState(false);

  // Variables to control page validation.
  const [validated, setValidated] = useState(false);
  const [firstTimeConfig, setFirstTimeConfig] = useState(true)
  const [existingTemplate, setExistingTemplate] = useState(null)
  const [newTemplate, setNewTemplate] = useState(null)
  const [clearStandard, setClearStandard] = useState(false)
  const [showOverwriteConfirmation, setShowOverwriteConfirmation] = useState(false); // State variable for showing/hiding the modal to overwrite standard
  const [navigationDirection, setNavigationDirection] = useState(null)
  const [fileCount, setFileCount] = useState(0)
  const [nextLock, setNextLock] = useState(false)

  // Variables that control user flow through classification configuration and code part validation
  const [hasCodeParts, setHasCodeParts] = useState(true)
  const [allCodePartsHaveValues, setAllCodePartsHaveValues] = useState(true)
  const [uploadFromExcel, setUploadFromExcel] = useState(false)
 
  // Variables to control notification messages
  const [showToast, setShowToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  // Variables to control help messages
  const [showStandardHelp, setShowStandardHelp] = useState(false);
  const [showCustomiseHelp, setShowCustomiseHelp] = useState(false);
  const [showExtractionsHelp, setShowExtractionsHelp] = useState(false);
  const [showUploadHelp, setShowUploadHelp] = useState(false);

  const toggleStandardHelp = () => setShowStandardHelp(!showStandardHelp);
  const toggleCustomiseHelp = () => setShowCustomiseHelp(!showCustomiseHelp);
  const toggleExtractionsHelp = () => setShowExtractionsHelp(!showExtractionsHelp);
  const toggleUploadHelp = () => setShowUploadHelp(!showUploadHelp);

  const steps = ['Choose a standard', 'Customise to suit', 'Next-gen search', 'Upload your files', 'Review and confirm'];
  console.log("Current step: ", currentStep)

  const isStepOptional = (step) => {
    return step === 1 || step === 2; // Step 2 and 3 are optional. 
  };

  // Google analytics ping on component mount
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  // Function to close the confirmation modal
  const handleCloseConfirmation = () => {
    setShowOverwriteConfirmation(false);
  };

  // Function to confirm user wants to select a new standard and continue to customise the standard. 
  const overwriteAllCodeParts = () => {
    setExistingTemplate(newTemplate);
    setClearStandard(true);
    setShowOverwriteConfirmation(false);
    setCurrentStep(currentStep + 1)
    setShowOverwriteConfirmation(false);
  }

  /* This section of code needs to take the userStandard, turn it into a json, and use the fileUpload endpoint to save the file to blob with sessionGuid tag */
  const handleSessionCommit = async (event) => {

    setShowOverlay(true)

    const userStandardJSON = JSON.stringify(userStandard);

    const userStandardJSONforBlob = new Blob([userStandardJSON], { type: 'application/json' });

    const formData = new FormData();

    formData.append('file', userStandardJSONforBlob, 'userStandard-classify-tag-search.json');
    formData.append('folder', userId);

    cancelTokenSource.current = axios.CancelToken.source();

    formData.append('sessionGuid', sessionGuid);

    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    console.log("FormData content:", formDataObject);

    try {
      setFileOperationInProgress(true); // Set the operation in progress flag

      const fileUploadResponse = await axios.post(
        `${API_BASE_URL}/api/FileUploadTrigger`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
          },
          cancelToken: cancelTokenSource.current.token,
        }
      );

      console.log("File upload successful. Server response:", fileUploadResponse.data);

      const response = axios.post(
        `${API_BASE_URL}/api/QueueFiles?sessionGuid=${sessionGuid}&containerName=${userId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
          },
          cancelToken: cancelTokenSource.current.token,
        }
      );

      console.log("Upload successful. Server response:", response.data);

      // Reset the operation in progress flag after the operation is completed
      setFileOperationInProgress(false);

      setTimeout(() => navigate(`/${userId}/${sessionGuid}/results`), 10000);

    } catch (error) {
      setFileOperationInProgress(false);
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.error("Error during upload:", error);
        // Handle other error cases
      }
    }

  };

  const handleStepChange = (event) => {

    if (event.key === 'Enter') {
      event.preventDefault()
      event.stopPropagation()
      return
    }

    console.log('File count is', fileCount)

    let stepIndex;

    if (navigationDirection === 'previous') {
      stepIndex = currentStep - 1;
    } else if (navigationDirection === 'next') {
      stepIndex = currentStep + 1;
    }

    console.log(stepIndex)

    // Trigger form validation. 
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    if (currentStep === 1 && allCodePartsHaveValues===false) {
      handleSetToastMessage("Not all Code Parts have values, check for blank rows before moving off this.");
      return;
  }

    // Validate that user has defined at least one code part. 
    if (stepIndex === 2 && userStandard.classifiers.length === 0) {
      return handleSetToastMessage("Classifiers cannot be blank.")
    }

    if(currentStep===3 && fileCount===0 && stepIndex===4) {
      return handleSetToastMessage("Upload at least one file")
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    // Check if the session is being configured for the first time. 
    if (firstTimeConfig) {
      // No check required. Update template details.
      setExistingTemplate(newTemplate)
      setCurrentStep(stepIndex)
    }

    // Check if the template has been changed since the Next button was last pressed. 
    else if (existingTemplate === newTemplate) {
      setCurrentStep(stepIndex);
    }
    // Ask for confirmation. 
    else {
      setShowOverwriteConfirmation(true);
    }
  };

  // Function for the DefineStandard subcomponent to pass the selected template details to the Initiate component. 
  const handleSetTemplate = (template) => {
    setNewTemplate(template)
  }

  // Function for the CustomiseStandard subcomponent to reset the boolean in the Initiate component.
  const handleSetFirstTimeConfig = () => {
    setFirstTimeConfig(false)
  }

  // Function for the CustomiseStandard subcomponent to reset the boolean in the Initiate component.
  const handleSetClearStandard = (boolean) => {
    setClearStandard(boolean)
  }

  // Function for the CustomiseStandard subcomponent to reset the boolean in the Initiate component.
  const handleSetShowOverwriteConfirmation = () => {
    setShowOverwriteConfirmation(true)
  }

  const handleSetToastMessage = (toastMessage) => {
    setToastMessage(toastMessage)
    setShowToast(true)
  }

  const handleSetFileCount = (newFileCount) => {
    setFileCount(newFileCount)
    console.log('File count is', newFileCount)
  }

  const handleSetNextLock = (boolean) => {
    setNextLock(boolean)
  }

  useEffect(() => {
    // Check if current step is 4 and showCheckout is false, then set showCheckout to true
    if (currentStep === 4 && !showCheckout) {
      // TO BE SWITCHED WHEN READY TO IMPLEMENT STRIPE
      setShowCheckout(false);
    }

    if (currentStep === 3 && showCheckout) {
      setShowCheckout(false);
    }

  }, [currentStep]);

  //console.log(hasCodeParts)

  // Set help modal content

  const renderModalContent = (
    <>
      {showStandardHelp && (
        <Modal.Body>
          <b>Why is this step important?</b><br /><br />
          Information management in the built environment involves organising, storing, retrieving, and utilising data and knowledge related to construction projects, infrastructure, and facilities.<br /><br />
          It encompasses systems and processes for managing documents, drawings, specifications, schedules, budgets, and other project-related information.<br /><br />
          Its usefulness lies in enhancing collaboration, efficiency, and decision-making throughout the lifecycle of built assets, leading to cost savings, improved quality and safety, and more timely delivery of projects  💪<br /><br />
          <b>Where to start?</b><br /><br />
          Many client organisations expect to receive information in a format that complies to their preferred standard, this is usually set out in the Employers Information Requirements or similar documents.<br /><br />
          Where no standard is mandated, adherence to the globally recognised ISO standard for the industry, ISO 19650, is heavily encouraged.<br /><br />
          If you'd prefer to build a standard from the ground up to suit your specific business requirements, you can do so on the next step using our intuitive standard builder.<br /><br />
  
          <b>Got a request?</b><br /><br />
          If you would like a specific industry or client standard to be added to our list of pre-configured standards, or if you would like further help in working out the right way forward for your project, we'd love to hear from you at <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a>
        </Modal.Body>
      )}
      {showCustomiseHelp && (
        <Modal.Body>
        <b>Why is this step important?</b><br /><br />
        Customising your standard allows you to tailor the classification system to your specific needs and requirements. By defining classifiers and their corresponding reference codes, you can ensure that your files are organised in a way that makes sense for your organisation or project 😎<br /><br />
        <b>How does it work?</b><br /><br />
        <b><i>Define Classifiers</i></b> | Start by defining the Classifiers that represent the categories or types of information you want to classify your files against. These could include disciplines, document types, project phases, or any other relevant categories. Open the Classifier Editor to add to, edit or change the sequential order of your Classifiers 🪣<br /><br /> 
        <b><i>Set Reference Codes</i></b> | Once you've defined your Classifiers, you next set Reference Codes for each Classifier. Reference codes are specific codes or identifiers that you use to categorise your documents and data within each classifier. For each file, our AI will pick the single most suitable Reference Code for each Classifier. You'll see exactly how our AI made its decision and how confident it is in its answer 🤔<br /><br />
        <b><i>Add Descriptions</i></b> | Adding descriptions to your Reference Codes is essential as it is the primary reference point for our AI in it's decision making process. Your description for each Reference Code must be brief, but rich in contextual information ✍️<br /><br />
        For example, instead of 'DWG' - 'Drawings', try 'DWG' - 'Drawings, site plans, layouts, floorplans' ✅<br /><br />
        <b>Where to start?</b><br /><br />
        If you're not sure where to start, take a look at one of the pre-defined standards for inspiration. You can also reach out to us if you'd like assistance or guidance in customising your standard to suit your specific business requirements.<br /><br />
        <b>Got a request or need help?</b><br /><br />
        If you have any specific requests or need assistance with customising your standard, feel free to reach out to us at <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a>. We're here to help!
      </Modal.Body>
      )}
      {showExtractionsHelp && (
        <Modal.Body>
        <b>Why is this step important?</b><br /><br />
        Configuring your search preferences enables you to swiftly extract critical information from your files. By utilising tags and search terms you can efficiently look for key facts across your entire data set, saving you from having to do it manually. What's more, our AI is far more diligent than the average human on a document diving mission! So... this step could be the difference between finding what you're looking for or never finding it at all 😵‍💫<br /><br />
        <b>How does it work?</b><br /><br />
        <b><i>Tagging Files:</i></b> Input your desired tags to instruct our AI to categorise documents accordingly. Our advanced tagging system ensures all relevant files are accurately tagged based on your specified criteria 🏷️<br /><br /> 
        <b><i>Applying Search Terms:</i></b> Enter specific search terms to pinpoint documents containing key information. Our AI uses natural language processing to analyse your questions and then asks them of all your files, returning results where relevant 🔍<br /><br />
        <b><i>Specific Natural Language Question Answering:</i></b> Our search feature goes beyond simple keyword matching. You can ask complex questions in natural language, and our AI will provide precise answers by extracting relevant information from documents 🧠<br /><br />
        <u>Well explained questions get the best out of our AI</u><br /><br />
        "Dates" ❌<br /><br />
        "Dates; find all listed dates, times, deadlines, milestones and their stated relevance" ✅<br /><br />
        <b>Where to start?</b><br /><br />
        To begin, simply input your desired tags and search terms into the search interface. You can refine your search parameters as needed to precisely target the information you require. If you have specific information retrieval exercises that you'd like help with, get in touch. Reach out for a chat, and we might be able to work out a way to save you an enormous amount of effort and time.<br /><br />
        <b>Got a request or need help?</b><br /><br />
        If you would like help, or have an urgent information retrieval challenge you need a hand in dealing with, reach out to us as <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a>. We're always happy to discuss how our technology could help.
      </Modal.Body>
      
      
      )}
      {showUploadHelp && (
        <Modal.Body>
        <b>Why is this step important?</b><br /><br />
        Uploading your files is the penultimate step. Whether it's documents, images, or other industry-standard formats, we've got you covered  🤝<br /><br />
        <b>Supported File Formats</b><br />
        We can handle all common file formats, including but not limited to PDF, DOCX, XLSX, PPTX, JPG, PNG, and TIFF. Our system is compatible with file formats consumable by Azure Document Intelligence Read and GPT Vision  👁️<br /><br />
        <b>Unsupported File Formats:</b><br />
        While we can process files of unsupported formats, the accuracy may not be as high. However, our AI is continuously learning and improving!  🤖<br /><br />
        <b>Beta Recommendation</b><br />
        While we're in beta, we recommend running trial uploads of no more than 25 files to ensure optimal performance and accuracy. It's a good idea to take an iterative approach, tweaking your standard as needed 🧪<br /><br />
        <b>Got a request or need help?</b><br /><br />
        If you have any specific questions, need assistance with your uploads, or wish to discuss processing large volumes of data, feel free to reach out to us at <a href="mailto:hello@hoppa.ai">hello@hoppa.ai</a>  🚀
      </Modal.Body>
      
      )}
    </>
  );
  
  const toggleHelpModal = () => {
    if (showStandardHelp) {
      toggleStandardHelp();
    } else if (showCustomiseHelp) {
      toggleCustomiseHelp();
    } else if (showExtractionsHelp) {
      toggleExtractionsHelp();
    } else if (showUploadHelp) {
      toggleUploadHelp();
    }
  };
  
  
  // Next-gen Help modal content
  const ExtractionsContent = () => (
    <Modal.Body>
      {/* Content for the Next-gen help */}
    </Modal.Body>
  );
  
  // Upload Help modal content
  const UploadHelpContent = () => (
    <Modal.Body>
      {/* Content for the Upload help */}
    </Modal.Body>
  );

  return (
    <div>
      <Modal show={showOverlay} centered className='modal-session-commit'>
          <div className='d-flex align-items-center flex-column'>
            <div className='icon-wrapper mb-3'>
              <div className='icon-outercontainer'>
                <div className='icon-innercontainer'>
                  <DoneRoundedIcon fontSize='large' style={{ color: 'white' }} />
                </div>
              </div>
            </div>
            <div className='text-center'>
            <h3>Success!</h3> 
            <p>We're uploading your standard and finalising a few details. Your session will be added to the queue and will be processed shortly.</p>
            <p>It's a good idea to take a copy of your session key so you can access your results later:</p>
            <HeaderWithClipboard style={{ color: 'inherit' }} fieldValue={sessionGuid} />
            <p className='mt-3'>Click the button below to be redirected to the results page.</p>
            <Button className='btn btn-tertiary' onClick={() => navigate(`/${userId}/${sessionGuid}/results`)}>Access your results</Button>           
            </div>
            </div>
      </Modal>
    <div>
      <HeaderBar homeLink="/" homeText="hoppa" link1='test'/>
      <Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide className="position-fixed top-0 start-50 translate-middle-x">
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>
      {/* Section 1 - 3 step accordion accepting the props defined at the start of this file */}
      <div className="full-width-primary-section">
        <div>
          <Form
            noValidate
            validated={validated}
            onSubmit={(event) => handleStepChange(event)}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          >
            { }
            <Container className="d-flex justify-content-between">
              <Button
                variant="secondary"
                disabled={currentStep === 0}
                type='submit'
                onClick={() => setNavigationDirection('previous')}
              >
                Previous
              </Button>
                <Button
                  variant="primary"
                  /*disabled={currentStep === steps.length - 1 | nextLock}*/
                  type='submit'
                  onClick={() => {
                    if (currentStep === 4) {
                      handleSessionCommit()
                      console.log("Session committed");
                    } else {
                      setNavigationDirection('next'); // Call next action
                    }
                  }}
                >
                  {currentStep === 4 ? 'Run' : 'Next'}
                </Button>
            </Container>
            {/* Progress Stepper */}
            <Container>
              <HorizontalLinearStepper steps={steps} isStepOptional={isStepOptional} activeStep={currentStep} />
            </Container>
            <Container className="pt-3 mb-5">
              <div className={currentStep === 0 ? '' : 'hidden'}>
                <Container className="blue-font background-fade-purple-sunburst mt-3 mb-3 pt-3 pb-3  position-relative"><h2>Define a classification standard</h2><h4>Tell hoppa how your files should be organised</h4>
                <Badge pill bg="primary" className="position-absolute top-0 end-0 mt-1" style={{ marginRight: '0.2rem', cursor: 'pointer' }}>
                <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-info">Click for help 💡</Tooltip>}
                >
                    <FontAwesomeIcon icon={faQuestionCircle} className="text-white" onClick={toggleStandardHelp} />
                </OverlayTrigger>
            </Badge>
            </Container>
                <DefineStandard
                  handleSetTemplate={handleSetTemplate}
                  setHasCodeParts={setHasCodeParts}
                  setUploadFromExcel={setUploadFromExcel}
                  

                />
              </div>
              <div className={currentStep === 1 ? '' : 'hidden'}>
              <Container className="blue-font background-fade-purple-sunburst mt-3 mb-3 pt-3 pb-3  position-relative"><h2>Customise the standard to suit your needs</h2><h4>Define your classifiers, and hoppa will do the rest</h4>
                <Badge pill bg="primary" className="position-absolute top-0 end-0 mt-1" style={{ marginRight: '0.2rem', cursor: 'pointer' }}>
                  <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-info">Click for help 💡</Tooltip>}
                    >
                        <FontAwesomeIcon icon={faQuestionCircle} className="text-white" onClick={toggleCustomiseHelp} />
                  </OverlayTrigger>
                  </Badge>
                </Container>
                <CustomiseStandard
                  template={existingTemplate}
                  hasCodeParts={hasCodeParts}
                  uploadFromExcel={uploadFromExcel}
                  firstTimeConfig={firstTimeConfig}
                  handleSetFirstTimeConfig={handleSetFirstTimeConfig}
                  clearStandard={clearStandard}
                  handleSetClearStandard={handleSetClearStandard}
                  handleSetToastMessage={handleSetToastMessage}
                  step={currentStep}
                  setAllCodePartsHaveValues={setAllCodePartsHaveValues}
                />
              </div>
              <div className={currentStep === 2 ? '' : 'hidden'}>
              <Container className="blue-font background-fade-purple-sunburst mt-3 mb-3 pt-3 pb-3  position-relative"><h2>Extract knowledge from your information</h2><h4>GPT-powered file tagging and natural language question-answering</h4>
              <Badge pill bg="primary" className="position-absolute top-0 end-0 mt-1" style={{ marginRight: '0.2rem', cursor: 'pointer' }}>
                  <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-info">Click for help 💡</Tooltip>}
                    >
                        <FontAwesomeIcon icon={faQuestionCircle} className="text-white" onClick={toggleExtractionsHelp}/>
                  </OverlayTrigger>
                  </Badge>
              </Container>
                <DefineEnrichers />
              </div>
              <div className={currentStep === 3 ? '' : 'hidden'}>
              <Container className="blue-font background-fade-purple-sunburst mt-3 mb-3 pt-3 pb-3  position-relative"><h2>Feed the hoppa!</h2><h4>Upload your files to get going</h4>
                <Badge pill bg="primary" className="position-absolute top-0 end-0 mt-1" style={{ marginRight: '0.2rem', cursor: 'pointer' }}>
                  <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip-info">Click for help 💡</Tooltip>}
                      >
                          <FontAwesomeIcon icon={faQuestionCircle} className="text-white" onClick={toggleUploadHelp}/>
                  </OverlayTrigger>
                  </Badge>
              </Container>
                <FileUpload 
                handleSetToastMessage={handleSetToastMessage} 
                handleSetFileCount={handleSetFileCount}
                handleSetNextLock={handleSetNextLock}
                />
              </div>
              {showCheckout && (
              <div className={currentStep === 4 ? '' : 'hidden'}>
                <Checkout sessionGuid={sessionGuid} fileCount={fileCount} handleSessionCommit={handleSessionCommit}/>
              </div>
              )}
            </Container>
          </Form>
        </div>
      </div>
      {/* Render OverwriteConfirmation component if showOverwriteConfirmation is true */}
      <Modal show={showOverwriteConfirmation} onHide={handleCloseConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Selecting a new template will overwrite all changes on the next page. Are you sure you want to continue?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmation}>Cancel</Button>
          <Button variant="primary" onClick={overwriteAllCodeParts}>Overwrite All</Button>
        </Modal.Footer>
      </Modal>
      
      {/* Render OverwriteConfirmation component if showOverwriteConfirmation is true */}
      <Modal show={showStandardHelp || showCustomiseHelp || showExtractionsHelp || showUploadHelp } onHide={toggleHelpModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Help</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ overflowY: 'auto' }}>
          {renderModalContent}
        </Modal.Body>
      </Modal>


    </div>
    </div>
  )
}

export default Initiate;