import React, { useEffect, useState } from 'react';
import { ListGroup, Badge, Collapse } from 'react-bootstrap';

const BlueprintPartPicker = ({ blueprints = [], onBlueprintSelect }) => {
  const [selectedBlueprint, setSelectedBlueprint] = useState(null);
  const [selectedClassifier, setSelectedClassifier] = useState(null);
  const [firstPanelWidth, setFirstPanelWidth] = useState('100%'); // Start with full width
  const [secondPanelWidth, setSecondPanelWidth] = useState('0%'); // Hidden initially
  const [showPanels, setShowPanels] = useState(false);
  const [showClassifiers, setShowClassifiers] = useState(false);
  const [showClassifierDescription, setShowClassifierDescription] = useState(false);

  const handleBlueprintClick = (e, blueprint) => {
    e.preventDefault();
    e.stopPropagation();

    // Toggle the panel width based on repeated clicks
    if (selectedBlueprint === blueprint && firstPanelWidth === '50%') {
      // Set first panel to full width, hide second panel
      setSelectedBlueprint(null);
      setFirstPanelWidth('100%');
      setSecondPanelWidth('0%');
      setShowClassifiers(false);
      setShowPanels(false);
    } else {
      // Set both panels to half-width, show classifiers
      setSelectedBlueprint(blueprint);
      setSelectedClassifier(null);
      setShowClassifiers(true);
      setShowPanels(true);
      setFirstPanelWidth('50%');
      setSecondPanelWidth('50%');
      //onBlueprintSelect(blueprint); // Pass the selected blueprint back to the parent
       // Log the selected blueprint for debugging
    }
  };

  useEffect(() => {
    if (selectedBlueprint) {
      onBlueprintSelect(selectedBlueprint);
      
    }
  }, [selectedBlueprint, onBlueprintSelect]);

  const handleClassifierClick = (e, classifier) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedClassifier(classifier);
    setShowClassifierDescription((prev) => (selectedClassifier === classifier ? !prev : true));
  };

  const handleCollapseClassifiers = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowClassifiers(false);
    setSelectedClassifier(null);
    setSecondPanelWidth('50%');
    setShowClassifierDescription(false);
  };

  // Sort blueprints to prioritize "General" clients at the top
  const sortedBlueprints = blueprints.sort((a, b) =>
    a.client === 'General' ? -1 : b.client === 'General' ? 1 : 0
  );

  const visibleClassifiers = selectedBlueprint ? Object.values(selectedBlueprint.classifiers) : [];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div style={{ display: 'flex', flex: 1 }}>
        {/* First Panel: Blueprint List with Accordion */}
        <div
          style={{
            width: firstPanelWidth,
            padding: '10px',
            transition: 'width 0.3s ease-in-out',
          }}
        >
          <ListGroup>
            {sortedBlueprints.map((blueprint) => (
              <ListGroup.Item
                key={blueprint.name}
                action
                onClick={(e) => handleBlueprintClick(e, blueprint)}
                active={selectedBlueprint === blueprint}
              >
                {blueprint.name}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>

        {/* Second Panel: Classifiers List */}
        {selectedBlueprint && showClassifiers && (
          <div
            style={{
              width: secondPanelWidth,
              padding: '10px',
              transition: 'width 0.3s ease-in-out, opacity 0.3s ease-in-out',
              opacity: showClassifiers ? 1 : 0,
            }}
          >
            {/* Blueprint Description Above Classifiers */}
            <div style={{ marginBottom: '15px', color: '#555', backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
              <div>{selectedBlueprint.description}</div>
              <div style={{ marginTop: '5px' }}>
                <Badge pill variant="info" style={{ marginRight: '5px' }}>
                  {selectedBlueprint.client}
                </Badge>
                <Badge pill variant="secondary">
                  {selectedBlueprint.region}
                </Badge>
              </div>
            </div>

            <ListGroup>
              {visibleClassifiers.map((classifier) => (
                <div key={classifier.name}>
                  <ListGroup.Item
                    action
                    onClick={(e) => handleClassifierClick(e, classifier)}
                    active={selectedClassifier === classifier}
                    style={{
                      backgroundColor: selectedClassifier === classifier ? '#e9ecef' : 'white', // Grey for selected classifier
                      color: 'black',
                      borderColor: '#ddd',
                    }}
                  >
                    {classifier.name}
                  </ListGroup.Item>
                  {selectedClassifier === classifier && (
                    <Collapse in={showClassifierDescription}>
                      <div style={{ padding: '10px', color: '#555', backgroundColor: '#fff', border: '1px solid #ccc' }}>
                        {classifier.prompt}
                      </div>
                    </Collapse>
                  )}
                </div>
              ))}
            </ListGroup>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlueprintPartPicker;
