import React, { useState, useEffect, useRef } from 'react'; // Import useRef
import Tooltip from '@mui/material/Tooltip';

const HeaderBarOrgDropdown = ({ organizations, currentOrg, handleOrgSwitch, switchingAllowed }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [logoUrl, setLogoUrl] = useState('');
  const [dropdownLogos, setDropdownLogos] = useState({}); // State to hold logos for dropdown items
  const dropdownRef = useRef(null); // Create a reference for the dropdown

  const toggleDropdown = () => {
    if (switchingAllowed) {
      setIsOpen((prev) => !prev);
    }
  };
  

  const handleOptionClick = (org) => {
    handleOrgSwitch(org.id);
    setIsOpen(false);
  };

  // Fetch current organization image
  useEffect(() => {
    const fetchCurrentOrgImage = async () => {
      if (currentOrg?.branding?.logo_url) {
        try {
          const response = await fetch(currentOrg.branding.logo_url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setLogoUrl(url);
          setIsLoaded(true);
        } catch (error) {
          console.error("Error fetching current organization image:", error);
          setIsLoaded(false);
        }
      } else {
        console.warn("No logo URL found for the current organization.");
      }
    };

    fetchCurrentOrgImage(); // Call the fetch image function for current organization
  }, [currentOrg]);

  // Fetch dropdown logos
  useEffect(() => {
    const fetchDropdownLogos = async () => {
      const logos = {};
      const filteredOrgs = organizations.filter(org => org.id !== currentOrg?.id);

      for (const org of filteredOrgs) {
        if (org.branding?.logo_url) {
          try {
            const response = await fetch(org.branding.logo_url);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            logos[org.id] = url; // Store logo URL by organization ID
          } catch (error) {
            console.error("Error fetching dropdown organization image:", error);
          }
        } else {
          console.warn(`No logo URL found for organization ${org.name}`);
        }
      }

      setDropdownLogos(logos); // Update state with all loaded logos
    };

    if (isOpen) {
      fetchDropdownLogos(); // Fetch logos when dropdown is opened
    }
  }, [isOpen, organizations, currentOrg]);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);
    
    // Clean up event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]); // Run effect whenever dropdownRef changes

  return (
    <div className="custom-dropdown" style={{ position: 'relative' }} ref={dropdownRef}>
      {/* Current organization display */}
      {currentOrg && (
        <div className='d-flex align-items-center' onClick={toggleDropdown} style={{ cursor: 'pointer' }}>
          <Tooltip title={currentOrg.display_name} placement="left">
            <img
              src={logoUrl} // Use the loaded logo URL for current organization
              alt={`${currentOrg.name} Logo`}
              style={{
                borderRadius: '50%',
                width: '40px',
                height: '40px',
              }}
            />
          </Tooltip>
          {/*<span>{currentOrg.display_name}</span>*/}
        </div>
      )}

      {/* Dropdown list */}
      {isOpen && (
        <div className="dropdown-list" style={{ 
          position: 'absolute', 
          backgroundColor: 'white', 
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
          zIndex: 1000, 
          width: '200px', // Set a width for dropdown
          borderRadius: '5px',
          marginTop: '5px', // Space between current org display and dropdown
          right: '0' // Position the dropdown to the left by aligning it to the right of the parent
        }}>
          {organizations.filter(org => org.id !== currentOrg?.id).map((org) => (
            <div
              key={org.id}
              className="dropdown-item d-flex align-items-center"
              onClick={() => handleOptionClick(org)}
              style={{ 
                cursor: 'pointer', 
                padding: '10px', 
                borderBottom: '1px solid #f1f1f1' // Optional styling for dropdown items
              }}
            >
              <img
                src={dropdownLogos[org.id]} // Use the loaded logo URL for dropdown items
                alt={`${org.name} Logo`}
                style={{
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                  marginRight: '10px',
                }}
              />
              <span>{org.display_name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HeaderBarOrgDropdown;
