import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const exchangeMicrosoftAuthorizationCode = async (authCode, containerName, userId) => {

    const config = {
        headers: {
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
        },
        params: {
            connector_key: 'sharepoint',
            code: authCode,
            code_verifier: localStorage.getItem('code_verifier'), // A cryptographically random string stored during the initial OAuth flow used to ensure that the authorization code received during the OAuth flow cannot be intercepted or reused by a malicious actor.
            organisation: containerName,
            user_id: userId,
            redirect_uri: `${window.location.origin}${window.location.pathname}`
        }
    }

    try {
        const response = await axios.get(`${API_BASE_URL}/api/ExchangeMicrosoftAuthorizationToken`, config);
        console.log(response.data);
    } catch (error) {
        console.error('Error exchanging authorization code:', error);
    }
};