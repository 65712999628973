import { border } from '@mui/system';
import * as React from 'react';
import { useState, useEffect } from "react"
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'


function NavBar({ handleShowSessionModal, handleShowStartModal }) {
    const [pos, setPos] = useState(0)
    const [backgroundColor, setBackgroundColor] = useState("rgba(0, 0, 0, 0)")
    const [borderColor, setBorderColor] = useState("rgba(231, 235, 243, 0)")
    const [textColor, setTextColor] = useState("white")
    const [showDrawer, setShowDrawer] = useState(false);
    const[showBanner, setShowBanner] = useState(true);

    // Track in google analytics whether pdf is clicked
    const handlePdfClick = () => {
        if (window.gtag) {
            window.gtag('event', 'pdf_open', {
                event_category: 'PDF',
                event_label: 'Use Cases PDF',
                value: 1,
            });
        }
    };

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setShowDrawer(open);
    };

    // Close drawer if window is resized and size is now greater than 991px. 
    const closeDrawerOnResize = () => {
        if (window.innerWidth > 991) {
            setShowDrawer(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = document.scrollingElement.scrollTop;
            let position = 0 - scrolled / 20; // Adjust the rate of movement as needed
            // Limit position to a minimum value of -6rem
            position = Math.max(position, -8);

            // If scroll position is past 600, move navbar downwards again
            if (scrolled > 600) {
                setBackgroundColor("rgba(255,255,255)");
                setBorderColor("rgba(231, 235, 243)");
                setTextColor('#0051B6');
                setShowBanner(false)
                position = position + (scrolled - 600) / 50; // Adjust rate of movement as needed
                position = Math.min(position, 0);
            }
            // Revert settings if user scrolls back up
            else {
                setBackgroundColor("rgba(0, 0, 0, 0)");
                setBorderColor("rgba(231, 235, 243, 0)");
                setTextColor("white");
                setShowBanner(true)
            }

            /// Update navigation link pseudo-elements
const navigationLinks = document.querySelectorAll('.navigation-link');
navigationLinks.forEach(link => {
    if (scrolled > 600) {
        link.classList.add('scroll-background');
    } else {
        link.classList.remove('scroll-background');
    }
});

            // Set the new position
            setPos(position);
        };

        const handleResize = () => {
            closeDrawerOnResize();
            if (window.innerWidth > 991) {
                handleScroll()
                document.addEventListener("scroll", handleScroll);
            } else {
                // Fix position of moving nav bar. 
                setPos(0);
                document.removeEventListener("scroll", handleScroll);
            }
        };

        // Initial setup
        handleResize();

        // Event listeners for scroll and resize
        window.addEventListener("resize", handleResize);

        // Clean up event listeners on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
            document.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className='section-navigation'>
            <div className='navigation_component'>
                {/* Conditional rendering based on showDrawer */}
                {showBanner && (
                    <div
                        className='banner'
                        style={{
                            transform: `translate3d(0px, ${pos}rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                            transformStyle: 'preserve-3d',
                        }}
                    >
                        <a href="/How_hoppa_can_help_your_organisation.pdf" target="_blank" rel="noopener noreferrer" style={{ color: `${textColor}`, textDecoration: 'none' }} onClick={handlePdfClick}>
                            <FontAwesomeIcon size='lg' icon={faFileContract} className='me-2' />
                            Click here to download our Use Cases Pack and discover what you could save.
                            <FontAwesomeIcon size='sm' icon={faArrowUpRightFromSquare} className='ms-2' />
                        </a>

                    </div>
                )}
                <div
                    className='navigation-wrap w-nav'
                    style={{
                        transform: `translate3d(0px, ${pos}rem, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                        transformStyle: 'preserve-3d',
                        backgroundColor: `${backgroundColor}`,
                        borderColor: `${borderColor}`
                    }}
                >
                    <div className='container-navigation'>
                        <div className='navigation_link-wrapper'>
                            <a href="/" className='logo-link'>
                                <h1 className="logo" style={{ color: `${textColor}` }}>hoppa</h1>
                            </a>
                            <nav className='navigation_menu-wrap'>
                                <ul className='navigation-list'>
                                    <li key={1} className='navigation-link' style={{ color: `${textColor}` }}>
                                        <ScrollLink activeClass="navigation-active" spy to="about">
                                            About
                                        </ScrollLink>
                                    </li>
                                    {/*<li key={2} className='navigation-link' style={{ color: `${textColor}` }}>
                                        <ScrollLink activeClass="navigation-active" spy to="features">
                                            Features
                                        </ScrollLink>
                                    </li>*/}
                                    <li key={2} className='navigation-link' style={{ color: `${textColor}` }}>
                                        <ScrollLink activeClass="navigation-active" spy to="solutions">
                                            Solutions
                                        </ScrollLink>
                                    </li>
                                    <li key={3} className='navigation-link' style={{ color: `${textColor}` }}>
                                        <ScrollLink activeClass="navigation-active" spy to="features">
                                            Features
                                        </ScrollLink>
                                    </li>
                                    <li key={4} className='navigation-link' style={{ color: `${textColor}` }}>
                                    <ScrollLink activeClass="navigation-active" spy to="benefits">
                                            Benefits
                                        </ScrollLink>
                                    </li>
                                    <li key={5} className='navigation-link' style={{ color: `${textColor}` }}>
                                        <ScrollLink activeClass="navigation-active" spy to="contact">
                                            Contact
                                        </ScrollLink>
                                    </li>

                                </ul>
                            </nav>
                        </div>
                        <div className='navigation_button-wrapper'>
                            <LoginButton/>
                            {/*<LogoutButton/>*/}
                        </div>
                        <div className='navigation_menu-icon'>
                            <IconButton
                                color="inherit"
                                onClick={showDrawer ? toggleDrawer(false) : toggleDrawer(true)}
                            >
                                {showDrawer ?
                                    <CloseIcon fontSize="large" /> :

                                    <MenuIcon fontSize="large" />
                                }
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className='navigation_menu-pulldown'>
                <Drawer
                    anchor='top'
                    open={showDrawer}
                    onClose={toggleDrawer(false)}
                    sx={{ width: 'auto', zIndex: 10, transform: 'translate3d(0px, 80px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)' }}
                >
                    <Box
                        sx={{ width: '90%' }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        {['About', 'Solutions', 'Features', 'Benefits', 'Contact'].map((text, index) => (
                            <Row key={text} className='navigation_menu-pulldown-item border-bottom'>
                            {text === 'Use Cases' ? (
                                <a  
                                    href="/How_hoppa_can_help_your_organisation.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none', color:'inherit' }}
                                    onClick={handlePdfClick}
                                >
                                    {text}
                                </a>
                            ) : (
                                <ScrollLink
                                    activeClass="navigation-active"
                                    spy
                                    to={text.toLowerCase()}
                                    onClick={toggleDrawer(false)}
                                >
                                    {text}
                                </ScrollLink>
                            )}
                            <Divider />
                        </Row>

                        ))}
                        <div className='row justify-content-start'>
                            <div className="navigation_menu-pulldown-button">
                                <LoginButton/>
                            </div>
                        </div>

                    </Box>
                    <div className='banner-mobile'>
                        <a href="/How_hoppa_can_help_your_organisation.pdf" target="_blank" rel="noopener noreferrer" style={{ color: `${textColor}`, textDecoration: 'none' }} onClick={handlePdfClick}>
                            <FontAwesomeIcon size='lg' icon={faFileContract} className='me-2' />
                            Click here to download our Use Cases Pack and discover what you could save.
                            <FontAwesomeIcon size='sm' icon={faArrowUpRightFromSquare} className='ms-2' />
                        </a>
                    </div>
                </Drawer>
            </div>
        </div>
    )
}

export default NavBar;