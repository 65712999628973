import React, { useState, useEffect, useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from "@stripe/react-stripe-js";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
const STRIPE_PROMISE = process.env.REACT_APP_STRIPE_PROMISE

const stripePromise = { STRIPE_PROMISE };

const Checkout = ({fileCount, handleSessionCommit }) => {
    const payload = { "fileCount": fileCount };
    const [isComplete, setIsComplete] = useState(false);
    const fetchClientSecret = useCallback(() => {
        
        console.log('Request Payload:', payload); // Log the payload
        return axios.post(
            `${API_BASE_URL}/api/CreateCheckoutSession`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
                }
            }
        )
        .then((res) => {
            console.log('Response:', res.data); // Log the response
            return res.data.clientSecret;
        })
        .catch((error) => {
            console.error('Error:', error); // Log any errors
            throw error; // Re-throw the error to propagate it further
        });
    }, [fileCount]);


  const handleComplete = () => {

    setIsComplete(true)

    handleSessionCommit()

  }
    

  const options = { fetchClientSecret, onComplete: handleComplete };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");

    if (sessionId) {
      fetch(`/CheckoutSessionStatus?session_id=${sessionId}`)
        .then((res) => res.json())
        .then((data) => {
          setStatus(data.status);
          setCustomerEmail(data.customer_email);
        });
    }
  }, []);

  if (status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (status === "complete") {
    return (
      <section id="success">
        <p>
          We appreciate your business! A confirmation email will be sent to{" "}
          {customerEmail}.
          If you have any questions, please email{" "}
          <a href="mailto:orders@example.com">orders@example.com</a>.
        </p>
      </section>
    );
  }

  return null;
};

export default Checkout;
