import React, { useState, useEffect } from "react";
import { Typography, Box, Stack, FormControl, Select, MenuItem } from '@mui/material';
import axios from "axios";
import { useParams } from "react-router-dom";
import { Controlled as ControlledEditor } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/python/python';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SessionSettings = ({ promptSettings = {}, handleSetPromptSettings }) => {
    const { organisation, workspace } = useParams(); // get organisation and workspace from URL
    const [code, setCode] = useState(`{\n  "key": "value"\n}`);
    const [tab, setTab] = useState('Description');

    const config = {
        headers: {
            'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
        }
    };

    useEffect(() => {
        loadSettings();
    }, []);

    const loadSettings = async () => {
        try {
            const response = await axios.post(
                `${API_BASE_URL}/api/GetPromptSettings?organisation=${organisation}&workspace=${workspace}`,
                {},
                config
            );
            console.log(response.data)

            handleSetPromptSettings(response.data);
        } catch (error) {
            console.error("Error loading settings:", error);
        }
    };

    const handleEditorChange = (editor, data, value) => {
        setCode(value);
    };

    const handleChange = (event) => {
        setTab(event.target.value);
    };

    return (
        <Box sx={{ margin: '1.5rem', flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <div style={{ width: '100%' }}>
                <Box
                    sx={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        height: '50px'
                    }}
                >
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#5C5DD8', flexGrow: 1 }}>
                        Settings
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                        <FormControl size="small">
                            <Select value={tab} onChange={handleChange}>
                                <MenuItem value='description'>Description</MenuItem>
                                <MenuItem value='search'>Search</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </Box>
            </div>
            <div>
                <Stack direction='row' spacing={2} style={{ width: '100%' }}>
                    <div style={{ flex: 1 }}>
                        <h6>System Message</h6>
                        <ControlledEditor
                            value={promptSettings.description?.systemMessage || ""}
                            onBeforeChange={handleEditorChange}
                            options={{
                                theme: 'material',
                                lineNumbers: true,
                                indentUnit: 2,
                                tabSize: 2,
                                smartIndent: true,
                                lineWrapping: true,
                                matchBrackets: true,
                                autoCloseBrackets: true,
                            }}
                        />
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6>User Message</h6>
                        <ControlledEditor
                            value={promptSettings.description?.userMessage || ""}
                            onBeforeChange={handleEditorChange}
                            options={{
                                theme: 'material',
                                mode:'python',
                                lineNumbers: true,
                                indentUnit: 2,
                                tabSize: 2,
                                smartIndent: true,
                                lineWrapping: true,
                                matchBrackets: true,
                                autoCloseBrackets: true,
                            }}
                        />
                    </div>
                </Stack>
            </div>
        </Box>
    );
};

export default SessionSettings;
