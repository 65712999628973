import React, {useState, useEffect} from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import {Form } from 'react-bootstrap';


const CustomForm = ({ status, message, onValidated }) => {

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [source, setSource] = useState('');
    const [validated, setValidated] = useState(false);
    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [sourceError, setSourceError] = useState(null);


    useEffect(() => {
        if(status === "success") clearFields();
      }, [status])
    
      const clearFields = () => {
        setName('');
        setEmail('');
        setSource('');
      }
    
    
    const handleSubmit = (e) => {
        e.preventDefault();

        // Trigger form validation. 
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      if (!name) {setNameError('Cannot be blank')}
      if (!email) {setEmailError('Cannot be blank')} 
      else if (email.indexOf("@") < 0) {setEmailError('Invalid email address')}
      if (!source) {setSourceError('Cannot be blank')}
      console.log('Error')
      return;
      
    }
        email &&
            name &&
            source &&
            email.indexOf("@") > -1 &&
            onValidated({
                MERGE0: email,
                MERGE1: name,
                MERGE3: source,
                MERGE5: 'Register interest'
            });
    }

    return (
        <Form
            noValidate
            validated={validated}
            className="mc__form"
            onSubmit={(e) => handleSubmit(e)}
        >
            {status === "sending" && (
                <div className="mc__alert mc__alert--sending">
                    sending...
                </div>
            )}
            {status === "error" && (
                <div
                    className="mc__alert mc__alert--error"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    className="mc__alert mc__alert--success"
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}

            {status !== "success" ? (
                <div className="mc__field-container">
                    <input
                        className={'custom-input ' + (validated ? 'custom-input-validated' : '')}
                        type="text"
                        required
                        placeholder='Name'
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                    <Form.Control.Feedback type="invalid">
                        {nameError}
                    </Form.Control.Feedback>
                    <input
                        className={'custom-input ' + (validated ? 'custom-input-validated' : '')}
                        type="Email"
                        required
                        placeholder='Email'
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                    <Form.Control.Feedback type="invalid">
                        {emailError}
                    </Form.Control.Feedback>
                    <select
                        className={'custom-input ' + (validated ? 'custom-input-validated' : '')}
                        required
                        onInput={(e) => setSource(e.target.value)}
                    >
                        <option value="" disabled selected hidden>How did you hear about us?</option>
                        <option value="Search engine (Google, Bing etc.)">Search engine (Google, Bing etc.)</option>
                        <option value="Recommended by friend or colleague">Recommended by friend or colleague</option>
                        <option value="Social media (LinkedIn, Instagram etc.)">Social media (LinkedIn, Instagram etc.)</option>
                        <option value="Blog or publication">Blog or publication</option>
                        <option value="Other">Other</option>
                    </select>
                    <Form.Control.Feedback type="invalid">
                        {sourceError}
                    </Form.Control.Feedback>
                </div>
            ) : ""}
            {
                status === 'success' ? '' :
                    <button className='btn btn-secondary m-0 mt-3'>Register</button>
            }
        </Form>
    );
};

const Subscribe = props => {

    const postUrl = `https://searchparty.us18.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

    return (
        <div className="mc__form-container">
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>
    );
};

export default Subscribe;