import React, { useState, useEffect } from 'react';

const TypingEffect = () => {
  const phrases = ['Streamline information handover', 'Spot gaps in your data', 'Comply with standards & regulation', 'Take control of incoming information', 'Find answers buried deep in your files'];
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpacity(0); // Fade out
    }, 2000); // Adjust fade out duration as needed

    return () => clearTimeout(timeoutId);
  }, [currentPhraseIndex]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpacity(1); // Fade in
      setCurrentPhraseIndex((currentPhraseIndex + 1) % phrases.length);
    }, 2500); // Adjust fade in and switch duration as needed

    return () => clearTimeout(timeoutId);
  }, [currentPhraseIndex, phrases]);

  return (
    <div style={{ opacity, transition: 'opacity 1s ease-in-out' }}>
      <h3 className='typing-text'>{phrases[currentPhraseIndex]}</h3>
    </div>
  );
};

export default TypingEffect;
