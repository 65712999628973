import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import HeaderBar from './shared/HeaderBar';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import FooterBar from './shared/FooterBar';

const NotFoundPage = () => {

    const navigate = useNavigate(); // Initialize the useNavigate hook
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);


    const goHome = () => {
        navigate('/'); // Navigate to the homepage
    };

    // For conditionally styling components based on window width. 
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <div className='full-width-error-section'>
                <Row className='ms-2 me-0'>
                    <div className='p-2'>
                        <h1 className="logo-blue">
                            <a href="/" className='logo-link'>
                                hoppa
                            </a>
                        </h1>

                    </div>
                </Row>
                <Container className="h-100">

                    <Row className="align-items-center h-100">

                        <Col className='error-page-text '>
                            <h1>Oops! You're lost.</h1>
                            <p>The page you are looking for doesn't exist. How you got here is a mystery. Click the button below to go back to the homepage.</p>
                            <button className='btn btn-primary mb-05' onClick={goHome}>
                                Return home
                            </button>
                        </Col>
                        <Col xs={{ order: windowWidth < 991 ? 'first' : 'last' }} s={12} md={6}>
                            <img
                                src={`${process.env.PUBLIC_URL}/404_error.png`}
                                alt="404"
                                className='img-fluid mt-4 mb-4'
                            />
                        </Col>

                    </Row>
                </Container>

            </div>
            <footer className='m-0'>
                <FooterBar />
            </footer>
        </div>
    );
};

export default NotFoundPage;
