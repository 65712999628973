import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { TextField, IconButton } from '@mui/material';

const EditableTitle = ({defaultTitle, handleSetTitle}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(defaultTitle); // Default title

  // Update title if the defaultTitle prop changes
  useEffect(() => {
    setTitle(defaultTitle);
  }, [defaultTitle]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    handleSetTitle(e.target.value)
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false); // Save and exit edit mode on Enter
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {isEditing ? (
        <TextField
          value={title}
          onChange={handleTitleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          autoFocus
          size="normal"
          variant="standard"
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {title}
          <IconButton
            size="small"
            onClick={handleEditClick}
            style={{ marginLeft: '8px' }}
            title='Edit session name'
          >
            <EditIcon fontSize="small"/>
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default EditableTitle;
