import React, { useState, useEffect, useMemo } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Container, Row, Col } from 'react-bootstrap';
import { Box } from '@mui/material';

const ResultsStatusBar = ({fileCount}) => {

    return (
      <Box style={{ height: '120px', minHeight: '100px' }}>
            <BarChart
              layout="horizontal"
              colors={['#0B0A07', '#06D6A0', '#FFD166', '#EF476F', '#CCD6EB']}
              height={120}
              leftAxis={null}
              bottomAxis={null}
              tooltip={{ trigger: 'item' }}
              slotProps={{
                bar: {
                  rx: 10,
                  ry: 20,
                },
              }}
              series={[
                { data: [fileCount.fail], stack: 'A', label: 'Failed files' },
                { data: [fileCount.pass], stack: 'A', label: 'Fully classified' },
                { data: [fileCount.warning], stack: 'A', label: 'Some warnings' },
                { data: [fileCount.error], stack: 'A', label: ' Some errors ' },
                { data: [(fileCount.total - fileCount.error - fileCount.warning - fileCount.pass - fileCount.fail)], stack: 'A', label: 'Awaiting processing' },
              ]}
            />
            </Box>
    );
};

export default ResultsStatusBar;