import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import FileCard from '../shared/FileCard';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Stack, Typography, Box } from '@mui/material';
import { useParams } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

function FileUpload({ sessionId, triggerAlert, handleSetFileCount, allFiles, handleSetSourceFiles, handleUpdateDataSource }) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [openCall, setOpenCall] = useState(false)
  const { organisation, workspace } = useParams(); // get organisation and workspace from URL

  useEffect(() => {
    console.log('All files are', allFiles)
    // Filter the allFiles array to match the selectedTab source
    const filteredFiles = allFiles.filter(file => file.source === 'upload');
    console.log(filteredFiles)
    // Set the filtered list to sourceFiles state
    // Pass any existing uploaded files down into the component, if they exist. 
    setUploadedFiles(filteredFiles)
  }, []);

  useEffect(() => {
    const counts = {
      countCompleted: uploadedFiles.filter(file => file.status === 'completed').length,
      countProcessing: uploadedFiles.filter(file => file.status === 'processing').length,
      countFailed: uploadedFiles.filter(file => file.status === 'failed').length
    };

    handleSetFileCount(counts.countCompleted)
    // handleSetSourceFiles(uploadedFiles, 'upload')
    console.log('Uploaded files are', uploadedFiles)
    handleUpdateDataSource('File / Folder Upload', 'File / Folder Upload', uploadedFiles)

    console.log('In component file count is', uploadedFiles.length)

  }, [uploadedFiles]);

  const updateFileStatus = (fileName, status) => {
    setUploadingFiles(prevUploadingFiles => {
      return prevUploadingFiles.map((uploadingFile) => {
        if (uploadingFile.name === fileName) {
          return {
            ...uploadingFile,
            status: status
          };
        }
        return uploadingFile;
      });
    });
  };

  const rowClick = () => {
    console.log('Row clicked')
  }

  const fileUpload = async (files) => {
    console.log('Session GUID is', sessionId);
    setIsUploading(true)
    setOpenCall(true)

    // Check for duplicate files.
    const uniqueFiles = files.filter(file => !uploadedFiles.some(upFile => upFile.name === file.file.name));
    if (uniqueFiles.length < files.length) {
      const skippedFiles = files.length - uniqueFiles.length;
      triggerAlert(`Duplicate files found. ${skippedFiles} files skipped.`, 'warning');
    }

    console.log('New files are', uniqueFiles);

    const filesWithSize = uniqueFiles.map(file => ({
      name: file.file.name,
      lastModifiedDate: file.file.lastModifiedDate,
      size: file.file.size,
      status: 'new',
    }));

    setUploadingFiles([...uploadingFiles, ...filesWithSize]);

    // Helper function to upload a single file
    const uploadSingleFile = async (file) => {
      console.log('File to be uploaded is', file);

      // Update the status to 'processing'
      updateFileStatus(file.name, 'processing')

      const formData = new FormData();
      formData.append('file', file);
      formData.append('organisation', organisation);
      formData.append('workspace', workspace);
      formData.append('sessionId', sessionId);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadingFiles(prevUploadingFiles => {
            const updatedFiles = prevUploadingFiles.map(uploadingFile => {
              if (uploadingFile.name === file.name) {
                return { ...uploadingFile, progress: percentCompleted };
              }
              return uploadingFile;
            });
            return updatedFiles;
          });
          console.log(`${progressEvent.loaded}kb of ${progressEvent.total}kb | ${percentCompleted}%`);
        },
      };

      try {
        const response = await axios.post(`${API_BASE_URL}/api/FileUploadTrigger`, formData, config);
        console.log("Upload successful. Server response:", response.data);

        // Update status to 'completed'
        updateFileStatus(file.name, 'completed')

        // Add file to the state only after a successful upload
        setUploadedFiles(prevUploadedFiles => [
          ...prevUploadedFiles,
          { name: file.name, size: file.size, status: 'completed', lastModifiedDate: file.lastModifiedDate, progress: 100 }
        ]);

      } catch (error) {

        updateFileStatus(file.name, 'failed')
        setUploadedFiles(prevUploadedFiles => [
          ...prevUploadedFiles,
          { name: file.name, size: file.size, status: 'failed', lastModifiedDate: file.lastModifiedDate, progress: 100 }
        ]);

        console.error("Error during upload:", error);

        // Update status to 'failed'
        updateFileStatus(file.name, 'failed')
      }
      finally {
        // Remove the file from the uploadingFiles array after upload completes (success or fail)
        setUploadingFiles((prevUploadingFiles) =>
          prevUploadingFiles.filter(uploadingFile => uploadingFile.name !== file.name)
        );

        triggerAlert(`Upload complete: ${file.name}.`)

      }
    };

    // Batch upload files in groups of 5
    for (let i = 0; i < uniqueFiles.length; i += 5) {
      const fileBatch = uniqueFiles.slice(i, i + 5).map(fileObj => fileObj.file);

      console.log(`Uploading batch ${i / 5 + 1}:`, fileBatch);

      // Use Promise.all to upload the batch of files in parallel
      await Promise.all(fileBatch.map(uploadSingleFile));

      console.log(`Batch ${i / 5 + 1} upload complete.`);
    }

    setIsUploading(false)
    setOpenCall(false)
  };


  const handleDeleteFile = (fileName) => {

    console.log('Attemping to delete', fileName)

    setOpenCall(true)

    // Find the file with the specified fileName in uploadedFiles
    const fileToDelete = uploadedFiles.find(file => file.name === fileName);

    console.log(fileToDelete)

    if (fileToDelete && fileToDelete.status === 'failed') {
      // File with 'failed' status found, proceed with deletion from the frontend only. 

      // Remove the file from uploadedFiles
      const updatedFiles = uploadedFiles.filter((file) => file.name !== fileName);
      setUploadedFiles(updatedFiles);
      setOpenCall(false);

      return
    }

    // Else continue with post to Fuction app. 
    const headers = {
      'Content-Type': 'application/json', // Set the Content-Type header to JSON
      'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
      // Add any other headers as needed
    };

    // Send POST request to backend API for file deletion
    axios.post(`${API_BASE_URL}/api/DeleteFileTrigger?sessionId=${sessionId}`, {
      fileNames: fileName, organisation: organisation, workspace: workspace, sessionId: sessionId
    }, { headers }
    )
      .then((response) => {
        console.log("Delete successful. Server response:", response.data);

        console.log('Files before updating the array', uploadedFiles)

        setUploadedFiles(prevUploadedFiles => {
          const updatedFiles = prevUploadedFiles.filter((file) => file.name !== fileName);
          return updatedFiles;
        })
        console.log('Files after updating the array', uploadedFiles)
        setOpenCall(false)
        triggerAlert('Files deleted successfully.')
      }
      )
      .catch((error) => {

        console.error("Error during delete. Server response:", error);

        triggerAlert('Error during delete.', 'error');

        setUploadedFiles(prevUploadedFiles => {
          const updatedFiles = prevUploadedFiles.filter((file) => file.name !== fileName);
          return updatedFiles;
        })
        setOpenCall(false)

        // // Update the file count after deletion
        // handleSetFileCount(countCompletedFiles())
      })

  };

  const handleDeleteAllFiles = () => {

    console.log('Attemping to delete all uploaded files to session')

    setOpenCall(true)

    // Else continue with post to Fuction app. 
    const headers = {
      'Content-Type': 'application/json', // Set the Content-Type header to JSON
      'x-functions-key': process.env.REACT_APP_FUNCTIONAPP_KEY  // Add the function key as a header
      // Add any other headers as needed
    };

    // Send POST request to backend API for file deletion
    axios.post(`${API_BASE_URL}/api/DeleteAllFilesTrigger`, {
      organisation: organisation, workspace: workspace, sessionId: sessionId
    }, { headers }
    )
      .then((response) => {
        console.log("Delete successful. Server response:", response.data);
        setUploadedFiles([])
        setOpenCall(false)
        triggerAlert('File delete successful.')
      }
      )
      .catch((error) => {
        console.error("Error during delete. Server response:", error);
        triggerAlert('Error during delete. Please refresh the page', 'error');
        setOpenCall(false)
      })


  }

  const traverseFileTree = async (item, updatedFiles, parentFolderName = '') => {
    if (item.isFile) {
      const file = await new Promise((resolve) => item.file(resolve));
      updatedFiles.push({ file, folder: parentFolderName });
    } else if (item.isDirectory) {
      const directoryReader = item.createReader();
      const folderName = parentFolderName ? `${parentFolderName}/${item.name}` : item.name;

      const entries = await new Promise((resolve) => directoryReader.readEntries(resolve));

      for (let j = 0; j < entries.length; j++) {
        await traverseFileTree(entries[j], updatedFiles, folderName);
      }
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const files = Array.from(e.target.files);
    const updatedFiles = [];

    // Collect all files first
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      updatedFiles.push({ file, folder: '' });
    }

    // Call fileUpload once after the loop
    fileUpload(updatedFiles);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const droppedItems = e.dataTransfer.items;
    const updatedFiles = [];

    for (let i = 0; i < droppedItems.length; i++) {
      const item = droppedItems[i].webkitGetAsEntry();

      if (item) {
        await traverseFileTree(item, updatedFiles);
      }
    }
    fileUpload(updatedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setSelectedFiles([]); // Clear selectedFiles after processing
  };

  return (
    <>
      <div style={{ width: '100%' }}>
        <Box
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
            mb: 2,
            height: '50px'
          }}
        >
          {/* Title */}
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#5C5DD8', flexGrow: 1 }}>
            Configure File / Folder Upload
          </Typography>
          <Stack direction="row" spacing={1} sx={{
            alignItems: "center",
          }}>
            <button className='btn btn-secondary' disabled={uploadedFiles.length === 0} onClick={() => handleDeleteAllFiles()}>
              Clear all
            </button>
          </Stack>
        </Box>
      </div>

      <div style={{ display: 'flex', flexGrow: 1, width: '100%', marginBottom: '60px'}}>
        <Stack direction="row" spacing={2} sx={{ flexGrow: 1, width: '100%', justifyContent: 'space-between' }}>
          <div
            className="dashed-box mt-0"
            style={{ display: 'flex', width: '30%', alignItems: 'center', justifyContent: 'center' }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            {isUploading ?
              (
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress />
                  <p>Uploading {uploadingFiles.length} file(s)</p>
                </div>
              )
              : (
                <div className="file-upload-drop-area" style={{ width: '100%' }}>
                  <FontAwesomeIcon icon={faCloudArrowUp} className="fa-3x mb-2" style={{ color: 'silver' }} />
                  <p>
                    Drag and drop a folder here or{' '}
                    <label htmlFor="file-upload-input" className="file-upload-label">
                      <u>choose files</u>
                    </label>
                  </p>
                  <input
                    key={uploadedFiles.length} // Reset the key whenever uploadedFiles change
                    type="file"
                    id="file-upload-input"
                    className="file-upload-input hidden"
                    onChange={handleFileChange}
                    multiple
                  />
                </div>
              )}

          </div>
          <div className="file-list" style={{ width: '100%', maxHeight: 'calc(100vh - 260px)', overflowY: 'auto' }}>
            {/* Display uploaded files using FileCard component */}
            {uploadedFiles.map((file, index) => (
              <FileCard
                key={index}
                fileName={file.name}
                fileType={file.name.split('.').pop()}
                total={file.size}
                status={file.status}
                uploadProgress={uploadProgress}
                handleDelete={handleDeleteFile} // Pass handleDeleteFile function to handle file deletion
              />
            ))}
          </div>
        </Stack>


        {/* <div>Total: {uploadedFiles.length} files ({fileCount.countCompleted} completed, {fileCount.countProcessing} processing, {fileCount.countFailed} failed)</div> */}

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openCall}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    </>
  );
}

export default FileUpload;
