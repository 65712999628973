import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Form } from 'react-bootstrap';
import { useUserStandard } from '../../contexts/InitiateContexts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faTimesCircle, faTable } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form'


const CodeValueEntry = ({ show, onHide, onSave, codePartIdentifier, baseStandard }) => {
  const { userStandard, dispatch } = useUserStandard();
  const [tableData, setTableData] = useState([]);
  const methods = useForm()
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    // Retrieve saved data from sessionStorage when the component mounts
    const savedData = sessionStorage.getItem(codePartIdentifier);

    if (savedData) {
      setTableData(JSON.parse(savedData));
    } else {
      // Set default data if no saved data is found
      setTableData([
        { code: 'C+P', description: 'Or type your codes' },
      ]);
    }
  }, [codePartIdentifier]);

  useEffect(() => {
    if (baseStandard) {
      // Retrieve saved data from sessionStorage when the component mounts
      const savedData = sessionStorage.getItem(codePartIdentifier);

      if (savedData) {
        setTableData(JSON.parse(savedData));
      } else {
        // Set default data if no saved data is found
        const defaultData =
          baseStandard.classifiers
            .find((classifier) => classifier.id === codePartIdentifier)?.data || [];

        setTableData(defaultData);
      }
    }
  }, [codePartIdentifier, baseStandard]);


  // useEffect to update tableData when codePartIdentifier changes
  useEffect(() => {
    // Update tableData when codePartIdentifier changes
    const savedData = sessionStorage.getItem(codePartIdentifier);
    if (savedData) {
      setTableData(JSON.parse(savedData));
    }
  }, [codePartIdentifier]);

  const addRow = () => {
    setTableData((prevData) => [...prevData, { code: '', description: '' }]);
  };

  const deleteRow = (index) => {
    if (tableData.length > 1) {
      setTableData((prevData) => prevData.filter((_, i) => i !== index));
    }
  };

  const updateRow = (index, field, value) => {
    setTableData((prevData) => {
      const newData = [...prevData];
      newData[index][field] = value;
      return newData;
    });
  };

  const handlePasteFromExcel = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData('text');

    // Parse the pasted data and update the tableData state
    const parsedRows = pastedData.split('\n').map((row) => {
      const [code, description] = row.split('\t');

      // Clean the description (remove unwanted characters)
      const cleanedDescription = description.replace(/[\r\n]/g, '');

      return { code: code || '', description: cleanedDescription || '' };
    });

    setTableData(parsedRows);
  };

  // const handleSave = () => {
  //   const formData = methods.getValues(); // Get form data
  //   const updatedSessionData = { ...userStandard.tableData, [codePartIdentifier]: formData };
  //   sessionStorage.setItem(codePartIdentifier, JSON.stringify(updatedSessionData));
  //   onSave(codePartIdentifier, formData);
  //   onHide();
  // };

  const handleSave = (event) => {

    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      // Update sessionStorage with the latest data
      const updatedSessionData = { ...userStandard.tableData, [codePartIdentifier]: tableData };
      sessionStorage.setItem(codePartIdentifier, JSON.stringify(updatedSessionData));

      // Call the onSave prop with the codePartIdentifier and the updated tableData
      onSave(codePartIdentifier, tableData);

      methods.reset()

      onHide();
    }
  };

  useEffect(() => {
    // Log the updated userStandard after the state has been updated
    console.log('UserStandard after update: ', userStandard);
  }, [userStandard]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Form
        noValidate validated={validated} onSubmit={handleSave}
      >
        <Modal.Header closeButton>
          <Modal.Title>Code Value Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Table responsive onPaste={handlePasteFromExcel}>
            <thead>
              <tr>
                <th>Code</th>
                <th>Description</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>
                    {/* <Input {...basic_validation} 
                      name='code'
                      id=''
                      value={row.code}
                      onChange={(e) => updateRow(index, 'code', e.target.value)}/> */}
                    <Form.Control
                      required
                      type="text"
                      placeholder="Type code here"
                      value={row.code}
                      onChange={(e) => updateRow(index, 'code', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Cannot be blank.
                    </Form.Control.Feedback>
                  </td>
                  <td>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Type description here"
                      value={row.description}
                      onChange={(e) => updateRow(index, 'description', e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">
                      Cannot be blank.
                    </Form.Control.Feedback>
                  </td>
                  <td style={{ verticalAlign: 'middle', }}>
                    <div style={{ cursor: 'pointer', margin: 'auto', color: 'red' }} onClick={() => deleteRow(index)}>
                      <FontAwesomeIcon title='Remove code value' icon={faTimesCircle} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Button variant="primary" onClick={addRow}>
            Add Row
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="primary" type='submit'>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CodeValueEntry;
