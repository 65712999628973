import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function ConfirmDeleteModal({ show, onHide, fieldName, onDelete }) {
  const [inputValue, setInputValue] = useState('');

  // Check if the input matches the session name to enable the Confirm button
  const isInputMatching = inputValue === fieldName;

  const handleConfirm = () => {
    if (isInputMatching) {
      onDelete();  // Execute the deletion
      onHide();    // Close the modal after deletion
      setInputValue('')
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Deletion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>To confirm the deletion, please type <strong>{fieldName}</strong> below:</p>
        <Form.Group controlId="fieldNameInput">
          <Form.Control
            type="text"
            placeholder="Enter key"
            value={inputValue}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={handleConfirm}
          disabled={!isInputMatching}  // Disable if input doesn't match
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDeleteModal;
