import React, { useState, useEffect, useCallback } from "react";
import { Typography, Box, Divider, CircularProgress } from '@mui/material';
import { Nav, Tab } from 'react-bootstrap';
import CustomiseSessionStandard from "./CustomiseSessionStandard";
import CustomiseSessionEnrichers from "./CustomiseSessionEnrichers";

const Refinery = ({ sessionStandard, setSessionStandard, saveStandard }) => {

    const [selectedTab, setSelectedTab] = useState(1);

    const handleTabSelect = (tab) => {
        setSelectedTab(tab);
    };

    return (

        <Box sx={{ margin: '1.5rem', flexGrow: 1, display: 'flex', flexDirection: 'column', position: 'relative' }}>
            {/* Title and Buttons Area */}
            <div style={{ width: '100%' }}>
                <Box
                    sx={{
                        justifyContent: 'space-between',
                        display: 'flex',
                        alignItems: 'center',
                        mb: 2,
                        height: '50px'
                    }}
                >
                    {/* Title */}
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#5C5DD8', flexGrow: 1 }}>
                        Data Refinery
                    </Typography>

                </Box>
            </div>
            {Object.keys(sessionStandard).length > 0 ? (
                <div style={{ width: '100%' }}>
                    <Tab.Container activeKey={selectedTab} onSelect={handleTabSelect}>
                        <Nav className="nav nav-pills">
                            <Nav.Item>
                                <Nav.Link eventKey={1}>Classifiers</Nav.Link>
                            </Nav.Item>
                            <Divider style={{ margin: '0 2px' }} />
                            <Nav.Item>
                                <Nav.Link eventKey={2}>Enrichers</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Divider style={{ border: '1px solid grey', margin: '8px 0' }} />
                        <Tab.Content>
                            <Tab.Pane eventKey={1}>
                                <CustomiseSessionStandard sessionStandard={sessionStandard} setSessionStandard={setSessionStandard} saveStandard={saveStandard} />
                            </Tab.Pane>
                            <Tab.Pane eventKey={2}>
                                <CustomiseSessionEnrichers sessionStandard={sessionStandard} setSessionStandard={setSessionStandard} saveStandard={saveStandard} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            )
            :
            <CircularProgress/>
            }
        </Box>
    )
}

export default Refinery;