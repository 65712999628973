import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button } from '@mui/material';
import { Modal } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

const BackButton = ({ destinationText, destination, warning = false }) => { // Default warning to false
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleClick = () => {
        // If warning is true, show confirmation modal; otherwise, navigate directly
        if (warning) {
            setShowConfirmation(true);
        } else {
            navigate(destination);
        }
    };

    const handleConfirm = () => {
        navigate(destination);
        setShowConfirmation(false);
    };

    const handleCancel = () => {
        setShowConfirmation(false);
    };

  return (
    <Button
      variant="text"
      startIcon={<ArrowBackIcon />}
      onClick={handleClick}
      style={{
        color: 'gray',
        fontSize: '16px',
        textTransform: 'none',
        fontWeight: 'normal',
        justifyContent: 'start'
      }}
    >
      Back to {destinationText}
    </Button>
  );
    return (
        <>
            <Button
                variant="text"
                startIcon={<ArrowBackIcon />}
                onClick={handleClick}
                style={{
                    color: 'gray',
                    fontSize: '16px',
                    textTransform: 'none',
                    fontWeight: 'normal',
                }}
            >
                Back to {destinationText}
            </Button>

            {/* Confirmation Modal */}
            <Modal show={showConfirmation} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Any unsaved changes will be lost. Do you want to continue?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleConfirm}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BackButton;
