import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, InputGroup } from 'react-bootstrap';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Card, Badge } from 'react-bootstrap';
import { Link as ScrollLink } from "react-scroll";



const RoiCalculator = () => {
  const [numFiles, setNumFiles] = useState(1000);
  const [timePerFile, setTimePerFile] = useState(15);
  const [hourlyRate, setHourlyRate] = useState(50);
  const [manualCost, setManualCost] = useState(0);
  // Adjust price per file here
  const [pricePerDeliverable, setPricePerDeliverable] = useState(2.5);
  const [totalCostSaving, setTotalCostSaving] = useState(0);
  const [totalTimeSaving, setTotalTimeSaving] = useState(0);
  const [roi, setROI] = useState(0);

  useEffect(() => {
    // Calculate time to perform manually
    const calculatedTotalTimeSaving = Math.max(numFiles * (timePerFile/60), 0)
    setTotalTimeSaving(calculatedTotalTimeSaving);

    // Calculate the cost of doing manually
    const calculatedManualCost = Math.max(calculatedTotalTimeSaving * hourlyRate, 0);
    setManualCost(calculatedManualCost);

    const ourPrice = (pricePerDeliverable * numFiles);

    // Calculate the total cost saving and ROI
    const calculatedTotalCostSaving = calculatedManualCost - ourPrice;
    setTotalCostSaving(calculatedTotalCostSaving);

    
    
    const calculatedROI = (calculatedManualCost !== 0) ? (calculatedTotalCostSaving / ourPrice) * 100 : 0;
    setROI(calculatedROI);

  }, [numFiles, timePerFile, hourlyRate, pricePerDeliverable]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

  return (
    <div>
    <Row>
        <Col md={12} lg={6} className='mb-4'>
        <Row>
          {/*<Col xs={6}>*/}
            <p className='small-font grey-font mt-2 mb-1'>Number of files</p>
            <div className='input-number_box'> {/*style={{ width:"200px" }}*/}
              <IconButton className='ps-0' onClick={() => setNumFiles(numFiles - 50)}>
                <RemoveCircleOutlineIcon />
              </IconButton>
              <Form.Control
                className='input-number'
                value={numFiles}
                onChange={(e) => setNumFiles(e.target.value)}
              />
              <IconButton onClick={() => setNumFiles(parseInt(numFiles) + 50)}>
                <AddCircleOutlineIcon />
              </IconButton>
            </div>
         {/* </Col>*/}
          {/*<Col xs={6} className="text-end">
          <p className='small-font grey-font mt-2 mb-0'>Our Price</p>
          <p style= {{fontSize:"35px", paddingTop:"2px"}} className="mt-1 green-font">
              <b>
                £{numberWithCommas(numFiles > 399 ? (pricePerDeliverable * numFiles).toFixed(0) : (pricePerDeliverable * numFiles).toFixed(2))}
              </b>
            </p>
          </Col>*/}
        </Row>
          <input
            type='range'
            id='files'
            value={numFiles}
            onChange={(e) => setNumFiles(e.target.value)}
            min={50}
            max={1500}
            step={50}
            className='win10-thumb'
          />
          <Row>

    <p className='small-font grey-font mt-2 mb-1'>Manual time to process a file</p>
    <div className='input-number_box'>
      <IconButton className='ps-0' onClick={() => setTimePerFile(timePerFile - 1)}>
        <RemoveCircleOutlineIcon />
      </IconButton>
      <InputGroup style={{ display: 'flex', alignItems: 'center' }}>
        <Form.Control
          className='input-number'
          value={timePerFile}
          onChange={(e) => setTimePerFile(parseInt(e.target.value, 10))}
        />
        <InputGroup.Text className='input-number_units'>mins</InputGroup.Text>
      </InputGroup>
      <IconButton onClick={() => setTimePerFile(parseInt(timePerFile) + 1)}>
        <AddCircleOutlineIcon />
      </IconButton>
    </div>

  
</Row>

          <input
            type='range'
            id='files'
            value={timePerFile}
            onChange={(e) => setTimePerFile(e.target.value)}
            min={1}
              max={60}
              step={1}
            className='win10-thumb'
          />
          <Row>
            {/*<Col xs={6}>*/}
          <p className='small-font grey-font mt-2 mb-1'>Hourly staff rate</p>
          <div className='input-number_box'> {/*style={{ width:"200px" }}*/}
            <IconButton className='ps-0' onClick={() => setHourlyRate(hourlyRate - 5)}>
              <RemoveCircleOutlineIcon />
            </IconButton>
            <InputGroup style={{display: 'flex', alignItems: 'center'}}>
            <InputGroup.Text className='input-number_units'>£</InputGroup.Text>
              <Form.Control
                className='input-number'
                value={hourlyRate}
                onChange={(e) => setHourlyRate(parseInt(e.target.value, 10))}
              />
            </InputGroup>
            <IconButton onClick={() => setHourlyRate(parseInt(hourlyRate) + 5)}>
              <AddCircleOutlineIcon />
            </IconButton>
          </div>
          {/*</Col>*/}
         {/*<Col xs={6}>
            {/*<div className="text-end">
              <p className='small-font grey-font mt-2 mb-0'>Manual cost</p>
              <p style={{ fontSize: "30px", paddingTop:"2px" }} className="mt-2 red-font">
                <b>
                  £{numberWithCommas(manualCost.toFixed(0))}
                </b>
              </p>
            </div>*/}
          {/*</Col>*/}
          </Row>
      </Col>
      <Col xs={12} lg={6}>
      <Card className="calculated-price mt-3">
        <div className="orange-background text-center white-font">
            <h3>We could help you avoid...</h3>
        </div>
        <Card.Body style={{ textAlign: "center" }}>
        
        {/*<b>Labour Saving</b>
        <h1><b>£{numberWithCommas(numFiles > 399 ? manualCost.toFixed(0) : manualCost.toFixed(2))}</b></h1>*/}
        <h3 className="mt-3"><b>Manual Effort</b></h3>
        <h3 className='headline-figure'>{numberWithCommas(totalTimeSaving > 100 ? totalTimeSaving.toFixed(0) : totalTimeSaving.toFixed(1))}hrs</h3>
        <h3 className="mt-4"><b>Manual Cost</b></h3>
        <h3 className='headline-figure'>£{numberWithCommas(manualCost.toFixed(0))}</h3>
        <button className="btn btn-primary">
                    <ScrollLink spy to="contact">
                      Request a demo
                    </ScrollLink></button>
        </Card.Body>
      </Card>
      </Col>
    </Row>
    </div>
  );
};

export default RoiCalculator;
